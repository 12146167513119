import React, { useEffect, useState } from "react";
import "./index.css";
import { getWishlistCustomer } from "../../../../Store/actions/wishlist";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty as NoWishlist, message, Pagination } from "antd";
import CreateWishlistModal from "../../../../Components/Wishlist/CreateWishlistModal";
import { WishlistItemType } from "../../../../Store/initialStates";
import EditWishlistModal from "../../../../Components/Wishlist/EditWishlistModal";
import DeleteWishlistModal from "../../../../Components/Wishlist/DeleteWishlistModal";

const Wishlist: React.FC = () => {
  const dispatch = useDispatch();
  const wishlist = useAppSelector( ( state ) => state.wishlistReducer );
  const [ modals, setModals ] = useState<{
    edit: boolean;
    create: boolean;
    delete: boolean;
    activeItem: WishlistItemType | null;
  }>( {
    edit: false,
    create: false,
    delete: false,
    activeItem: null,
  } );

  const { isSuccessful, error, data, isLoading } = wishlist;

  useEffect( () => {
    dispatch( getWishlistCustomer( { page: 1, limit: 10 } ) );
  }, [ dispatch ] );

  const handleModals = (
    type:
      | "SHOW_CREATE_MODAL"
      | "SHOW_EDIT_MODAL"
      | "HIDE_CREATE_MODAL"
      | "HIDE_EDIT_MODAL"
      | "HIDE_ALL_MODALS"
      | "SHOW_DELETE_MODAL"
      | "HIDE_DELETE_MODAL",
    payload?: WishlistItemType
  ) => {
    switch ( type ) {
      case "SHOW_CREATE_MODAL":
        setModals( ( old ) => ( { ...old, create: true } ) );
        break;
      case "SHOW_EDIT_MODAL":
        if ( payload ) {
          setModals( ( old ) => ( { ...old, edit: true, activeItem: payload } ) );
          break;
        }
        message.error( {
          duration: 2,
          content: "An error occurred",
        } );
        break;
      case "SHOW_DELETE_MODAL":
        if ( payload ) {
          setModals( ( old ) => ( { ...old, delete: true, activeItem: payload } ) );
          break;
        }
        message.error( {
          duration: 2,
          content: "An error occurred",
        } );
        break;
      case "HIDE_CREATE_MODAL":
        setModals( ( old ) => ( { ...old, create: false } ) );
        break;
      case "HIDE_EDIT_MODAL":
        setModals( ( old ) => ( { ...old, edit: false, activeItem: null } ) );
        break;
      case "HIDE_DELETE_MODAL":
        setModals( ( old ) => ( { ...old, delete: false, activeItem: null } ) );
        break;
      case "HIDE_ALL_MODALS":
        setModals( ( old ) => ( {
          ...old,
          create: false,
          edit: false,
          activeItem: null,
        } ) );
        break;
      default:
        break;
    }
  };

  const changePage = ( page: number ) => {
    dispatch( getWishlistCustomer( { page } ) );
  };

  return (
    <section className="wishlist_container position-relative">
      <h3>Wishlist</h3>
      {!!wishlist?.data?.data?.length && (
        <button
          className="d-block btn btn-success ms-auto px-4 py-2 mb-2"
          onClick={() => handleModals( "SHOW_CREATE_MODAL" )}
        >
          Create Item
        </button>
      )}

      {isLoading && (
        <div className="loader__wrapper">
          <LoadingOutlined style={{ fontSize: 40 }} spin />
        </div>
      )}

      {!isLoading && error && !isSuccessful && (
        <div className="container text-center display-4 text-muted mt-4">
          An error occured
        </div>
      )}

      {!isLoading && !error && isSuccessful && !data?.data?.length && (

        <NoWishlist className="no-data" description={
          <div className="">
            <h3 className="text-muted">
              Your don't have any items in your wishlist
            </h3>
            <button
              className="btn btn-secondary py-3"
              onClick={() => handleModals( "SHOW_CREATE_MODAL" )}
            >
              Create Wishlist Product
            </button>
          </div>} />
      )}

      {!isLoading && !error && !!data?.data?.length && (
        <>
          <div className="table-responsive">
            <table className="table borderless">
              <thead>
                <tr>
                  <th scope="col" className="col-1">
                    No.
                  </th>
                  <th scope="col" className="col-3">
                    Title
                  </th>
                  <th scope="col" className="col-2">
                    Fulfulled
                  </th>
                  <th scope="col" className="col-2">
                    Keywords
                  </th>
                  <th scope="col" className="col-2">
                    City
                  </th>
                  <th scope="col" className="col-2">
                    State
                  </th>
                  <th scope="col" className="col-1">
                    Action
                  </th>
                </tr>
              </thead>

              {data?.data?.map( ( item, index ) => {
                const { _id, title, fulfilled, keywords, city, state } = item;
                return (
                  <tbody key={_id}>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{title}</td>
                      <td>{fulfilled ? "Yes" : "No"}</td>
                      <td>{keywords.length ? keywords.join( ", " ) : "-"}</td>
                      <td>{city}</td>
                      <td>{state}</td>
                      <td className="d-flex ">
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            handleModals( "SHOW_DELETE_MODAL", item )
                          }
                        >
                          X
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleModals( "SHOW_EDIT_MODAL", item )}
                        >
                          <EditOutlined />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              } )}
            </table>
          </div>
          <EditWishlistModal
            open={modals.edit}
            handleModals={handleModals}
            item={modals.activeItem as WishlistItemType}
          />
          <DeleteWishlistModal
            open={modals.delete}
            handleModals={handleModals}
            item={modals.activeItem as WishlistItemType}
          />
          <Pagination
            onChange={changePage}
            total={data?.total || 0}
            hideOnSinglePage={true}
            current={data?.currentPage}
            pageSize={10}
            className="d-flex justify-content-end pb-5 pt-4"
          />
        </>
      )}
      <CreateWishlistModal open={modals.create} handleModals={handleModals} />
    </section>
  );
};

export default Wishlist;
