import { Tag } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//
import { IProgram as ProgramItemType } from "./program-type";
import { Button } from "../../Components/ui/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToCart, selectAddToCart } from "../../Store/actions/addToCart";
import { selectGetCart } from "../../Store/actions/getCart";
import { selectPrograms } from "../../Store/actions/programs";
import { formatCurrency } from "../../Utils";

type ProgramItemPropsType = {
  program: ProgramItemType;
};

function getOriginalPrice(program) {
  const sellingPrice = program?.totalSellingPrice || 0;
  const discountPrice = program?.discount || 0;

  return sellingPrice + discountPrice;
}
function ProgramItem(props: ProgramItemPropsType) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const addToCartState = useAppSelector(selectAddToCart);
  const programs = useAppSelector(selectPrograms)?.data?.programs ?? [];

  const handleAddToCart = (programId: string) => {
    setIsLoading(true);
    const program = programs.find((p: any) => p._id === programId);
    dispatch(
      addToCart({
        type: "add",
        id: programId,
        data: [...cartData, program],
      })
    );
  };

  const handleRemoveFromCart = (programId: string) => {
    setIsLoading(true);
    const data = cartData?.filter((program: any) => program?._id !== programId);
    dispatch(addToCart({ type: "remove", id: programId, data }));
  };

  const cartData = useAppSelector(selectGetCart)?.data ?? [];
  const getPercent = () =>
    Math.round((100 * props.program.totalDiscount) / props.program.totalPrice);

  const stopIsLoading = () => setIsLoading(false);

  useEffect(() => {
    if (
      !addToCartState?.isLoading &&
      (addToCartState?.isSuccessful || addToCartState?.error)
    ) {
      stopIsLoading();
    }
  }, [
    addToCartState?.error,
    addToCartState?.isLoading,
    addToCartState?.isSuccessful,
  ]);

  const getProgramImage = () =>
    props?.program?.avatar?.secure_url ??
    "https://plus.unsplash.com/premium_photo-1676717962720-d9a812c8f8c9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <div className="program__item">
      <div className="program__item--image">
        <img src={getProgramImage()} alt="Program Icon" />
      </div>

      <div className="program__content">
        <Link to={`/programs/${props.program._id}`} className="program__link">
          <h5 className="program__title">{props.program.title}</h5>

          <div className="program__row">
            <span>Products per program:</span>
            <span>{props?.program?.products?.length}</span>
          </div>
          <div className="program__row">
            <Tag className="discount bold" color="red">
              {getPercent()}%
            </Tag>
            <span className="price bold">
              {formatCurrency(props.program.totalSellingPrice || 0)}
            </span>
            <s>{formatCurrency(getOriginalPrice(props?.program))}</s>
          </div>

          <div className="program__row">
            <span>State:</span>
            <span>{props?.program.owner?.state}</span>
          </div>

          <div className="program__row">
            <span>City:</span>
            <span>{props?.program.owner?.city}</span>
          </div>

          <div className="program__row">
            <span>Address:</span>
            <span>{props?.program.owner?.address}</span>
          </div>
        </Link>

        {cartData?.find((p: any) => {
          return p?._id === props.program._id;
        }) ? (
          <Button
            className="remove_from_cart"
            loading={isLoading}
            onClick={() => handleRemoveFromCart(props.program._id)}
          >
            Remove from cart
          </Button>
        ) : (
          <Button
            className="add_to_cart"
            loading={isLoading}
            onClick={() => handleAddToCart(props.program._id)}
          >
            Add to cart
          </Button>
        )}
      </div>
    </div>
  );
}

export default ProgramItem;
