/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-line react-hooks/exhaustive-deps
// STYLES
import "./index.css";
import { MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import { Pagination, Modal, Popover, Input } from "antd";

// MODULES AND PACKAGES
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AxiosCall from "../../../../Utils/axios";

// ACTIONS
import {
  getProgramList,
  getProgramCleanup,
} from "../../../../Store/actions/bussiness/get-program";
import { formatCurrency } from "../../../../Utils";

const sortComparer = (a, b) => {
  const getDate = (obj) => {
    const date = new Date(obj.createdAt);
    return isNaN(date.getTime()) ? new Date(obj.createdAt) : date;
  };

  const dateA = getDate(a);
  const dateB = getDate(b);

  return dateB.getTime() - dateA.getTime();
};
const VipProgram = () => {
  const history = useHistory();
  const { Search } = Input;
  const dispatch = useDispatch();
  const getProgramState = useSelector((s) => s.getProgram);
  const [programs, setPrograms] = useState();
  const [totalPrograms, setTotalPrograms] = useState("");
  const [currentID, setCurrentID] = useState("");
  const [currentProgram, setCurrentProgam] = useState("");
  // FOR MODAL
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changePage = (page) => {
    dispatch(getProgramList({ page: page }));
  };

  useEffect(() => {
    dispatch(getProgramList({ limit: 20 }));
  }, []);

  useEffect(() => {
    if (getProgramState.isSuccessful) {
      setPrograms(getProgramState.data);
      setTotalPrograms(getProgramState.data.programs.length);
      dispatch(getProgramCleanup());
    } else if (getProgramState.error) {
      setPrograms(null);
      dispatch(getProgramCleanup());
    }
  }, [getProgramState]);

  const actions = async (id) => {
    try {
      const result = await AxiosCall({
        method: "DELETE",
        path: "programs/" + id,
      });
      dispatch(getProgramList({}));
      handleCancel();
    } catch (err) {}
  };

  const onSearch = (value) => dispatch(getProgramList({ title: value }));

  return (
    <div className="program-list-container">
      <h3>Programs</h3>
      <hr />

      {!getProgramState.isLoading && (
        <p style={{ textAlign: "center" }}>
          {totalPrograms === 0
            ? "No programs found"
            : `${totalPrograms} programs`}
        </p>
      )}

      {totalPrograms ? (
        <div className="search-container mx-auto">
          <Search
            placeholder="Search by program title"
            onSearch={onSearch}
            enterButton
          />
        </div>
      ) : null}

      {getProgramState.isLoading ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} Spin />
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table borderless">
            {programs && programs.programs.length === 0 ? null : (
              <thead>
                <tr>
                  <th scope="col" className="col-1">
                    No.
                  </th>
                  <th scope="col" className="col-3">
                    Program Title
                  </th>
                  <th scope="col" className="col-2">
                    Original Price
                  </th>

                  <th scope="col" className="col-2">
                    VIP Member Discount
                  </th>

                  <th scope="col" className="col-2">
                    VIP Member Price
                  </th>

                  <th scope="col" className="col-2">
                    Date Created
                  </th>
                  <th scope="col" className="col-1">
                    Actions
                  </th>
                </tr>
              </thead>
            )}

            {programs && programs.programs.length !== 0 && (
              <>
                {programs.programs.sort(sortComparer).map((program, index) => (
                  <tbody key={index}>
                    <tr className="all-products">
                      <td>{index + 1}.</td>
                      <td>{program.title}</td>
                      <td>{formatCurrency(program.totalPrice)}</td>
                      <td>{formatCurrency(program.totalDiscount)}</td>
                      <td>{formatCurrency(program.totalSellingPrice)}</td>
                      <td>
                        {new Date(program.createdAt).toLocaleDateString()}
                      </td>
                      <td className="delete-action text-start">
                        <Popover
                          content={
                            <div
                              onClick={() => {
                                setCurrentID(program._id);
                                setCurrentProgam(program.title);
                              }}
                            >
                              <p
                                className="edit-action"
                                onClick={() => {
                                  history.push(
                                    "/account/programs/" + program._id
                                  );
                                }}
                              >
                                Edit Program
                              </p>
                              <p
                                className="delete-action"
                                onClick={() => {
                                  showModal();
                                }}
                              >
                                Delete Program
                              </p>
                            </div>
                          }
                          trigger="click"
                        >
                          <MoreOutlined />
                        </Popover>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </>
            )}
          </table>
        </div>
      )}

      <Modal
        title="Delete Program"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="text-center">
          Are you sure you want to delete this Program?
        </p>

        <h5 className="text-center fw-normal"> {currentProgram} </h5>

        <div className="d-flex justify-content-center">
          <button
            className="btn btn-danger btn-sm m-4"
            onClick={() => actions(currentID)}
          >
            YES
          </button>
          <button
            className="btn btn-primary btn-sm m-4"
            onClick={() => handleCancel()}
          >
            NO
          </button>
        </div>
      </Modal>

      <div className="d-flex justify-content-end pb-5 pt-4">
        <Pagination
          onChange={changePage}
          total={totalPrograms}
          hideOnSinglePage={true}
        />
      </div>
    </div>
  );
};

export default VipProgram;
