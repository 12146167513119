/**
 * Format the query parameters for a route.
 * It converts the object query to a string of queries.
 * @param query
 * @returns string joined with &
 */
export const paramsFormat = (query: Record<string, string>) =>
  Object.keys(query)
    .map((key) => {
      let value = query[key];
      const isString = typeof value === "string";
      const isNumber = typeof value === "number";

      if (isNumber || (isString && value.trim())) return `${key}=${query[key]}`;

      return "";
    })
    .filter((q) => q !== "")
    .join("&");
