import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";

export const validateMailStart = () => ({
  type: "VALIDATE_MAIL_START",
});

export const validateMailSuccess = (payload) => ({
  type: "VALIDATE_MAIL_SUCCESS",
  payload,
});

export const validateMailFail = (payload) => ({
  type: "VALIDATE_MAIL_FAIL",
  payload,
});

export const validateMailCleanup = () => ({
  type: "VALIDATE_MAIL_CLEANUP",
});

export const validateMail = (payload) => async (dispatch) => {
  try {
    dispatch(validateMailStart());
    const requestObj = {
      path: "users/check-email?email=" + payload.email,
      method: "GET",
    };
    const { data } = await AxiosCall(requestObj);
    dispatch(validateMailSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(validateMailFail(error));
  }
};
