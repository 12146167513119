import "./index.css";
import { MoreOutlined,LoadingOutlined } from "@ant-design/icons";
import Loader from "../Loader";
import { Popover, Dropdown } from "antd";
// import AxiosCall from "../../Utils/axios";
import { Fragment, useRef } from "react";

const getVerifiedBgColor = (isVerified = false) =>
  isVerified ? "#8CF6CA" : "#F7B28C";

const getIsSuspendText = (isSuspend = false) => (isSuspend ? "Yes" : "No");

const dropdownMenu = (loading,moreOptions,actions, user) => {
  if (loading) {
    return [
      {
        key: "1",
        label: <Loader />,
      },
    ];
  }

  if (user.suspended.value) {
    return moreOptions.map((option, index) =>
      option.title === "lift suspention"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : null
    );
  }

  if (user.deleted.value) {
    return moreOptions.map((option, index) =>
      option.title === "restore"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : null
    );
  }

  return moreOptions.map((option, index) => {
    return {
      key: index,
      label: (
        <Fragment key={index}>
          {option.title !== "lift suspention" ? (
            option.title === "restore" ? null : (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            )
          ) : null}
        </Fragment>
      ),
    };
  });
};

const List = ({
  data,
  error,
  actions,
  isLoading,
  visibility,
  setVisibility,
}) => {
  const { showUserDetails, moreOptions } = actions;

  const itemRefs = useRef([]);
  itemRefs.current = [];
  const viewProfile = async (e, index, userId) => {
    console.log("selected element: ", e.target.parentNode);
    if (visibility != null) return;
    console.log("index element: ", itemRefs.current[index].childNodes[0]);
    if (e.target.parentNode !== itemRefs.current[index].childNodes[0]) {
      showUserDetails(userId);
    }
  };

  const addItiemRefs = (e) => {
    if (e && !itemRefs.current.includes(e)) {
      itemRefs.current.push(e);
    }
  };


  return (
    <div className="table-wrapper">

{!data ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
      <div className="table">
        <li className="table-head" id="top-level">
          <span className="user-id">User ID</span>
          <span className="user-email">User Email</span>
          <span className="verification">Earned</span>
          <span className="verification">Suspended</span>
          <span className="verification">User Verified</span>
          <span className="actions">Actions</span>
        </li>
        {error && <li>{error}</li>}
        <ul className="table-body">
          {data &&
            data.users.map((user, key) => (
              <li className="all-users" key={key} onClick={(e) => viewProfile(e, key, user._id)}>
                <span className="items user-id">{user._id}</span>
                <span className="items user-email">
                  {user.email}
                </span>
                <span className="items user-email">${user.referralEarned}</span>
                <span className="items verification">
                  <b
                    style={{
                      backgroundColor: getVerifiedBgColor(user.suspended.value),
                    }}
                  >
                    {getIsSuspendText(user.suspended.value)}
                  </b>
                </span>
                <span className="items verification">
                  <b
                    style={{
                      backgroundColor: getVerifiedBgColor(
                        user.verifiedBusiness
                      ),
                    }}
                  >
                    {getIsSuspendText(user.verifiedBusiness)}
                  </b>
                </span>
                <span className="items actions" ref={addItiemRefs}>
                <Dropdown
                    menu={{
                      items: dropdownMenu(isLoading === user._id,moreOptions,actions,user),
                    }}
                    trigger={["click"]}
                    open={visibility === user._id ? true : false}
                    onOpenChange={() => {
                      if (visibility === null) {
                        setVisibility(user._id);
                      } else {
                        setVisibility(null);
                      }
                    }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </span>
              </li>
            ))}
        </ul>
      </div>
      )}
    </div>
  
  )
};

export default List;
