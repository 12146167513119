import { Col, Row } from "antd";
import { CustomRow } from "../CustomRow";
import { useAppSelector } from "../../../../hooks";
import { formatCurrency } from "../../../../Utils";
import productImage from "../../../../Assets/images/head-set.jpg";
import "./index.css";

type ShoppingItemPropsType = {
  itemImage?: string;
  title: string;
  itemId: string;
  sellingPrice: number;
  totalPrice: number;
  discountPrice: number;
  platformCharge: number;
};

function ShoppingItem(props: ShoppingItemPropsType) {
  return (
    <Col className="shopping__list--item detail px-2 pb-3 gap-4">
      <div className="image__wrapper d-grid my-2">
        <img
          src={props.itemImage ?? productImage}
          alt={props?.title}
          height={"250px"}
          width={"100%"}
          className="rounded mb-1"
        />
      </div>

      <CustomRow title="Title:" description={props.title} />

      <CustomRow title="Quantity:" description={(1).toFixed(2)} />

      <CustomRow title="ID:" description={props?.itemId} />

      <CustomRow
        title="Price:"
        description={
          <div className="d-flex gap-4">
            <span className="bg-danger text-white py-1 px-2 border-1 rounded-pill">
              {formatCurrency(props?.sellingPrice)}
            </span>
            <s className="fw-bold" aria-details="Original program price">
              {formatCurrency(props?.totalPrice)}
            </s>
          </div>
        }
      />

      <CustomRow
        title="Discount:"
        description={formatCurrency(props?.discountPrice)}
      />

      <CustomRow
        title="Platform fee:"
        description={formatCurrency(props?.platformCharge)}
      />
    </Col>
  );
}

function ShoppingList() {
  const getCartState = useAppSelector((state) => state.getCart);

  return (
    <Row
      style={{
        columnGap: "16px",
        rowGap: "16px",
        overflowY: "auto",
        height: "calc(100vh - 210px)",
        maxHeight: "calc(100vh - 210px)",
      }}
      className="shopping__list pb-1 mt-3 noscrollbar"
    >
      {getCartState.data?.map((cartItem) => (
        <ShoppingItem
          key={cartItem?._id}
          platformCharge={29.99}
          discountPrice={cartItem.totalDiscount}
          totalPrice={cartItem.totalPrice}
          sellingPrice={cartItem.totalSellingPrice}
          itemId={cartItem._id}
          title={cartItem.title}
          itemImage={cartItem.avatar?.secure_url}
        />
      ))}
    </Row>
  );
}

export default ShoppingList;
