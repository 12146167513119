/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dayjs } from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.css";
import {
  Input,
  Row,
  Col,
  Modal,
  Checkbox,
  message,
  Pagination,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  editProgramCleanup,
  editProgram,
} from "../../../../Store/actions/bussiness/edit-program";

import {
  getProductList,
  getProductCleanup,
} from "../../../../Store/actions/bussiness/get-product";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductType, ProgProductType } from "../../../../types";
import {
  programDetails,
  programDetailsCleanup,
} from "../../../../Store/actions/programDetails";
import { disableDateBeforeCurrentDay, formatCurrency } from "../../../../Utils";
import InputField from "../../../../Components/ui/form/Input";

interface SelectedProductType extends ProductType {
  in_stock: number;
}

const EditProgram = () => {
  const { id } = useParams();

  const { Search } = Input;
  const dispatch = useAppDispatch();
  const editProgramState = useAppSelector((s) => s.editProgram);
  const getProductState = useAppSelector((s) => s.getProduct);
  const programState = useAppSelector((s) => s.programDetails);

  const [products, setProducts] = useState<Array<ProductType>>([]);
  // const [oldProducts, setOldProducts] = useState<Array<ProductType>>([]);
  // const [newProducts, setNewProducts] = useState<Array<ProductType>>([]);
  const [form, setForm] = useState({
    inStock: 0,
    title: "",
    totalPrice: 0,
    totalDiscount: 0,
    expiresAt: "",
  });
  const [productsArray, setProductsArray] = useState<ProgProductType[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedProductType[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [allowEditProgram, setAllowEditProgram] = useState(false);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const changeTotalDiscount = (totalDiscount: number) =>
    setForm((prev) => ({ ...prev, totalDiscount }));

  const changeTotalPrice = (totalPrice: number) =>
    setForm((prev) => ({ ...prev, totalPrice }));

  const calcNum = (arg) => {
    const isStock = selectedItems.find((item) => item._id === arg._id);

    if (isStock) {
      return isStock.in_stock;
    } else {
      return 0;
    }
  };

  const increaseStock = (arg, isOld?) => {
    setSelectedItems((prev) => {
      const isItem = prev.find((item) => item._id === arg._id);

      if (isItem) {
        return prev.map((item) =>
          item._id === arg._id
            ? {
                ...item,
                in_stock: item.in_stock + 1,
              }
            : item
        );
      }
      return [...prev, { ...arg, in_stock: 1 }];
    });

    let setProductAction = (prev) => {
      const isItem = prev.find((item) => item.item === arg._id);

      if (isItem) {
        return prev.map((item) =>
          item.item === arg._id
            ? {
                item: item.item,
                stock: item.stock + 1,
              }
            : item
        );
      }
      return [...prev, { stock: 1, item: arg._id }];
    };
    setProductsArray(setProductAction);
    // setOldProducts(setProductAction);
  };

  const decreaseStock = (arg) => {
    setSelectedItems((prev) =>
      prev.reduce<Array<SelectedProductType>>((ack, item) => {
        if (item._id === arg) {
          if (item.in_stock === 1) return ack;
          return [...ack, { ...item, in_stock: item.in_stock - 1 }];
        } else {
          return [...ack, item];
        }
      }, [])
    );
  };

  const sumPrice = () => {
    changeTotalPrice(
      selectedItems.reduce(
        (ack, item) => ack + item.in_stock * item.sellingPrice,
        0
      )
    );

    changeTotalDiscount(
      selectedItems.reduce(
        (ack, item) => ack + item.in_stock * item.discount,
        0
      )
    );
  };

  // put id and in stock in an array and prevent from being added twice

  const changePage = (page) => {
    dispatch(getProductList({ page: page }));
  };

  const onSearch = (value) => dispatch(getProductList({ title: value }));

  const onSubmit = () => {
    const data = {
      id,
      products: productsArray,
      totalPrice: form.totalPrice,
      totalDiscount: form.totalDiscount,
      title: form.title,
      stock: +form.inStock, // convert to number
      expiresAt: form.expiresAt,
    };
    dispatch(editProgram(data));
  };

  /**
   * Handles datepicker change
   *
   * @param date
   */
  const onDateChange = (date: Dayjs | null) => {
    setForm({
      ...form,
      expiresAt: date?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")!,
    });
  };

  // // run this when a product is added
  useEffect(() => {
    sumPrice();
  }, [selectedItems]);

  useEffect(() => {
    dispatch(getProductList({ limit: 20 }));
    dispatch(programDetails({ _id: id }));
  }, []);

  // GET PROGRAM DETAILS AND DISPLAY
  useEffect(() => {
    if (programState.isSuccessful) {
      setForm({
        ...form,
        title: programState.data.title,
        inStock: programState.data.stock,
        expiresAt: programState.data.expiresAt,
        totalPrice: programState.data.totalPrice,
        totalDiscount: programState.data.totalDiscount,
      });

      setProductsArray([]);
      setSelectedItems([]);
      programState.data.products.forEach((product) => {
        if (product.item) {
          for (let i = 0; i < product.stock; i++) {
            increaseStock(product.item, true);
          }
        }
      });
      dispatch(programDetailsCleanup());
    } else if (programState.error) {
      setProducts([]);
      dispatch(programDetailsCleanup());
    }
  }, [programState]);

  // GET PRODUCTS FROM BACKEND TO DISPLAY
  useEffect(() => {
    if (getProductState.isSuccessful) {
      setProducts(getProductState.data.products);
      dispatch(getProductCleanup());
    } else if (getProductState.error) {
      setProducts([]);
      dispatch(getProductCleanup());
    }
  }, [getProductState]);

  useEffect(() => {
    if (editProgramState.isSuccessful) {
      message.success("Program updated successfully");
      handleCancel();
      // setProductsArray([]);
      setSelectedItems([]);
      // reset setForm
      setForm({
        inStock: 0,
        totalPrice: 0,
        totalDiscount: 0,
        title: "",
        expiresAt: "",
      });
      dispatch(editProgramCleanup());
      dispatch(programDetails({ _id: id }));
      setAllowEditProgram(false);
    } else if (editProgramState.error) {
      message.error(editProgramState.error);
      dispatch(editProgramCleanup());
      setAllowEditProgram(false);
    }
  }, [editProgramState]);

  const showTermsModal = () => {
    setIsTermsModalVisible(true);
  };

  const showModal = () => {
    if (form.totalDiscount < 350) {
      message.success("Total discount should be upto $350");
    } else {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAllowEditProgram(false);
  };

  const handleTermsOk = () => {
    setIsTermsModalVisible(false);
  };

  const handleTermsCancel = () => {
    setIsTermsModalVisible(false);
  };

  return (
    <div className="create-program-container edit__program">
      <div className="container-inner">
        <h6>Edit VIP Program </h6>
        <hr />

        <div className="search-container">
          <Search
            className="search-input"
            placeholder="Search by title"
            onSearch={onSearch}
            enterButton
          />
        </div>

        {programState.isLoading || getProductState.isLoading ? (
          <div
            className="text-center py-5 d-flex justify-content-center align-items-center"
            style={{ height: "60vh" }}
          >
            <LoadingOutlined style={{ fontSize: 30 }} spin />
          </div>
        ) : (
          <>
            <Row style={{ gap: "30px", justifyContent: "space-between" }}>
              <Col xs={{ span: 24 }} md={{ span: 10 }}>
                <InputField
                  label=""
                  name="title"
                  onChange={onChange}
                  defaultValue={programState?.data?.title}
                  placeholder="Title"
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 5 }}>
                <InputField
                  label=""
                  type="number"
                  name="inStock"
                  onChange={onChange}
                  defaultValue={programState?.data?.stock}
                  placeholder="100"
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 5 }}>
                <DatePicker
                  format="YYYY/MM/DD hh:mm A"
                  showTime={false}
                  showToday={false}
                  onChange={onDateChange}
                  disabledDate={disableDateBeforeCurrentDay}
                  // defaultValue={new Dayjs(programState?.data?.expiresAt)}
                />
              </Col>
            </Row>

            <Row gutter={[16, 24]} className="mt-4">
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <div className="create_program_card selling_price">
                  <p>Total Selling Price</p>

                  <h3>{formatCurrency(form?.totalPrice)}</h3>
                </div>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <div className="create_program_card discount_price">
                  <p>Total Discount</p>

                  <h3>{formatCurrency(form?.totalDiscount)}</h3>
                </div>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <div className="create_program_card item_number">
                  <p>No. of items</p>

                  <h3>{selectedItems.length}</h3>
                </div>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <div className="create_program_card create_btn">
                  <button
                    // disabled={totalDiscount < 350}

                    onClick={showModal}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>

            <div className="table-responsive mt-5 mb-5">
              <table className="table borderless">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Item Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Selling Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {products?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{item.category}</td>
                      <td>{formatCurrency(item.sellingPrice)}</td>
                      <td>{formatCurrency(item.discount)}</td>

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="calc_button"
                          onClick={() => decreaseStock(item._id)}
                        >
                          <FontAwesomeIcon icon={faMinus as IconProp} />
                        </button>
                        {calcNum(item)}
                        <button
                          className="calc_button"
                          onClick={() => increaseStock(item)}
                        >
                          <FontAwesomeIcon icon={faPlus as IconProp} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {products.length && (
              <div className="d-flex justify-content-end pb-5 pt-4">
                <Pagination
                  onChange={changePage}
                  total={getProductState.data.totalProducts}
                  hideOnSinglePage={true}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        title="Edit Program"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="text-center">
          <Checkbox
            name="allowEditProgram"
            onChange={(e) => setAllowEditProgram(e.target.checked)}
            style={{ marginRight: "5px" }}
            checked={allowEditProgram}
          ></Checkbox>{" "}
          <span>
            Agree to{" "}
            <span className="terms-btn" onClick={showTermsModal}>
              terms and conditions
            </span>
          </span>
          <br />
          <div className="d-flex justify-content-evenly mt-5">
            <button
              onClick={handleCancel}
              className="btn-danger btn-sm px-4 py-2"
            >
              CANCEL
            </button>
            <button
              disabled={!allowEditProgram}
              onClick={onSubmit}
              className="btn btn-success btn-sm px-4 py-2"
            >
              {editProgramState.isLoading ? <LoadingOutlined /> : "PROCEED"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Terms of use"
        open={isTermsModalVisible}
        onOk={handleTermsOk}
        onCancel={handleTermsCancel}
        footer={null}
      >
        <p>
          Terms and Conditions General Site Usage Last Revised: December 16,
          2013 Welcome to www.lorem-ipsum.info. This site is provided as a
          service to our visitors and may be used for informational purposes
          only. Because the Terms and Conditions contain legal obligations,
          please read them carefully. 1. YOUR AGREEMENT By using this Site, you
          agree to be bound by, and to comply with, these Terms and Conditions.
          If you do not agree to these Terms and Conditions, please do not use
          this site. PLEASE NOTE: We reserve the right, at our sole discretion,
          to change, modify or otherwise alter these Terms and Conditions at any
          time.
        </p>
      </Modal>
    </div>
  );
};

export default EditProgram;
