/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// STYLES
import { Container } from "reactstrap";
import "./index.css";

//ACTIONS
import { programsList } from "../../Store/actions/programs";

import SearchForm from "../Home/Hero/SearchForm";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ProgramType } from "../../types";
import ProgramList from "../../Components/ui/programs/List";
import { useGetCartItems } from "../../hooks/cart";

interface ProgramTypeWCart extends ProgramType {
  inCart: boolean;
}

const ProgramListing = () => {
  const dispatch = useAppDispatch();
  const [cart] = useGetCartItems()

  const programState = useAppSelector((state) => state.programs);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [programs, setPrograms] = useState<ProgramTypeWCart[]>([]);
  const [programMap] = useState({});
  const city = searchParams.get("city");
  const state = searchParams.get("state");
  const businessName = searchParams.get("businessName");
  const programName = searchParams.get("programName");

  useEffect(() => {
    dispatch(
      programsList({
        city,
        state,
        businessName,
        programName,
      })
    );
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // update the list of programs as they scroll the page
    if (programState?.isSuccessful && programState?.data?.programs) {
      const programs: any[] = [];

      programState.data.programs.forEach((program) => {
        if (!programMap[program._id]) {
          programMap[program._id] = program;
          programs.push(program);
        }
      });

      setPrograms((prev) => [...prev, ...programs]);
    }
  }, [programState]);

  return (
    <div className="program-list">
      <Container>
        <div className="text-start">
          <SearchForm searchBy="program" />
        </div>

        {programState.isLoading ? (
          <div className="text-center py-5">
            <LoadingOutlined style={{ fontSize: 30 }} spin />
          </div>
        ) : (
          <>
            <div className="topper mb-4 mt-4">
              <h3>Available Programs</h3>
              <h4>{programs?.length ?? 0} Programs</h4>
            </div>

            {programs?.length < 1 && (
              <>
                <p className="fw-bold fs-6 text-center">
                  No result found for your search!
                </p>
                <p className="text-center">
                  {" "}
                  You can{" "}
                  <Link to="/signup" className="text-danger">
                    {" "}
                    sign up{" "}
                  </Link>{" "}
                  to become a business owner{" "}
                </p>
              </>
            )}

            <ProgramList
              variant={1}
              data={programs}
              isLoading={programState?.isLoading}
              showControls
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default ProgramListing;
