import { LoadingOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";

function Loader(props: SpinProps & { fontSize?: number }) {
  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: props.fontSize ?? 24 }} spin />
      }
      {...props}
    />
  );
}

export default Loader;
