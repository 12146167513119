import { Empty as NoProgram } from "antd";
import ProgramItem from "./ProgramItem";
import { useAppSelector } from "../../hooks";
import { selectPrograms } from "../../Store/actions/programs";

function ProgramList() {
  const programs = useAppSelector(selectPrograms)?.data?.programs ?? [];

  if (!programs?.length)
    return <NoProgram className="no-data" description="No VIP programs" />;

  return (
    <div className="program__list">
      {programs?.map((program) => (
        <ProgramItem key={program._id} program={program} />
      ))}
    </div>
  );
}

export default ProgramList;
