import { LoadingOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteWishlistItem } from "../../../Store/actions/wishlist";
import { WishlistItemType } from "../../../Store/initialStates";

const DeleteWishlistModal: React.FC<{
  open: boolean;
  item: WishlistItemType;
  handleModals: (type: "HIDE_DELETE_MODAL") => void;
}> = ({ open, item, handleModals }) => {
  const dispatch = useDispatch();
  const [wishlist, setWishlist] = useState<WishlistItemType | null>(null);

  useEffect(() => {
    setWishlist(item);
  }, [item]);

  const handleDelete = () => {
    if (wishlist) {
      dispatch(deleteWishlistItem(wishlist._id) as any);
      handleModals("HIDE_DELETE_MODAL");
      return;
    }

    message.error({
      message: "Something went wrong",
      duration: 2,
    });
    return;
  };

  return (
    <Modal
      title="Delete Wishlist Item"
      open={open}
      onCancel={() => handleModals("HIDE_DELETE_MODAL")}
      onOk={() => handleDelete()}
      width="50%"
      okText="Delete"
      cancelText="Close"
    >
      {!wishlist && (
        <div className="d-flex justify-content-center w-100">
          <LoadingOutlined style={{ fontSize: 30 }} spin={true} />
        </div>
      )}
      {wishlist && (
        <section>
          <h5 className="text-muted">
            Are you sure you want to delete "{wishlist.title}" from your
            wishlist?
          </h5>
        </section>
      )}
    </Modal>
  );
};

export default DeleteWishlistModal;
