import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "../../../../Components/ui/form/Select";
import InputField from "../../../../Components/ui/form/Input";
import "./index.css";
import {
  productList,
  selectProductList,
} from "../../../../Store/actions/productList";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ProductSearch } from "./ProductSearch";
import {
  programsList,
  selectPrograms,
} from "../../../../Store/actions/programs";
import { ProgramSearch } from "./ProgramSearch";
import { WishlistSearch } from "./WishlistSearch";
import { Button } from "../../../../Components/ui/Button";

type SearchPropType = {
  searchBy?: "program" | "product" | "wishlist";
};

function Search(props: SearchPropType) {
  const [form, setForm] = useState<Record<string, string>>({
    view: props?.searchBy ?? "",
    city: "",
    state: "",
    country: "",
    businessName: "",
    programName: "",
  });
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const productListState = useAppSelector(selectProductList);
  const programListState = useAppSelector(selectPrograms);

  const onChange = (e: any) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { view, ...search } = form;

    switch (view) {
      case "product":
        const productPayload = {
          state: form?.state,
          city: form?.city,
          title: form?.title,
          productType: form?.type,
          limit: 8,
        };
        dispatch(productList(productPayload) as any);
        break;

      case "program":
        const programPayload = {
          state: form?.state,
          city: form?.city,
          businessName: form?.businessName,
          programName: form?.programName,
          limit: 8,
        };

        dispatch(programsList(programPayload));
        break;

      default:
        break;
    }

    console.log(search);
  };

  const resetFields = (fields: string[]) => {
    const newState = {};

    Object.entries(form).forEach(([key, value]) => {
      if (fields.includes(key)) {
        newState[key] = "";
      } else {
        newState[key] = value;
      }
    });

    setForm((prev) => ({ ...prev, ...newState }));
  };

  // useEffect(() => {
  //   const queries = {};

  //   if (search) {
  //     search
  //       ?.replace("?", "")
  //       .split("&")
  //       .forEach((q: string) => {
  //         const query = q?.trim().split("=");
  //         const key = query[0];
  //         const value = query[1];

  //         queries[key] = value;
  //       });
  //   }
  //   // update the search queries
  //   setForm((prev) => ({ ...prev, ...queries }));
  // }, [search]);

  return (
    <form className="program__search--wrapper" onSubmit={handleSubmit}>
      <Select
        name="view"
        placeholder="Search by"
        options={[
          { label: "PRODUCT", value: "product" },
          { label: "PROGRAM", value: "program" },
          { label: "WISHLIST", value: "wishlist" },
        ]}
        // defaultValue={form?.view}
        onChange={onChange}
      />

      {/* display this on hero page */}
      {form.view === "" ? (
        <>
          <InputField
            label=""
            name="state"
            placeholder="New York"
            onChange={onChange}
          />

          <InputField
            label=""
            name="city"
            placeholder="New York"
            onChange={onChange}
          />

          <InputField
            label=""
            name="businessName"
            placeholder="Business Name"
            onChange={onChange}
          />

          <InputField
            label=""
            name="programName"
            placeholder="Program Name"
            onChange={onChange}
          />
        </>
      ) : null}

      {form.view === "product" ? (
        <ProductSearch
          form={form}
          onChange={onChange}
          resetFields={resetFields}
        />
      ) : null}

      {form.view === "program" ? (
        <ProgramSearch
          form={form}
          onChange={onChange}
          resetFields={resetFields}
        />
      ) : null}

      {form.view === "wishlist" ? (
        <WishlistSearch
          form={form}
          onChange={onChange}
          resetFields={resetFields}
        />
      ) : null}

      <Button
        className="btn btn--search"
        loading={productListState?.isLoading || programListState?.isLoading}
      >
        Search
      </Button>
    </form>
  );
}

export default Search;
