import InputField from "../../../../Components/ui/form/Input";
import Select from "../../../../Components/ui/form/Select";
import { selectLoggedInUserRole } from "../../../../Store/actions/getMe";
import { useAppSelector } from "../../../../hooks";
import { getCitiesByState, getStates } from "./search.utils";

type WishlistSearchType = {
  form: Record<string, string>;
  onChange(e: any): void;
  resetFields(fields: string[]): void;
};

export const WishlistSearch = (props: WishlistSearchType) => {
  const { onChange, form, resetFields } = props;
  const currentRole = useAppSelector(selectLoggedInUserRole);

  const onChangeState = (e: any) => {
    // reset the city field on the form
    resetFields(["city"]);
    onChange(e);
  };

  return (
    <>
      <InputField
        label=""
        name="title"
        placeholder="Wishlist Title"
        onChange={onChange}
      />

      <Select
        name="state"
        placeholder="State"
        options={getStates()}
        onChange={onChangeState}
      />

      <Select
        name="city"
        placeholder="Arizhona"
        options={getCitiesByState(form.state)}
        onChange={onChange}
      />

      <Select
        name="type"
        placeholder="Type"
        options={[
          { label: "Any", value: "" },
          { label: "product", value: "product" },
          { label: "service", value: "service" },
        ]}
        onChange={onChange}
      />

      <InputField
        label=""
        name="keywords"
        placeholder="Keywords"
        onChange={onChange}
      />

      {currentRole === "admin" ? (
        <Select
          name="fulfilled"
          placeholder="Fulfilled"
          defaultValue="false"
          options={[
            { label: "true", value: "true" },
            { label: "false", value: "false" },
          ]}
          onChange={onChange}
        />
      ) : null}
    </>
  );
};
