import { useState } from "react";
import { useDispatch } from "react-redux";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Divider, Image, Upload, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./profile.css";
import AxiosCall from "../../../../Utils/axios";
import { getMe } from "../../../../Store/actions/getMe";
import ErrorHandler from "../../../../Utils/error-handler";
import { config } from "../../../../config";

function UserProfile(props) {
  const baseWebUrl = config.REACT_APP_WEB_URL;
  const { user } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const referralLink = `${baseWebUrl}/signup?referral-code=${user?.affiliateLink}`;

  const doNothing = async ({ file, onSuccess }) => {
    const formData = new FormData();
    formData.append("profile-pic", file);
    try {
      const requestObj = {
        path: "users/profile-pic",
        method: "PUT",
        data: formData,
        contentType: "multipart/form-data",
      };
      const { data } = await AxiosCall(requestObj);
      message.success(data?.message);
      setLoading(false);
      dispatch(getMe());
      onSuccess("ok");
    } catch (err) {
      const error = ErrorHandler(err);
      message.error(error);
      onSuccess("ok");
    }
  };

  const beforeUpload = async (file) => {
    setLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setLoading(false);
      message.error(
        "Image selected is larger than 2MB, kindly ensure Image selected is smaller than 2MB!"
      );
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div className="account-wrapper">
      <div className="account-Container">
        <div
          style={{
            position: "relative",
            padding: "10px 0px",
          }}
        >
          <Upload
            name="profile-pic"
            customRequest={doNothing as any}
            maxCount={1}
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {user?.avatar && user?.avatar?.secure_url ? (
              <Image
                src={user?.avatar?.secure_url}
                crossOrigin="anonymous"
                preview={false}
                fallback={"/avatar.png"}
              />
            ) : (
              <div className="user-profile__placeholder">
                <UserOutlined style={{ fontSize: "30px" }} />
              </div>
            )}

            <br />

            {loading ? (
              <LoadingOutlined
                style={{
                  height: 24,
                  width: 24,
                  backgroundColor: "#d4d4d4",
                  padding: 5,
                  borderRadius: "50%",
                }}
              />
            ) : (
              <>
                {user?.avatar && user?.avatar?.secure_url ? (
                  <EditOutlined
                    style={{
                      position: "absolute",
                      top: 80,
                      height: 24,
                      width: 24,
                      right: -5,
                      backgroundColor: "#d4d4d4",
                      padding: 5,
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <EditOutlined
                    style={{
                      position: "absolute",
                      top: 50,
                      height: 24,
                      width: 24,
                      right: -4,
                      backgroundColor: "#eee",
                      padding: 5,
                      borderRadius: "50%",
                    }}
                  />
                )}
              </>
            )}
          </Upload>
        </div>

        <div className="account-details">
          <h4>{user?.name}</h4>

          <span>{user?.email}</span>

          {/* <span>User ID: {user._id}</span> */}
        </div>

        <div className="user-rating">
          {user.role !== "affiliate" && (
            <div className="row">
              <span className="text-center">${user?.earnedDiscont ?? 0}</span>

              <span className="text-center">Saved</span>
            </div>
          )}
          {user.role === "affiliate" && (
            <div className="row">
              <span className="text-center">${user?.referralEarned}</span>
              <span className="text-center">Referral Earned</span>
            </div>
          )}
          <Divider
            style={{ minHeight: "50px", borderWidth: "3px" }}
            type="vertical"
          />

          <div className="row" style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              <span>{user?.role}</span>
            </div>

            <span>Account Type</span>
          </div>
        </div>

        {user.role === "affiliate" ? (
          <div className="btnWrapper text-center mb-3">
            <span className="fw-bold">Referral Link</span>
            <br />

            {user?.affiliateLink ? (
              <span style={{ fontSize: "11px" }}>{referralLink}</span>
            ) : (
              <span>Not available</span>
            )}

            {user?.affiliateLink ? (
              <CopyToClipboard
                text={referralLink}
                onCopy={() => message.success("copied")}
              >
                <button style={{ fontSize: "10px" }} className="copy-btn">
                  Copy
                </button>
              </CopyToClipboard>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default UserProfile;
