import moment from "moment";

export const getProgramTitle = (program: any) => {
  let title = program?.owner?.businessName ?? "Unknown";
  title += "'s";

  return `${title} ${program?.title}`;
};

export const formatCurrency = (amount: number, currency = "USD") =>
  new Intl.NumberFormat("en-US", { style: "currency", currency }).format(
    amount
  );

export const disableDateBeforeCurrentDay = (current) => {
  return current && current < moment().endOf("day");
};
