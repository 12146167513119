/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

//modules and packages
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "antd";
import { Container } from "reactstrap";

//actions
import { login, loginCleanup, selectLogin } from "../../Store/actions/login";
//components
import AuthHeader from "../../Components/AuthHeader";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Input from "../../Components/ui/form/Input";
import { Button } from "../../Components/ui/Button";
import Select from "../../Components/ui/form/Select";

const Login = () => {
  const [formError, setFormError] = useState({
    email: "",
    password: "",
    role: "",
  });
  const [form, setForm] = useState({
    password: "",
    email: "",
    role: "customer",
  });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loginState = useAppSelector(selectLogin);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!form.email) {
      setFormError((prev) => ({ ...prev, email: "Email is required" }));
      return;
    }

    if (!form.password) {
      setFormError((prev) => ({ ...prev, password: "Password is required" }));
      return;
    }

    // dispatch(loginCleanup());

    dispatch(login(form));
  };

  const onChange = (e: any) => {
    if (loginState?.error || formError.email || formError.password) {
      dispatch(loginCleanup());
      setFormError({ email: "", password: "", role: "" });
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getAlertMessage = () => {
    if (loginState.isSuccessful) return "Login successfully.";
    if (loginState.error) return loginState?.error;

    return "An unknown error has occured.";
  };

  const getAlertType = (isSuccessful: boolean) =>
    isSuccessful ? "success" : "error";

  useEffect(() => {
    if (
      loginState?.error?.includes(
        "email "
      ) /** the empty space is intentionally left to filter off activation error */
    ) {
      setFormError((prev) => ({ ...prev, email: loginState.error }));
    } else if (loginState?.error?.includes("password")) {
      setFormError((prev) => ({ ...prev, password: loginState.error }));
    }

    if (loginState?.isSuccessful) {
      // clear the login state after successful login
      dispatch(loginCleanup());
      history.push("/");
    }
  }, [loginState?.error, loginState?.isSuccessful]);

  return (
    <div className="login">
      <AuthHeader />
      <Container>
        <div className="form-div">
          <h3>Sign In</h3>
          <p className="sub_title">
            Shop, save and support your local community
          </p>

          {loginState?.error || loginState.isSuccessful ? (
            <Alert
              showIcon
              closable
              message={getAlertMessage()}
              type={getAlertType(loginState.isSuccessful)}
            />
          ) : null}

          <form onSubmit={onSubmit}>
            <Input
              label="Email"
              name="email"
              placeholder="johndoe@gmail.com"
              type="email"
              isRequired
              error={formError.email}
              onChange={onChange}
            />

            <Input
              label="Password"
              name="password"
              placeholder="******"
              type="password"
              isRequired
              onChange={onChange}
              error={formError.password}
            />

            <Select
              name="role"
              // placeholder="Select a role"
              isRequired
              onChange={onChange}
              defaultValue={form.role}
              options={[
                { label: "affiliate", value: "affiliate" },
                { label: "business", value: "business" },
                { label: "customer", value: "customer" },
              ]}
            />

            <Link to="/password-reset" className="forgot">
              forgot password?
            </Link>

            <Button
              className="btn--login"
              typeof="submit"
              loading={loginState.isLoading}
            >
              Login
            </Button>
          </form>
        </div>
        <p className="login__help">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
      </Container>
    </div>
  );
};

export default Login;
