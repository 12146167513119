import america from "../../../../Utils/location/america";

export const getStates = () =>
  Object.keys(america)?.map((s: any) => ({
    value: s,
    label: s,
  }));

export const getCitiesByState = (state: string) =>
  (america[state] ?? [])?.map((s: any) => ({
    value: s,
    label: s,
  }));
