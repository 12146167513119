import AxiosCall from "../../../Utils/axios";
import { paramsFormat } from "../../../Utils/axios/paramsFormat";
import ErrorHandler from "../../../Utils/error-handler";

export const usersStart = () => ({
  type: "USERS_START",
});

export const usersSuccess = (payload) => ({
  type: "USERS_SUCCESS",
  payload,
});

export const usersFail = (payload) => ({
  type: "USERS_FAIL",
  payload,
});

export const usersCleanup = () => ({
  type: "USERS_CLEANUP",
});

export const users = (payload) => async (dispatch) => {
  const page = payload?.page ?? 1;
  const limit = payload?.limit ?? 10;
  const city = payload?.city ?? "";
  const state = payload?.state ?? "";
  const category = payload?.category ?? "";
  const role = payload?.role ?? "";
  const status = payload?.status ?? "";

  const query = {
    page,
    limit,
    city,
    state,
    category,
    role,
    status,
  };

  const params = paramsFormat(query);

  try {
    dispatch(usersStart());
    const requestObj = {
      path: `users/search?${params}`,
      method: "GET",
    };
    const { data } = await AxiosCall(requestObj);
    dispatch(usersSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(usersFail(error));
  }
};
