import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";

export const editProgramStart = () => ({
  type: "EDIT_PROGRAM_START",
});

export const editProgramSuccess = (payload) => ({
  type: "EDIT_PROGRAM_SUCCESS",
  payload,
});

export const editProgramFail = (payload) => ({
  type: "EDIT_PROGRAM_FAIL",
  payload,
});

export const editProgramCleanup = () => ({
  type: "EDIT_PROGRAM_CLEANUP",
});

export const editProgram = (payload) => async (dispatch) => {
  try {
    dispatch(editProgramStart());
    // const id = payload.id;
    const body = { ...payload };
    delete body.id;
    const requestObj = {
      path: `program/${payload.id}`,
      method: "PATCH",
      data: body,
    };
    const { data } = await AxiosCall(requestObj);
    dispatch(editProgramSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(editProgramFail(error));
  }
};
