import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Checkbox, Typography, Select, Space } from "antd";

//actions
import { setProfile, setStage } from "../../Store/actions/signupProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  validateMail,
  validateMailCleanup,
} from "../../Store/actions/validateMail";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";

const causes = [
  "UNOS",
  "ASPCA",
  "National Kidney Foundation",
  "St. Judes",
  "Salvation Army",
  "YMCA of the USA",
  "United Way Worldwide",
  "Habitat for Humanity",
  "Boys and Girls Clubs of America",
  "National Red Cross",
  "Mayo Clinic",
  "Doctors Without Borders US",
];

const { Text } = Typography;

const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const signupProgress: any = useAppSelector((state) => state.signupProgress);
  const mailValid = useAppSelector((state) => state.validateMail);
  const [isMinority, setIsMinority] = useState(
    () => signupProgress.minority ?? false
  );
  const [mailError, setMailError] = useState("");
  // const [cause, setCause] = useState('');
  const [minorityGroup, setMinorityGroup] = useState<Array<string>>(
    () => signupProgress.minority_group ?? []
  );
  const formData = useRef<any>({});
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referralCode = searchParams.get("referral-code");
  const { Option } = Select;

  const onFinish = (values) => {
    formData.current = values;
    dispatch(validateMail({ email: values.email }));
  };

  const onClick = () => {
    dispatch(
      setStage({
        stage: "account",
      })
    );
  };

  const editMinorityGroup = (selected: boolean, group: string) => {
    if (selected) {
      setMinorityGroup((prev) => {
        return [...prev, group];
      });
    } else {
      setMinorityGroup((prev) => {
        let newGroup = [...prev];
        let index = newGroup.findIndex((e) => e === group);
        if (index > -1) newGroup.splice(index, 1);
        return newGroup;
      });
    }
  };

  function onChange(value, field) {
    if (field === "minority") {
      setIsMinority(value);
    }
  }

  useEffect(() => {
    if (mailValid.error) {
      // show error message
      setMailError("An account with this email already exists");
      dispatch(validateMailCleanup());
    }
    if (mailValid.isSuccessful) {
      dispatch(validateMailCleanup());
      const {
        email,
        phoneNo,
        fund_raiser,
        address,
        city,
        state,
        country,
        postalCode,
      } = formData.current;
      dispatch(
        setProfile({
          email,
          phoneNo,
          minority: isMinority,
          minorityGroup,
          address,
          city,
          state,
          country,
          postalCode,
          fund_raiser,
        })
      );
    }
  }, [mailValid, dispatch]);

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="login"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          initialValue={signupProgress.email}
          rules={[
            {
              required: true,
              message: "Please input an email",
            },
            {
              type: "email",
              message: "Enter a valid email",
            },
          ]}
          labelAlign="left"
        >
          <Input
            onChange={() => {
              if (mailError) setMailError("");
            }}
            status={mailError ? "error" : ""}
            placeholder="johndoe@savenwin.com"
          />
        </Form.Item>
        {mailError ? (
          <Text type="danger" style={{ marginTop: "-24px", display: "block" }}>
            {mailError}
          </Text>
        ) : null}
        {signupProgress.role !== "customer" && (
          <Form.Item
            labelAlign="left"
            label="Phone Number"
            name="phoneNo"
            initialValue={signupProgress.phoneNo}
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input placeholder="+12409375963" />
          </Form.Item>
        )}
        {signupProgress.role === "customer" && !referralCode && (
          <Form.Item
            labelAlign="left"
            label="Choose your cause"
            name="fund_raiser"
            initialValue={signupProgress.fund_raiser}
          >
            <Select
              placeholder="select your cause"
              style={{ textAlign: "left", backgroundColor: "#f4f4f4" }}
              value={causes[0]}
              className="cause-select"
            >
              {causes.map((cause, index) => (
                <Option value={cause} key={index}>
                  {cause}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {signupProgress.role === "business" && (
          <>
            <hr />
            <Form.Item
              label="Street address"
              name="address"
              initialValue={signupProgress.address}
              rules={[
                {
                  required: true,
                  message: "please input an street address",
                },
              ]}
              labelAlign="left"
            >
              <Input placeholder="Enter your street address" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              label="City"
              name="city"
              initialValue={signupProgress.city}
              rules={[
                {
                  required: true,
                  message: "Please input your City",
                },
              ]}
            >
              <Input placeholder="Enter you city" />
            </Form.Item>
            <Form.Item
              label="State"
              initialValue={signupProgress.state}
              name="state"
              rules={[
                {
                  required: true,
                  message: "please input your state",
                },
              ]}
              labelAlign="left"
            >
              <Input placeholder="Enter your state" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              label="Country"
              name="country"
              initialValue={signupProgress.country}
              rules={[
                {
                  required: true,
                  message: "Please input your Country",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              label="Postal code"
              name="postalCode"
              initialValue={signupProgress.postalCode}
              rules={[
                {
                  required: true,
                  message: "Please input your Postal code",
                },
              ]}
            >
              <Input placeholder="Enter your Postal code" />
            </Form.Item>
          </>
        )}

        {signupProgress.role === "business" && (
          <Form.Item className="minority">
            <Checkbox
              onChange={(e) => onChange(e.target.checked, "minority")}
              checked={isMinority}
            >
              If you are a minority owned business and would like to promote
              your minority owned status, click here
            </Checkbox>
          </Form.Item>
        )}

        {signupProgress.role === "business" && isMinority && (
          <Form.Item
            className="minority"
            labelAlign="left"
            label="Click here if you are minority owned business"
            name="minorityGroup"
            rules={[
              () => ({
                validator() {
                  if (minorityGroup.length > 0) return Promise.resolve();
                  return Promise.reject("Select at least one minority group");
                },
              }),
            ]}
          >
            <Checkbox
              defaultChecked={minorityGroup.includes("female")}
              onChange={(e) => editMinorityGroup(e.target.checked, "female")}
            >
              Female
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("asian-indian")}
              onChange={(e) =>
                editMinorityGroup(e.target.checked, "asian-indian")
              }
            >
              Asian-indian
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("asian-pacific")}
              onChange={(e) =>
                editMinorityGroup(e.target.checked, "asian-pacific")
              }
            >
              Asian-pacific
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("black")}
              onChange={(e) => editMinorityGroup(e.target.checked, "black")}
            >
              Black
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("hispanic")}
              onChange={(e) => editMinorityGroup(e.target.checked, "hispanic")}
            >
              Hispanic
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("native american")}
              onChange={(e) =>
                editMinorityGroup(e.target.checked, "native american")
              }
            >
              Native american
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("LGBTQ+")}
              onChange={(e) => editMinorityGroup(e.target.checked, "LGBTQ+")}
            >
              LGBTQ+
            </Checkbox>
            <Checkbox
              defaultChecked={minorityGroup.includes("veteran")}
              onChange={(e) => editMinorityGroup(e.target.checked, "veteran")}
            >
              Veteran owned
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item>
          <Space
            direction="horizontal"
            style={{
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "5rem",
            }}
          >
            <Button
              onClick={onClick}
              disabled={!!referralCode}
              className="back"
              size="large"
              type="primary"
            >
              Back
            </Button>

            <Button
              htmlType="submit"
              className="signup-btn"
              disabled={mailValid.isLoading}
              style={{
                width: "97px",
              }}
              size="large"
            >
              {mailValid.isLoading ? <LoadingOutlined size={10} /> : "Continue"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default PersonalDetails;
