//styles
import "./index.css";

//modules and packages
import { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Container } from "reactstrap";
import {
  CloseOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, Popover } from "antd";
import secureLocalStorage from "react-secure-storage";

//medias
import type { MenuProps } from "antd";

import logo from "../../Assets/images/logo.png";

//actions
import { clearAuth } from "../../Store/actions/auth";
import { getMe, getMeCleanup } from "../../Store/actions/getMe";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCartCleanup, selectGetCart } from "../../Store/actions/getCart";
import { CartItems } from "../../Views/Private/common/cart";
import { UserNotCartAuth } from "../../Views/Private/common/cart";

const items: MenuProps["items"] = [
  {
    key: "faq",
    label: (
      <Link to="/faq/business" className="nav-links">
        Business
      </Link>
    ),
  },
  {
    key: "customer",
    label: (
      <Link to="/faq/customer" className="nav-links">
        Customer
      </Link>
    ),
  },
  {
    key: "affiliate",
    label: (
      <Link to="/faq/affiliate" className="nav-links">
        Affiliate
      </Link>
    ),
  },
];

const NavBar = () => {
  const dispatch = useAppDispatch();

  const authState = useAppSelector((state) => state.auth);
  const getMeState = useAppSelector((state) => state.getMe);
  const getCartState = useAppSelector(selectGetCart);
  const home = useRouteMatch("/");

  const [mobileNav, setMobileNav] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [isHome, setIsHome] = useState(false);
  const [user, setUser] = useState(null);

  const logout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    secureLocalStorage.clear(); // clear all session data
    dispatch(clearAuth());
    dispatch(getMeCleanup());
    dispatch(getCartCleanup())
    setUser(null)
  };

  useEffect(() => {
    if (authState?.isLoggedIn) {
      dispatch(getMe());
    }
  }, [authState?.isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getMeState?.isSuccessful) {
      setUser(getMeState?.data?.user);
      dispatch(getMeCleanup());
    } else if (getMeState?.error) {
      setUser(null);
      dispatch(clearAuth());
      dispatch(getMeCleanup());
      dispatch(getCartCleanup())
    } 
  }, [getMeState?.isSuccessful, getMeState?.data, getMeState?.error]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsHome(!!home?.isExact);
    window.addEventListener("scroll", (e) => {
      if (window.scrollY >= 90) {
        setAtTop(false);
      } else {
        setAtTop(true);
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {
        console.log("Removing scroll effect");
      });
    };
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [home?.isExact]);

  return (
    <div className={`public-nav ${isHome && atTop ? "home-nav" : ""}`}>
      <Container>
        <Link className="logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className="mobile-toggler">
          {!mobileNav && (
            <MenuUnfoldOutlined
              onClick={() => setMobileNav(true)}
              className="toggle-btn"
            />
          )}
          {mobileNav && (
            <CloseOutlined
              onClick={() => setMobileNav(false)}
              className="toggle-btn"
            />
          )}
        </div>
        <nav className={`${mobileNav ? "show-nav" : ""}`}>
          <ul id="ul">
            <li className="nav-items">
              <Link to="/" className="nav-links">
                Home
              </Link>
            </li>

            <li className="nav-items">
              <Link to="/products" className="nav-links">
                All VIP Programs
              </Link>
            </li>

            <li className="nav-items">
              <Dropdown menu={{ items }} placement="bottom">
                <div className="nav-links cursor-pointer">FAQ</div>
              </Dropdown>
            </li>

            {!user && (
              <li className="nav-items">
                <Link to="/login" className="nav-links">
                  Login
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-items">
                <Link href="nav-links" to="/" id="logout-btn" onClick={logout}>
                  Logout
                </Link>
              </li>
            )}

            <li className="nav-items cart">
              <Popover
                content={!!user ? <CartItems /> : <UserNotCartAuth />}
                trigger="click"
              >
                <Badge
                  className="cart-badge"
                  count={getCartState?.data?.length}
                  style={{ backgroundColor: "red" }}
                />
                <ShoppingCartOutlined
                  className="nav-links"
                  style={{
                    fontSize: "30px",
                    // scale: 1,
                  }}
                />
              </Popover>
            </li>

            {user && (
              <li className="nav-items user-btn">
                <Link to="/account">
                  <div className="user-avatar">
                    <button className="btn btn-outline-danger">
                      Dashboard
                    </button>
                  </div>
                </Link>
              </li>
            )}
            {!user && (
              <li className="nav-items special">
                <Link className="nav-links" to="/signup">
                  Sign Up
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </Container>
    </div>
  );
};

export default NavBar;
