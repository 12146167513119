import React from "react";

interface TermsProps {
  switchModal: (value: string) => void;
}

const Terms: React.FC<TermsProps> = ({ switchModal }) => {
  return (
    <div>
      <h6
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING THIS
        WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED
        HEREIN AND ALL TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE.
        IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS WEBSITE.
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        Welcome to “<b>SAVENWIN”, </b>“
        <a href="http://www.SaveNWin.com/" className="s1" target="__blank">
          SaveNWin.com
        </a>
        ” and{" "}
        <a href="http://www.SaveNWin.com/" className="s1" target="__blank">
          www.SaveNWin.com
        </a>
        .
      </p>
      <p>
        The following terms and conditions (the “Terms of Service” or
        “Agreement”) form a binding agreement between you and us and apply to
        your access to, and use of, the website of SAVENWIN, located at
        SaveNWin.com and www.SaveNWin.com (the “Site”) and the local offer
        information and related services provided in connection therewith.
        SAVENWIN.com and{" "}
        <a href="http://www.SaveNWin.com/" className="s1" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        is owned and operated by AFFORDABLE ADVERTISING SOLUTIONS, LLC
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        (“SAVENWIN”, “SaveNWin.com”, “we” or “us”), which is offering the
        Services (as defined below). The terms “you” or “your” refer to the
        person accessing or using the Site and Services, or the company or
        organization on whose behalf that person accesses the Site and Services.
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        These Site Terms do not alter in any way the terms or conditions of any
        other agreement you may have with SAVENWIN, SaveNWin.com, Affordable
        Advertising Solutions, LLC or its subsidiaries or affiliates, for
        advertising, products, services or otherwise. If you are using the Site
        on behalf of any entity, you represent and warrant that you are
        authorized to accept these Site Terms and Conditions on such entity’s
        behalf, and that such entity agrees to indemnify you and SAVENWIN,
        SaveNWin.com, &amp; Affordable Advertising Solutions, LLC for violations
        of these Terms.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Our Services
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        We provide a set of local daily deal, offer-based coupon-related tools
        you can use to create, distribute and track the performance of coupons,
        as well as providing businesses verified by us at our discretion with a
        SAVENWIN profile page and the appearance of their coupons in SAVENWIN
        search results, through our Site at
        <a href="https://www.savenwin.com/" className="a" target="__blank">
          {" "}
          SaveNWin.com
        </a>{" "}
        and{" "}
        <a href="https://www.savenwin.com/" className="a" target="__blank">
          https://www.SaveNWin.com
        </a>{" "}
        and any other related websites, toolbars, buttons, widgets, applications
        or other affiliate networks or distribution channels we may, from time
        to time, operate (collectively, the “Services”). You may use the
        Services for your personal
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        and business use or for internal business purposes within the
        organization that you represent.
      </p>
      <h6
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Acceptance of the Terms of Service
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        By accessing or using the Services, you agree to be bound by these Terms
        of Service, whether you are a “Member” (which means that you have
        registered for an account), “Customer” (which means that you have paid
        for our premium Services, where applicable), or a “Visitor” (which means
        that you are visiting the Site or any of our related websites, toolbars,
        widgets, applications or the like). The term “User” refers to a Visitor,
        Customer or a Member. These Terms of Service along with any other
        guidelines we may post from time to time, such as our Privacy Policy
        (collectively, the “Guidelines”) will govern your use of the Services.
        If you do not agree to these Terms of Service, any of our Guidelines or
        any part thereof, your sole and exclusive remedy is to discontinue your
        use of the Site or the Services. VIOLATION OF ANY OF THE TERMS BELOW
        WILL RESULT IN THE TERMINATION OF YOUR RIGHT TO USE THE SAVENWIN.COM
        SERVICE, AND ANY ACCOUNT THAT YOU MAY HAVE CREATED AS PART OF THE
        SERVICE. YOU AGREE TO USE SAVENWIN.COM AT YOUR OWN RISK.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Eligibility
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        By using the Services, you represent and warrant that (a) you are fully
        able and competent to enter into the terms, conditions, obligations,
        representations and warranties set forth in these Terms of Service; (b)
        all registration information you submit is truthful and accurate; (c)
        you will maintain the accuracy of such information; (d) you are 18 years
        of age and older; and (e) your use of the Services does not violate any
        applicable law or regulation. Use of the Services is void where
        prohibited. Each User hereby warrants that if it is or represents a
        corporation or other legal entity, such entity is validly formed and
        existing under the laws of its jurisdiction and has duly authorized its
        agent or agents to enter into this Agreement.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Your Account
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        In order to open an account with SaveNWin.com or www.SaveNWin.com, you
        must (i) agree to these Terms of Service, (ii) provide a valid email
        address, and (iii) provide any other information required by{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        or{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        during the registration process. You can create an account with us by
        registering on the Site or by adding
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        our widget or application through other websites. You are solely
        responsible for maintaining the confidentiality of your password, and
        fully responsible for all activities that occur under your account. You
        agree not to use the account, username or password of another User at
        any time or to disclose your password to any third party or do anything
        else that might jeopardize the security of your account. You agree to
        notify us immediately of any unauthorized use of your account. If we
        have reasonable grounds to suspect violation of these terms or that
        registration information you have provided is untrue, inaccurate,
        outdated, or incomplete, we may terminate your User account and refuse
        current or future use of any or all of the Services. We are not
        responsible for any loss or damage to you or any third party that may be
        incurred as a result of any unauthorized access and/or use of your
        account, or otherwise.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        License Grants &amp; Restrictions
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SAVENWIN
        </a>{" "}
        hereby grants you a non-exclusive, non-transferable, worldwide right to
        access and use{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        and{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>
        , solely with supported browsers through the Internet for your own
        internal purposes, subject to the Terms of Service. You may not permit
        SaveNWin.com to be used by or for the benefit of unauthorized third
        parties. Nothing in the Terms of Service shall be construed to grant you
        any right to transfer or assign rights to access or use SaveNWin.com and
        www.SaveNWin.com. All rights not expressly granted to you are reserved
        by AFFORDABLE ADVERTISING SOLUTIONS, LLC. You shall not (i) modify or
        make derivative works based upon SaveNWin.com or www.SaveNWin.com; (ii)
        reverse engineer or access{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        or{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        in order to
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        (a) build a competitive product or service,
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        (b) build a product using similar features, functions or graphics of
        SaveNWin.com or www.SaveNWin.com, or
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        {" "}
        (c) copy any features, functions or graphics of SaveNWin.com or
        www.SaveNWin.com. You further acknowledge and agree that, as between the
        parties, SAVENWIN owns all rights, title, and interest in and to{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        and{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>
        , the Site and the Services, including all intellectual property rights
        therein.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Free Services; Paid Services
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        Our Daily Deals Voucher/Coupon creation and local offer distribution
        platform is offered to Users free of charge for posting Daily Deal
        Offers. However, there is a 25% back-end sales fee charged on each Daily
        Deal
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Offer that is sold. Likewise, Additional Services may be available to
        Customers under various pricing plans (“Paid Services”) and are subject
        to a separate set of terms – the Advertiser Terms and Conditions. You
        will not be charged for using any Service unless you have opted for a
        Paid Service. Merchant processing fees may be incurred and are the
        responsibility of the Participating Merchant / business.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Your Coupons
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You expressly agree that any voucher, coupon or offer submitted by you
        will be honored by you and that you will use your best efforts to
        effectively administer and resolve any customer issues or problems
        arising from the voucher, coupon or offer and reported to SAVENWIN.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Your Content
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You own all your content, including text, files, images, photos, videos
        and the like, which you store on our Servers and Services or which you
        or your visitors provide by using our Services (collectively,
        “Content”). You grant us a license to access, copy, modify, distribute,
        store, transmit, reformat, list information regarding, republish, edit,
        translate, make derivative works of, publicly display and publicly
        perform such Content to the extent needed to provide our Services to you
        and in any other lawful manner. The license you grant to SAVENWIN is
        non-exclusive (meaning you are free to license your Content to anyone
        else in addition to SAVENWIN), irrevocable, fully-paid and royalty-free
        (meaning that SAVENWIN is not required to pay you for the use of the
        Services or the Content that you post), transferable and sublicensable
        (so that SAVENWIN is able to use its affiliates and subcontractors such
        as Internet content delivery networks to provide the Services), and
        worldwide (because the Internet and the Services are global in reach).
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        You are solely responsible for your Content and the consequences of its
        transmission. You are further responsible for ensuring that you do not
        accidentally make any private Content publicly available. Any Content
        that you may receive through the use of the Services from your visitors
        or otherwise is provided to you AS IS for your information and personal
        use only and you agree not to use or otherwise exploit such Content for
        any purpose without the express written consent of the person who owns
        the rights to such Content. We make no warranties, express or implied,
        as to
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        the Content or to the accuracy or reliability of the Content or any
        material or information that you receive through our Services.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        You further acknowledge and agree that SAVENWIN does not control the
        Content originating from your account or other users of the Site or the
        Services, and does not guarantee the accuracy, integrity or quality of
        such Content. Notwithstanding the foregoing, SAVENWIN may review all
        Content and may block, modify, terminate access to, or remove any such
        Content that SAVENWIN considers, in its sole discretion, does not comply
        with any of the requirements of this Agreement but SAVENWIN is not
        obligated to do so.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        SAVENWIN reserves the right to purge Content from its databases at any
        time and from time to time without notice. You acknowledge and agree
        that you are solely responsible for backing up any Content uploaded to
        the Site by you or received by you through the use of the Services.
        SAVENWIN shall not be liable for any purging, deletion or failure to
        retain any such Content.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        User Data Rights
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        As a User, you may have access to certain information explicitly
        submitted by Users, such as contact information submitted while
        redeeming a coupon (“User Submissions”). In order to provide our
        Services, you also allow us to collect, store and use additional data
        about the usage of our tools that was not explicitly submitted by any
        User, such as IP address of a User or statistics about the usage of
        SAVENWIN coupons (collectively with User Submissions, “User Data”). You
        hereby grant SAVENWIN a nonexclusive, worldwide, perpetual, irrevocable,
        transferable, sublicensable, fully paid-up, royalty-free right and
        license to
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        (i) use, reproduce, store, modify, create derivative works of,
        distribute, translate, publicly perform and display the User Data on or
        through the Site and in all current and future media in which the
        Services may be distributed; (ii) use and disclose the User Data and
        related metrics in an aggregate or other non-personally identifiable
        manner (including, for use in targeting advertising in a non-personally
        identifiable manner through and in connection with the Site); and (iii)
        use User Data for other purposes permitted by our Privacy Policy,
        including the transmission by SAVENWIN or other Members of messages to
        you.
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        You hereby agree not to use, display or share User Data in a manner
        inconsistent with our Terms of Service and Guidelines. You own the
        Content created or stored by you. Unless specifically permitted by you,
        your use of the Services does not grant SAVENWIN a license to use the
        Content created or stored by you for our commercial, marketing or any
        similar purpose. You agree not to use, display or share your Content or
        any data we provide to you in a manner inconsistent with our Terms of
        Service, Guidelines and all applicable laws and regulations. SAVENWIN is
        not required to keep back-up copies of User Data on the Site once your
        account or User Data is deleted. SAVENWIN makes no guarantee that User
        Data will be safely stored on the Site. To be safe, you should
        independently back-up your User Data, to the extent permitted herein and
        by applicable laws and regulations. You acknowledge that SAVENWIN may
        terminate the account of any User in accordance with this Agreement.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Prohibited Activity
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You acknowledge that SAVENWIN may terminate the account of any User for
        engaging in prohibited activities including but not limited to the
        following:
      </p>
      <ol id="l1">
        <li data-list-text={1}>
          <p
            style={{
              paddingTop: "14pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to use the Services for illegal, unauthorized,
            harmful, misleading, fraudulent or other malicious purposes or to
            post, disseminate or communicate any unlawful, defamatory, obscene,
            lewd, excessively violent, harassing, sexually explicit or otherwise
            objectionable subject matter or for the transmission of material
            that contains viruses or other malicious code, or that which
            infringes or may infringe on intellectual property, privacy or other
            rights of another.
          </p>
        </li>
        <li data-list-text={2}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to use the Services for the transmission of “junk
            mail”, “spam”, “chain letters”, “phishing” or unsolicited mass
            distribution of email. Despite these prohibitions, content
            communicated by other Users may contain inaccurate, inappropriate,
            offensive or sexually explicit material, products or services, and
            we assume no responsibility or liability for this material.
          </p>
        </li>
        <li data-list-text={3}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree to comply with: (i) this Agreement, including SAVENWIN’s
            anti-spam policy and all other policies as published on the Site
            from time to time, (ii) all applicable U.S. federal, state, local
            and
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            international laws, including the CAN-SPAM Act of 2003, and all
            other laws related to unsolicited commercial email messages,
            defamation, privacy, obscenity, intellectual property or child
            protective email address registries, (iii) all other rules or
            regulations applicable to User, and (iv) all privacy policies or
            similar policies or procedures to which User may be bound that are
            related to User’s use of the Services.
          </p>
        </li>
        <li data-list-text={4}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to attempt to use another user’s account, impersonate
            any other person, website or entity, misrepresent your affiliation
            with a person or entity, use a false identity, or otherwise mislead
            visitors to and respondents of your coupon or offer.
          </p>
        </li>
        <li data-list-text={5}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to engage in excessive usage of the Site or the
            Services, as determined by SAVENWIN in its sole discretion,
            including usage that adversely affects the speed, responsiveness or
            functionality of the Site, or disrupts the availability of the Site
            and the Services for other users.
          </p>
        </li>
        <li data-list-text={6}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to take any action that imposes an unreasonable or
            disproportionately large load on the Site or its network
            infrastructure.
          </p>
        </li>
        <li data-list-text={7}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to attempt to damage, deny service to, hack, crack,
            reverse engineer or otherwise interfere with the Site or the
            Services in any manner.
          </p>
        </li>
        <li data-list-text={8}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to interfere with or disrupt the Site or the Services
            or servers or networks connected to the Site or the Service, or
            disobey any requirements, procedures, policies, or regulations of
            networks connected to the Site or the Services.
          </p>
        </li>
        <li data-list-text={9}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to disrupt or interfere with the security or use of
            the Services, the Site or any web sites linked to the Site.
          </p>
        </li>
        <li data-list-text={10}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to use the Site or the Services to collect, process,
            or otherwise handle, “Protected Health Information” (as defined in
            45 C.F.R. § 160.103) without SAVENWIN’s prior written consent.
          </p>
        </li>
        <li data-list-text={11}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to upload, post, email, or otherwise transmit any
            Content that you do not have a right to transmit under any law or
            under contractual or fiduciary relationships (such as inside
            information, proprietary, and confidential information learned or
            disclosed as part of employment relationships or under
            non-disclosure agreements).
          </p>
        </li>
        <li data-list-text={12}>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to attempt to obtain unauthorized access to the Site,
            user accounts, computer systems or networks connected to the Site,
            or portions of the Site that are restricted from general access.
          </p>
        </li>
        <li data-list-text={13}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to collect, record, process or mine — manually or
            through an automatic process — information about other users without
            their express consent or other information relating to the Site or
            the Services.
          </p>
        </li>
        <li data-list-text={14}>
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to use any robot, spider, site search/retrieval
            application, or other automated device, process or means to access,
            retrieve, scrape, or index any portion of the Site or any Content.
          </p>
        </li>
        <li data-list-text={15}>
          <p
            style={{
              paddingLeft: "77pt",
              textIndent: "-54pt",
              lineHeight: "19pt",
              textAlign: "justify",
            }}
          >
            You agree NOT to reformat or frame any portion of the Site.
          </p>
        </li>
      </ol>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        You further acknowledge and agree that SAVENWIN may cooperate with any
        governmental authority in connection with any investigation into User’s
        use of the Site or the Services, including use in contravention of
        applicable laws, and may disclose any Content, and any other information
        pertaining to the User or to User’s use of the Site or the Services, to
        such governmental authority in connection with any such investigation.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Without assuming any obligation to do so, we may delete any Content or
        suspend any account associated with it, that we have reasonable grounds
        to believe violates this Agreement or that may be offensive or illegal,
        or violate the rights, harm, or threaten the safety of any person.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Inactive User Accounts Policy
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        We reserve the right to disable or deactivate paid or unpaid User
        accounts that are inactive for more than 90 days. In the event of such
        termination, all data associated with such User account may be deleted.
        We will provide you prior notice of such termination by email. In the
        future, we may limit the number of, or eliminate, free Services and may
        impose different usage restrictions than currently offered.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Service Communications
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        By providing SAVENWIN your email address and phone number as a Member,
        you consent to our using the email address or phone number to send you
        Service-related notices, including any notices required by law, in lieu
        of communication by postal mail. We may also use your email address or
        phone number to send you other messages, such as updates, user
        communications, newsletters, changes to features of the Service, or{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        or{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        offers. If you do not want to
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        receive certain email messages and communications, you may opt out by
        contacting our support team. Opting out may prevent you from receiving
        valuable messages regarding updates, improvements, offers, or
        communications from other Users. SAVENWIN reserves the right to send you
        notices about your account even if you opt out of all voluntary email
        and phone notifications
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Availability and Internet Delays
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        The availability and functionality of the Services depend on various
        factors and elements, including software, hardware and communication
        networks, which may be provided by third parties. These factors are not
        fault-free. We do not warrant or guarantee that the Services will
        operate without disruption, limitations, delays, errors or
        interruptions, or that it will be accessible, or available at all times,
        or immune from unauthorized access or error-free.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Maps
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        Google Maps provides the map features on SaveNWin.com. The data is being
        made available under the Google Maps/Earth Additional Terms of Service
        located
      </p>
      <p
        className="s5"
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        <a
          href="https://www.google.com/intl/en_us/help/terms_maps.html"
          className="a"
          target="__blank"
        >
          at{" "}
        </a>
        https://www.google.com/intl/en_us/help/terms_maps.html
        <span style={{ color: "#545454" }}>.</span>
      </p>
      <h6
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Privacy Protection
      </h6>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        We care about your privacy. Click{" "}
        <button
          className="s6"
          onClick={(e) => {
            e.preventDefault();
            switchModal("privacy");
          }}
        >
          here
        </button>{" "}
        to view our Privacy Policy.
      </p>
      <h6
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Third Party Links and Interactions
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You release SAVENWIN, its officers, employees, agents and successors
        from claims, demands and damages of every kind or nature arising out of
        or related to any disputes with other Users and third parties.
        Additionally, SAVENWIN, Users or third parties may provide hyperlinks on
        the Site or Services, or any other form of link or redirection of your
        connection to other sites (“Third Party Sites”). Links to these Third
        Party Sites are provided solely for your convenience and in no way does
        the inclusion of any link on the Site or Services imply our affiliation
        or endorsement of the linked site, their business practices (including
        their privacy policies) or any information therein. SAVENWIN expressly
        disclaims responsibility for the accuracy, quality, legality, nature,
        availability or reliability of Third Party Sites linked to by or through
        the Site or Services.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Copyright Protection
      </h6>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        In the course of using any of the Services, if you come across any
        Content with copyright notice(s) or any copy protection feature(s), you
        agree not to remove such copyright notice(s) or disable such copy
        protection feature(s) as the case may be. By making any
        copyrighted/copyrightable Content available on any of the Services you
        affirm that you have the consent, authorization or permission, as the
        case may be from every person who may claim any rights in such Content
        to make such Content available in such manner. Further, by making any
        Content available in the manner aforementioned, you expressly agree that
        SAVENWIN will have the right to block access to or remove such Content
        made available by you, if SAVENWIN receives complaints concerning any
        illegality or infringement of third party rights in such Content. By
        using any of the Services and transmitting or publishing any Content
        using such Service, you expressly consent to determination of questions
        of illegality or infringement of third party rights in such Content by
        the agent designated by SAVENWIN for this purpose.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        It is our policy to respond to clear notices of alleged copyright
        infringement that comply with the Digital Millennium Copyright Act of
        1998 (“DMCA”). In addition, we will promptly terminate without notice
        the accounts of those determined by us to be repeat infringers. If you
        are a copyright owner and you believe that any content hosted on the
        Services infringes your copyrights, then you may submit a notification
        pursuant to the DMCA by providing our Copyright Agent with the following
        information in writing:
      </p>
      <ul id="l2">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "14pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright interest;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            a description of the copyrighted work that you claim has been
            infringed;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            a description of where the material that you claim is infringing is
            located on our Services, such as by providing us the URLs to the
            content;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "19pt",
              textAlign: "justify",
            }}
          >
            your address, telephone number, and email address;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            a written statement by you that you have a good faith belief that
            the disputed use is not authorized by the copyright owner, its
            agent, or the law;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.
          </p>
        </li>
      </ul>
      <p
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        The fastest way to reach our Copyright Agent for notices of claims of
        copyright infringement is by email to SAVENWIN:
      </p>
      <p
        className="s5"
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        <a href="mailto:Support@ShopRVA.com" className="a" target="__blank">
          Email:
        </a>
        <a href="mailto:Support@ShopRVA.com" className="s4" target="__blank">
          Support@SaveNWin.co
        </a>
        m<span style={{ color: "#545454" }}>Mailing address:</span>
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        Copyright Agent – SAVENWIN.com
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "140%",
          textAlign: "left",
        }}
      >
        c/o AFFORDABLE ADVERTISING SOLUTIONS, LLC PO Box 28437
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "140%",
          textAlign: "left",
        }}
      >
        Richmond, VA 23228 USA
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Attn: AFFORDABLE ADVERTISING SOLUTIONS, LLC Legal
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Department.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        You acknowledge and agree that we and our licensors retain ownership of
        all intellectual property rights of any kind related to the Site and
        Services, including applicable copyrights, trademarks and other
        proprietary rights. We are not granting any license to you under any of
        those intellectual property rights by virtue of these Terms of Service.
        You further acknowledge and agree that SAVENWIN retains ownership and
        control over the “look and feel” and substance of our tools, widgets,
        buttons, applications and the like. We reserve all rights that are not
        explicitly granted to you in this Agreement.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        or{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        trademarks, logos, service marks, images, trade names, designs, page
        headers, button icons, scripts and other distinctive branding features
        used in connection with the Services are the trademarks, service marks
        or trade dress of{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          SaveNWin.com
        </a>{" "}
        and{" "}
        <a href="http://www.SaveNWin.com/" className="a" target="__blank">
          www.SaveNWin.com
        </a>{" "}
        and may not be copied, imitated, or used, in whole or in part, without
        the prior written permission of SAVENWIN.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Disclaimer of Warranties
      </h6>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        SAVENWIN cannot guarantee continuous service, service at any particular
        time, or integrity of data, information or content stored or
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        transmitted via the Internet. SAVENWIN does not warrant that (i) the
        Services will meet your specific requirements, (ii) the Services will be
        uninterrupted, timely, secure, or error-free, (iii) the results that may
        be obtained from the use of the Services will be accurate or reliable,
        (iv) the quality of any products, services, information, or other
        material purchased or obtained by you through the Services will meet
        your expectations, and
      </p>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        (v) any errors in the Services will be corrected.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        THE MATERIALS, SERVICES, AND PRODUCTS CONTAINED AND OFFERED ON THE SITE
        OR OTHERWISE BY SAVENWIN ARE PROVIDED “AS IS” AND WITHOUT
        REPRESENTATIONS OR WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO
        THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, SAVENWIN DOES
        NOT REPRESENT OR WARRANT THAT THE PRODUCTS, THE SITE, THE SERVICES, THE
        USE OF THE SITE, OR ANY INFORMATION ON THE SITE: (I) WILL BE
        UNINTERRUPTED OR SECURE, (II) WILL BE FREE OF DEFECTS, INACCURACIES OR
        ERRORS, (III) WILL MEET YOUR REQUIREMENTS, OR (IV) WILL OPERATE IN THE
        CONFIGURATION OR WITH OTHER HARDWARE, SOFTWARE OR WEBSITE(S) YOU USE.
        ANY WARRANTIES AND REPRESENTATIONS SPECIFICALLY SET FORTH IN THIS
        AGREEMENT ARE THE ONLY WARRANTIES AND REPRESENTATIONS WITH RESPECT TO
        THIS AGREEMENT, THE SITE, PRODUCTS AND SERVICES, AND ARE IN LIEU OF ANY
        AND ALL OTHER WARRANTIES, WRITTEN OR ORAL, EXPRESS OR IMPLIED, THAT MAY
        ARISE EITHER BY AGREEMENT BETWEEN THE PARTIES HERETO OR BY OPERATION OF
        LAW, NONE OF THESE WARRANTIES AND REPRESENTATIONS WILL EXTEND TO ANY
        THIRD PERSON. SAVENWIN DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR OTHER VIOLATIONS
        OF RIGHTS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
        WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Limitation of Liability
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        IN NO EVENT SHALL SAVENWIN OR ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
        EMPLOYEES, INDEPENDENT CONTRACTORS,
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        TELECOMMUNICATIONS PROVIDERS, OR AGENTS BE LIABLE FOR ANY INDIRECT,
        SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
        WITHOUT LIMITATION DAMAGES RELATING TO LOST REVENUES, LOST PROFITS, LOSS
        OF USE, LOST DATA OR LOSS OF GOODWILL, UNDER ANY CAUSE OF ACTION
        WHATSOEVER INCLUDING, BUT NOT LIMITED TO, CONTRACT, TORT, STRICT
        LIABILITY, WARRANTY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES FOR ANY CLAIM CAUSE OF ACTION, FEE, EXPENSE, COST, OR LOSS
        (COLLECTIVELY, “CLAIMS”) ARISING FROM OR RELATED TO THIS AGREEMENT, THE
        PRIVACY POLICY, THE PRODUCTS OR SERVICES, OR YOUR USE OF THE SITE OR ANY
        PRODUCT OR SERVICE.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        SAVENWIN ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR
        OMISSIONS IN THE SITE OR SERVICES, THAT DEFECTS WILL BE CORRECTED, OR
        THAT THE SITE OR THE SERVERS THAT MAKE SUCH MATERIALS AVAILABLE ARE FREE
        OF VIRUSES OR OTHER HARMFUL COMPONENTS; SAVENWIN FURTHER ASSUMES NO
        LIABILITY OR RESPONSIBILITY FOR ANY FAILURES, DELAYS, MALFUNCTIONS, OR
        INTERRUPTIONS IN THE DELIVERY OF ANYTHING CONTAINED ON THE SITE OR
        SERVICES; ANY LOSSES OR DAMAGES ARISING FROM THE USE OF THE SITE OR
        SERVICES; ANY LOSSES OR DAMAGES ARISING FROM RELIANCE ON DATA IN A
        SAVENWIN.COM ACCOUNT; OR ANY CONDUCT BY USERS OF THE SITE OR SERVICES,
        EITHER ONLINE OR OFFLINE. SAVENWIN DOES NOT WARRANT OR MAKE ANY
        REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE
        MATERIALS ON THE SITE OR SERVICES IN TERMS OF THEIR CORRECTNESS,
        ACCURACY, RELIABILITY, OR OTHERWISE. YOU ASSUME TOTAL RESPONSIBILITY AND
        RISK FOR YOUR USE OF THE SITE, ALL SERVICES AND PRODUCTS AND ANY THIRD
        PARTY WEB SITES AND THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR,
        OR CORRECTION.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        SAVENWIN DOES NOT WARRANT OR GUARANTEE THE ACCURACY OR COMPLETENESS OF
        THE INFORMATION PROVIDED BY THIRD PARTIES. NEITHER THE ACCURACY OR THE
        COMPLETENESS OF ANY INFORMATION PROVIDED WILL BE VERIFIED BY
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        SAVENWIN.COM AND THE USER ASSUMES THE RISK OF SAME. USER IS SOLELY
        RESPONSIBLE FOR IDENTIFYING AND CORRECTING ANY AND ALL ERRORS IN
        INFORMATION STORED ON USER’S BEHALF BY SAVENWIN.COM.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        SAVENWIN ASSUMES NO LIABILITY FOR THE DELAY, FAILURE, INTERRUPTION,
        INTERCEPTION OR CORRUPTION OF ANY DATA OR OTHER INFORMATION COMMUNICATED
        TO OR ENTERED INTO THE SITE, THE RISK OF WHICH IS ASSUMED BY YOU.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        YOU AGREE THAT AFFORDABLE ADVERTISING SOLUTIONS, LLC’S ENTIRE LIABILITY
        AND YOUR SOLE REMEDY FOR ALL CLAIMS SHALL BE LIMITED, IN THE AGGREGATE,
        TO THE GREATER OF (I) USD $150.00, OR (II) THE TOTAL AMOUNT OF MONEY YOU
        PAID TO SAVENWIN IN THE ONE (1) YEAR PERIOD IMMEDIATELY PRECEDING THE
        INCIDENT ON WHICH YOUR ALLEGED CLAIM IS BASED.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        YOU ACKNOWLEDGE AND AGREE THAT, REGARDLESS OF ANY STATUTE OR LAW TO THE
        CONTRARY, ANY CLAIM OR CAUSE OF ACTION YOU MAY HAVE ARISING OUT OF,
        RELATING TO, OR CONNECTED WITH YOUR USE OF THE SITE OR SERVICES, MUST BE
        FILED WITHIN ONE (1) CALENDAR YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION
        ARISES, OR FOREVER BE BARRED.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Indemnification
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You agree to indemnify, defend, and hold harmless SAVENWIN, its
        affiliates and their respective present, former and future officers,
        directors, employees and agents, and their respective heirs, legal
        representatives, successors and assigns (collectively the “Company
        Indemnitees”) from and against any claim, demand, loss, damage, cost, or
        liability (including amounts paid in settlement and reasonable
        attorneys’ fees) arising out of or relating to:
      </p>
      <ul id="l3">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "14pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            any Content you submit, post, transmit, or make available through
            the Site or Services;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            any alleged or actual failure by you to honor or comply with the
            terms of any coupon or offer submitted by you:
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "19pt",
              textAlign: "left",
            }}
          >
            your use or misuse of the Services;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            your connection to the Services;
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            your breach or alleged breach of this Agreement; or
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            your violation of any rights (including intellectual property
            rights) of a third party.
          </p>
        </li>
      </ul>
      <h6
        style={{
          paddingTop: "14pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Changes to Services
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        We reserve the right at any time (and from time to time) to modify,
        suspend, or discontinue providing the Services or any part thereof with
        or without notice. We will not be liable to you or to any third party
        for any modification, suspension or discontinuance of any of these
        Services.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "left",
        }}
      >
        Amendments
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        We may amend, modify, change, add or remove portions of this Agreement
        or any Guidelines at any time without notice to you by posting a revised
        version on the Site and/or by emailing you. The revised version will be
        effective at the time we post it to the Site or otherwise notify you.
        Your continued use of the Services after such posting or notification of
        the changes constitutes your binding acceptance of such changes.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Term and Termination
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        You may terminate your account and end your use of the Services at any
        time and for any or no reason. SAVENWIN has the right (at its sole
        discretion) for any reason to (i) delete, disable or deactivate your
        account, block your email or IP address, or otherwise terminate your
        access to or use of the Services, (ii) remove and discard any Content
        within any account or anywhere on the Site or (iii) shut down an
        account, with or without notice, and with no liability of any kind to
        you.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        If you terminate your account, we will have no obligation to refund you
        any fees you may have paid except as may be required by applicable law.
        Upon deactivating your account, this Agreement terminates and your
        access rights to the Site and any Services immediately cease to exist.
        AFFORDABLE ADVERTISING SOLUTIONS, LLC’s rights survive the termination
        of this Agreement.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "19pt",
          textAlign: "justify",
        }}
      >
        Governing Law and Arbitration
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        This Agreement shall be governed by the laws of the State of Virginia
        without giving effect to any principles that may provide the application
        of the law of another jurisdiction. Any controversy or claim arising out
        of or relating to this Agreement shall be settled by binding arbitration
        before a single arbitrator in accordance with the JAMS Streamlined
        Arbitration
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Rules. The decision of the arbitrator shall be final and unappealable.
        The arbitration shall be conducted in Richmond, Virginia and judgment on
        the arbitration award may be entered into any court having jurisdiction
        thereof. Notwithstanding the foregoing, we may seek injunctive or other
        equitable relief to protect our intellectual property rights in any
        court of competent jurisdiction.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        If either SAVENWIN or you want to arbitrate a dispute, SAVENWIN and you
        each agree to send written notice to the other providing a description
        of the dispute, previous efforts to resolve the dispute, all supporting
        documents/information, and the proposed resolution. Notice to you will
        be sent to your billing address that you provided us and notice to us
        will be sent to: PO Box 28437, Richmond, Virginia 23228. SAVENWIN and
        you each agree to make attempts to resolve this dispute within
        forty-five (45) days of receipt of the notice to arbitrate. Thereafter,
        either SAVENWIN or you may submit the dispute to formal arbitration.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Both you and SAVENWIN each agree not to pursue arbitration on a
        consolidated or classwide basis. Both you and SAVENWIN each agree that
        any arbitration will be solely between you and SAVENWIN (not brought on
        behalf of or together with another individual’s claim). If for any
        reason any court or arbitrator hold that this restriction in
        unconscionable or unenforceable, then this agreement to arbitrate does
        not apply and the dispute must be brought in court.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        No Class Actions
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        TO THE EXTENT ALLOWED BY LAW, YOU WAIVE ANY RIGHT TO PURSUE DISPUTES ON
        A CONSOLIDATED OR CLASSWIDE BASIS; THAT IS, TO EITHER JOIN A CLAIM WITH
        THE CLAIM OF ANY OTHER PERSON OR ENTITY, OR ASSERT A CLAIM IN A
        REPRESENTATIVE CAPACITY ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT,
        ARBITRATION, OR OTHER PROCEEDING.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        No Trial by Jury
      </h6>
      <p
        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
      >
        TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO TRIAL BY JURY
        IN ANY LAWSUIT, ARBITRATION, OR OTHER PROCEEDING.
      </p>
      <h6
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        General
      </h6>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        The failure of SAVENWIN to exercise or enforce any right or provision of
        this Agreement does not constitute a waiver of such right or provision.
        If any provision of this Agreement is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the
        court should try to give effect to the parties’ intentions as reflected
        in the provision, and the other provisions of this Agreement remain in
        full force and effect. SAVENWIN may assign this Agreement in whole or in
        part in its sole discretion without your consent and without notice.
        This Agreement constitutes the entire agreement between you and SAVENWIN
        and governs your use of the Services, superseding any prior agreements
        (whether written or oral) between you and SAVENWIN regarding the subject
        matter hereof. Nothing in this Agreement shall prevent us from complying
        with the law and applicable regulations.
      </p>
      <p
        style={{
          paddingTop: "7pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
        className="text-secondary"
      >
        Last Updated: May 4, 2021
      </p>
    </div>
  );
};

export default Terms;
