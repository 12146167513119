import { Modal } from "antd";
import { ModalFooterRender } from "antd/lib/modal/interface";
import { PropsWithChildren, ReactNode } from "react";

type ModalProps = {
  title: string;
  open: boolean;
  onOpen: () => void;
  confirmLoading?: boolean;
  onClose?: () => void;
  footer?: ReactNode | ModalFooterRender;
};

export const CustomModal = (props: PropsWithChildren<ModalProps>) => (
  <Modal
    title={props.title}
    open={props.open}
    onOk={props.onOpen}
    onCancel={props.onClose}
    footer={props.footer}
    confirmLoading={props.confirmLoading}
  >
    {props.children}
  </Modal>
);
