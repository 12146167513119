import { Col, Row } from "antd";
import { ReactNode } from "react";

type CustomRowPropsType = {
  title: ReactNode;
  description: ReactNode;
};

export function CustomRow(props: CustomRowPropsType) {
  return (
    <Row className="d-flex justify-content-between align-items-center my-1">
      <Col className="text-left fw-bold">{props?.title}</Col>
      <Col className="text-right text-truncate">{props?.description}</Col>
    </Row>
  );
}
