import { LoadingOutlined } from "@ant-design/icons";
import { Col, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateWishlistItem } from "../../../Store/actions/wishlist";
import { WishlistItemType } from "../../../Store/initialStates";
import america from "../../../Utils/location/america";
import styles from "./index.module.css";

const EditWishlistModal: React.FC<{
  open: boolean;
  item: WishlistItemType;
  handleModals: (type: "HIDE_EDIT_MODAL") => void;
}> = ({ open, handleModals, item }) => {
  const dispatch = useDispatch();
  const [wishlist, setWishlist] = useState<WishlistItemType | null>(null);
  const [cities, setCities] = useState<string[]>([]);

  const states = Object.keys(america);

  useEffect(() => {
    if (wishlist?.state) {
      return setCities(america[wishlist?.state]);
    }
    setCities([]);
  }, [wishlist?.state]);

  useEffect(() => {
    setWishlist(item);
  }, [item]);

  const handleFormState = (
    e: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    let value: string | string[] = e.target.value;
    const type = e.target.type;

    if (type === "checkbox") {
      const event = e as React.ChangeEvent<HTMLInputElement>;
      const checked = event.target.checked;
      setWishlist((old) => ({
        ...(old as WishlistItemType),
        fulfilled: checked,
      }));
      return;
    }

    if (name === "keywords") {
      value = value.split(",");
    }

    setWishlist((old) => ({ ...(old as WishlistItemType), [name]: value }));
    return;
  };

  const handleFormSubmit = () => {
    if (wishlist) {
      const { title, description, state, city, type, country, fulfilled, _id } =
        wishlist;
      if (!title.length) {
        message.error({
          duration: 2,
          content: "Please enter a title",
        });
        return;
      }
      if (!description.length) {
        message.error({
          duration: 2,
          content: "Please enter a description",
        });
        return;
      }
      if (!state.length) {
        message.error({
          duration: 2,
          content: "Please enter a state",
        });
        return;
      }
      if (!city.length) {
        message.error({
          duration: 2,
          content: "Please enter a city",
        });
        return;
      }
      if (!type.length || (type !== "product" && type !== "service")) {
        message.error({
          duration: 2,
          content: "Please choose a valid type",
        });
        return;
      }
      if (!country.length) {
        message.error({
          duration: 2,
          content: "Please enter a country",
        });
        return;
      }

      const wishObj: {
        title: string;
        description: string;
        state: string;
        city: string;
        type: "product" | "service";
        country: string;
        keywords: string[];
        fulfilled: boolean;
        _id: string;
      } = {
        title,
        description,
        state,
        city,
        type,
        country,
        fulfilled,
        _id,
        keywords: wishlist.keywords.map((value) => value.trim()),
      };

      dispatch(updateWishlistItem(wishObj) as any);
      handleModals("HIDE_EDIT_MODAL");
      return;
    }
    message.error({
      duration: 2,
      content: "something went wrong",
    });
  };

  return (
    <Modal
      title="Edit Wishlist"
      open={open}
      onCancel={() => handleModals("HIDE_EDIT_MODAL")}
      onOk={() => handleFormSubmit()}
      width="50%"
      okText="Update"
      cancelText="Close"
    >
      {!wishlist && (
        <div className="d-flex justify-content-center w-100">
          <LoadingOutlined style={{ fontSize: 30 }} spin={true} />
        </div>
      )}
      {wishlist && (
        <form name="editWishlist" onSubmit={() => handleFormSubmit()}>
          <Row className="mb-3">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <label
                htmlFor="title"
                className={`form-label ${styles.label_form}`}
              >
                Title
              </label>
              <input
                placeholder="Add a title"
                className={`${styles.input_field} d-block`}
                type="text"
                name="title"
                id="title"
                value={wishlist.title}
                onChange={handleFormState}
                required={true}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <label
                htmlFor="description"
                className={`form-label ${styles.label_form}`}
              >
                Description
              </label>
              <textarea
                placeholder="Add a description"
                className={`${styles.input_field} d-block`}
                name="description"
                id="description"
                value={wishlist.description}
                onChange={handleFormState}
                rows={1}
                required={true}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <label
                htmlFor="state"
                className={`form-label ${styles.label_form}`}
              >
                State
              </label>
              <select
                name="state"
                id="state"
                className={`${styles.input_field} d-block`}
                onChange={handleFormState}
                value={wishlist.state}
              >
                {states.map((state) => {
                  return <option value={state}>{state}</option>;
                })}
              </select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <label
                htmlFor="city"
                className={`form-label ${styles.label_form}`}
              >
                City
              </label>
              <select
                name="city"
                id="city"
                className={`${styles.input_field} d-block`}
                onChange={handleFormState}
                value={wishlist.city}
              >
                {cities.map((city) => {
                  return <option value={city}>{city}</option>;
                })}
              </select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>
              <label
                htmlFor="country"
                className={`form-label ${styles.label_form}`}
              >
                Country
              </label>
              <input
                placeholder="Add a country"
                className={`${styles.input_field} d-block`}
                type="text"
                name="country"
                id="country"
                value={wishlist.country}
                onChange={handleFormState}
                required={true}
              />
            </Col>
            <Col span={12}>
              <label
                htmlFor="fulfilled"
                className={`form-label ${styles.label_form}`}
              >
                Status
              </label>
              <div className="d-flex align-items-center py-2">
                <span className="me-2">Fulfilled</span>
                <input
                  className={`d-block`}
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                  checked={wishlist.fulfilled}
                  onChange={handleFormState}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label
                htmlFor="type"
                className={`form-label ${styles.label_form}`}
              >
                Type
              </label>
              <select
                name="type"
                id="type"
                className={`${styles.input_field} d-block`}
                onChange={handleFormState}
              >
                <option value="none" defaultValue={""} disabled hidden>
                  Select a Type
                </option>
                <option value="product">Product</option>
                <option value="service">Service</option>
              </select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <label
                htmlFor="keywords"
                className={`form-label ${styles.label_form}`}
              >
                Keywords
              </label>
              <input
                placeholder="Split keywords with a comma"
                className={`${styles.input_field} d-block`}
                type="text"
                name="keywords"
                id="keywords"
                value={wishlist.keywords}
                onChange={handleFormState}
              />
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  );
};

export default EditWishlistModal;
