import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Pagination } from "antd";
import ProgramCard from "./Card";
import { Row } from "reactstrap";

import "./List.css";
import { useState } from "react";

type ProgramListType = {
  data: any[];
  isLoading: boolean;
  showControls?: boolean;
  variant?: number;
};

const pageLength = 5;
function ProgramList(props: ProgramListType) {
  const [pageNo, setPageNo] = useState(1);

  const handleChangePage = (page: number) => {
    setPageNo(page);
  };

  if (!props?.data?.length)
    return <Empty description="No program is available" />;

  const renderList = () => {
    return props.data.slice(pageLength * (pageNo - 1), pageNo * pageLength);
  };

  return (
    <div className="program__list">
      {props?.isLoading ? (
        <div className="text-center py-5 w-100">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <>
          <Row xs="12" md="3" lg="4" style={{ gap: "1rem" }}>
            {renderList().map((program: any) => (
              <ProgramCard
                variant={props.variant}
                key={program?._id}
                program={program}
                showControls={props?.showControls}
              />
            ))}
          </Row>
          <Pagination
            onChange={handleChangePage}
            total={props?.data?.length??0}
            hideOnSinglePage
          />
        </>
      )}
    </div>
  );
}

export default ProgramList;
