import { Empty } from "antd";
import { Link } from "react-router-dom";
//
import { Button } from "../../../../Components/ui/Button";

export const UserNotCartAuth = () => (
  <Empty
    // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{ height: 60, marginBlock: "20px" }}
    description={<span>You are not logged in.</span>}
  >
    <Link to="/login">
      <Button type="primary" className="text-danger border border-danger">
        Login
      </Button>
    </Link>
  </Empty>
);
