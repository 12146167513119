/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Promotion from "./Promotion";
import PopularPrograms from "./PopularProgram";

//components
import SubscriptionModal from "./SubscriptionModal";

//actions
import {
  selectSubscription,
  subCleanup,
  subscribe,
} from "../../Store/actions/subscription";
import { productList } from "../../Store/actions/productList";
import { useAppDispatch, useAppSelector } from "../../hooks";
import InputField from "../../Components/ui/form/Input";
import { Button } from "../../Components/ui/Button";
import { Alert } from "antd";

const SUB_MODAL_COOKIE = "__sub_mod";
const SUB_MODAL_COOKIE_EXPIRY = 7;

const Home: React.FC = () => {
  const [modal, setModal] = useState(true);
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [showSubModal, setShowSubModal] = useState(false);
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const subscription = useAppSelector(selectSubscription);

  const toggle = () => setModal(!modal);

  const createCookie = (name: string, value: string, days: number): void => {
    let expires: string;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; SameSite=Lax";
    console.log(document.cookie);
  };

  const handleSubscribe = ({ email }: { email: string }) => {
    dispatch(subscribe(email));
  };

  const handleSubmitSubscription = (e: any) => {
    e.preventDefault();
    dispatch(subCleanup()); // clean up the current state before initiating another API request
    dispatch(subscribe(subscriberEmail));
  };

  useEffect(() => {
    dispatch(productList({ page: 1, limit: 4, main: "products" }));

    /** Sub modal */
    let modalTimeout;
    if (
      !authState.isLoggedIn &&
      !document.cookie
        .split("; ")
        .find((row) => row.startsWith(SUB_MODAL_COOKIE))
    ) {
      modalTimeout = setTimeout(() => {
        setShowSubModal(true);
        createCookie(SUB_MODAL_COOKIE, "true", SUB_MODAL_COOKIE_EXPIRY);
      }, 5000);
    } else {
      setShowSubModal(false);
    }

    return () => {
      clearTimeout(modalTimeout);
    };
  }, []);

  return (
    <div className="home">
      {subscription?.error || subscription?.isSuccessful ? (
        <Alert
          showIcon
          closable
          style={{ maxWidth: "400px", marginInline: "auto" }}
          message={subscription?.error || subscription?.data?.message}
          type={!!subscription?.error ? "error" : "success"}
        />
      ) : null}

      <Hero />
      <Promotion />
      {/* <PopularProducts /> */}
      <PopularPrograms />
      <div className="subscribe__content--wrapper">
        <div className="subscribe__content">
          <h2 className="fs-1 fw-bolder">Subscribe to our newsletter</h2>
          <p>
            Learn about our new features, enhancements and upgrades as we
            progress with our platform in the upcoming months. Providing your
            email address will keep you up to date and informed regarding
            contests and new opportunities.
          </p>
          <form
            className="subscription__form"
            onSubmit={handleSubmitSubscription}
          >
            <InputField
              label=""
              onChange={(e: any) => setSubscriberEmail(e.target.value)}
              type="email"
              name="email"
              placeholder="Your email address"
            />
            <Button
              className="btn--subscription"
              loading={subscription?.isLoading}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
      {showSubModal && (
        <SubscriptionModal
          toggle={toggle}
          subscribe={handleSubscribe}
          modal={modal}
        />
      )}
    </div>
  );
};

export default Home;
