import { RootState } from "../..";
import AxiosCall from "../../../Utils/axios";
import { paramsFormat } from "../../../Utils/axios/paramsFormat";
import ErrorHandler from "../../../Utils/error-handler";

export const programsStart = () => ({
  type: "PROGRAMS_START",
});

export const programsSuccess = (payload) => ({
  type: "PROGRAMS_SUCCESS",
  payload,
});

export const programsFail = (payload) => ({
  type: "PROGRAMS_FAIL",
  payload,
});

export const programsCleanup = () => ({
  type: "PROGRAMS_CLEANUP",
});

// selectors
export const selectPrograms = (state: RootState) => state.programs;
export const selectProgramsCursors = (state: RootState) => ({
  next: state?.programs.data.next,
  previous: state?.programs.data.prev,
});
export const selectProgramsCurrentPage = (state: RootState) =>
  state.programs.data.currentPage;

export const programsList = (payload?: any) => async (dispatch) => {
  const page = payload?.page ?? 1;
  const limit = payload?.limit ?? 30;
  const city = payload?.city ?? "";
  const state = payload?.state ?? "";
  const businessName = payload?.businessName ?? "";
  const programName = payload?.programName ?? "";

  const query = {
    page,
    limit,
    city,
    state,
    businessName,
    programName,
  };

  const params = paramsFormat(query);
  try {
    dispatch(programsStart());
    const requestObj = {
      path: `programs?${params}`,
      method: "GET",
      version: "v2",
    };

    const { data } = await AxiosCall(requestObj);
    dispatch(programsSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(programsFail(error));
  }
};
