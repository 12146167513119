/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./profile.css";

//packages
import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Select, Alert, Flex } from "antd";
import { Link } from "react-router-dom";

import america from "../../../../Utils/location/america";

import {
  updateProfile,
  updateProfileCleanup,
} from "../../../../Store/actions/updateProfile";
import { getMe, selectLoggedInUser } from "../../../../Store/actions/getMe";
import { useAppSelector } from "../../../../hooks";
import { CustomModal } from "../../../../Components/ui/Modals";

function EditProfile() {
  const updateProfileState = useAppSelector((s) => s.updateProfile);
  const userState = useAppSelector(selectLoggedInUser);

  const [error, setError] = useState(null);
  // implement form state using useState with default values
  const [form, setForm] = useState({
    name: "",
    businessName: "",
    businessType: "",
    address: "",
    city: "",
    state: "",
    ...userState?.data?.user,
  });

  const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const states = Object.keys(america);

  const dispatch = useDispatch();
  const { Option } = Select;

  const handleOpenModal = () => setOpen(true);
  const handleModalSuccess = () => {
    setOpen(false);
  };
  const handleCloseModal = () => setOpen(false);

  useEffect(() => {
    if (updateProfileState.isSuccessful) {
      dispatch(getMe());
      dispatch(updateProfileCleanup());
    } else if (updateProfileState.error) {
      setError(updateProfileState.error);
      dispatch(updateProfileCleanup());
    }
  }, [updateProfileState]);

  const onFinish = (values) => {
    dispatch(updateProfile(values));
    setDisabled(!disabled);
  };

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeState = (state) => {
    const cities = america[state];
    setCities([]);

    if (!state) return setError("Please select a state");

    setForm({
      ...form,
      state,
      city: "",
    });

    setCities(cities);
  };

  // handle select city
  const handleSelectCity = (city) => {
    setForm({
      ...form,
      city,
    });
  };

  // inform the user to complete their profile information
  useLayoutEffect(() => {
    const fields = [
      "city",
      "state",
      "country",
      "address",
      "name",
      "businessName",
      "businessType",
    ];

    for (const key of fields) {
      if (userState?.data?.user?.role === "business" && form[key] === "") {
        handleOpenModal();
        break;
      } else if (
        form[key] === "" &&
        !!userState?.data?.user?.role &&
        !["businessName", "businessType"].includes(key)
      ) {
        handleOpenModal();
        break;
      }
    }
  }, [userState?.data?.user?.role]);

  return (
    <div className="editProfileWrapper">
      <div className="editProfileInner">
        <Flex className="mb-3" justify="space-between" align="center">
          <Button
            onClick={() => setDisabled(!disabled)}
          >
            Edit Profile
          </Button>
          <Link
            to="/programs-list"
            className="btn btn-warning text-white"
            style={{ textDecoration: "none" }}
          >
            Continue to Shopping
          </Link>
        </Flex>
        <div className="editProfileInput">
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: "3rem" }}
            name="login"
            onFinish={onFinish}
            initialValues={form}
          >
            {error && <Alert message={error} type="error" showIcon closable />}
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "please input your name",
                },
                {
                  type: "string",
                },
                {
                  min: 4,
                  message: "name shoud be more than 4 characters long",
                },
              ]}
              labelAlign="left"
            >
              <Input
                disabled={disabled}
                name="name"
                onChange={onChange}
                placeholder={"Enter Full Name"}
              />
            </Form.Item>
            {form?.role === "business" && (
              <>
                <Form.Item
                  label="Business Name"
                  name="businessName"
                  rules={[
                    {
                      required: true,
                      message: "please input your name",
                    },
                    {
                      type: "string",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input
                    disabled={disabled}
                    placeholder={
                      form?.businessName
                        ? form?.businessName
                        : "Enter Business Name"
                    }
                    onChange={onChange}
                    name="businessName"
                    defaultValue={form?.businessName}
                  />
                </Form.Item>
                <Form.Item
                  label="Business Type"
                  name="businessType"
                  rules={[
                    {
                      required: true,
                      message: "please input your name",
                    },
                    {
                      type: "string",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input
                    disabled={disabled}
                    placeholder={
                      form?.businessType
                        ? form?.businessType
                        : "Enter Business Type"
                    }
                    onChange={onChange}
                    name="businessType"
                    defaultValue={form?.businessType}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
              labelAlign="left"
            >
              <Input
                disabled={disabled}
                placeholder={"Enter Address"}
                name="address"
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "please select a state",
                },
              ]}
            >
              <Select
                disabled={disabled}
                placeholder={form?.state ? form?.state : "Select State"}
                onChange={onChangeState}
                defaultValue={form?.state}
              >
                {states.map((state, index) => (
                  <Option value={state} key={index}>
                    {state}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "please select a city",
                },
              ]}
            >
              <Select
                disabled={disabled}
                placeholder={form?.city ? form?.city : "Select City"}
                onSelect={handleSelectCity}
                defaultValue={form?.city}
              >
                {cities.map((city, index) => (
                  <Option value={city} key={index}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!disabled && (
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
                <Button onClick={() => setDisabled(!disabled)}>Cancel</Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>

      <CustomModal
        title="Complete form before you proceed"
        open={open}
        onClose={handleCloseModal}
        onOpen={handleModalSuccess}
      >
        You have to complete the form before you proceed. This is done to ensure
        that you have accurate information on the platform.
      </CustomModal>
    </div>
  );
}

export default EditProfile;
