/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";
import { Container } from "reactstrap";
import { BookOutlined, HomeOutlined, PaperClipOutlined, PicCenterOutlined, RocketOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom'

const Login = () => {
  const { role } = useParams();

  return (
    <div className="faq">
      <Container>
        <h3 className="text-center mb-4 pb-2 text-danger fw-bold">FAQ <span className={'text-capitalize'}>({role})</span></h3>
        <p className="text-center mb-5">
          Find the answers for the most frequently asked questions below
        </p>

        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><PaperClipOutlined style={{ marginRight: 3 }} /> A simple
              question?</h6>
            <p>
              <strong><u>Absolutely!</u></strong> We work with top payment companies which guarantees
              your
              safety and
              security. All billing information is stored on our payment processing partner.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><PicCenterOutlined style={{ marginRight: 3 }} /> A question
              that
              is longer then the previous one?</h6>
            <p>
              <strong><u>Yes, it is possible!</u></strong> You can cancel your subscription anytime in
              your
              account. Once the subscription is
              cancelled, you will not be charged next month.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><UserOutlined style={{ marginRight: 3 }} /> A simple
              question?
            </h6>
            <p>
              Currently, we only offer monthly subscription. You can upgrade or cancel your monthly
              account at any time with no further obligation.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><RocketOutlined style={{ marginRight: 3 }} /> A simple
              question?
            </h6>
            <p>
              Yes. Go to the billing section of your dashboard and update your payment information.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><HomeOutlined style={{ marginRight: 3 }} /> A simple
              question?
            </h6>
            <p><strong><u>Unfortunately no</u>.</strong> We do not issue full or partial refunds for any
              reason.</p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-danger d-flex"><BookOutlined style={{ marginRight: 3 }} /> Another
              question that is longer than usual</h6>
            <p>
              Of course! We’re happy to offer a free plan to anyone who wants to try our service.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
