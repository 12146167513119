import Axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { config } from "../../config";

const baseURL = `${config.REACT_APP_SERVER_HOST}/api/`;

const AxiosCall = async (requestObj) => {
  const { path, method, data, contentType } = requestObj;

  const version = requestObj?.version ?? "v1";

  const token = secureLocalStorage.getItem("authToken");
  const headers = {
    Authorization: `Bearer ${token}` ,
    "Content-Type": contentType || "application/json",
  };

  const url = version ? `${baseURL}${version}/${path}` : `${baseURL}${path}`;
  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;
    return result;
  } catch (error) {
    if (error?.response?.data?.status === 401) {
      secureLocalStorage.removeItem("authToken");
      if (!["/login", "/"].includes(window.location.pathname)) {
        window.location.href = "/login";
      }
    }
    throw error;
  }
};

export default AxiosCall;
