import { Col } from "antd";
import { ReactNode } from "react";
import { formatCurrency } from "../../../Utils";
import { CustomRow } from "./CustomRow";

type ProgramQRCodePropsType = {
  qrCode: ReactNode;
  title: string;
  itemId: string;
  description: string;
  sellingPrice: number;
  totalPrice: number;
  discountPrice: number;
  platformCharge: number;
};

export function ProgramQRCode(props: ProgramQRCodePropsType) {
  return (
    <Col className="programQRCode detail px-2 pb-3 gap-4">
      <div className="image__wrapper d-grid my-2">{props.qrCode}</div>

      <CustomRow title="Title:" description={props.title} />
      <CustomRow title="Description:" description={props.description} />

      <CustomRow title="Quantity:" description={(1).toFixed(2)} />

      <CustomRow title="ID:" description={props?.itemId} />

      <CustomRow
        title="Price:"
        description={
          <div className="d-flex gap-4">
            <span className="bg-danger text-white py-1 px-2 border-1 rounded-pill">
              {formatCurrency(props?.sellingPrice)}
            </span>
            <s className="fw-bold" aria-details="Original program price">
              {formatCurrency(props?.totalPrice)}
            </s>
          </div>
        }
      />

      <CustomRow
        title="Discount:"
        description={formatCurrency(props?.discountPrice)}
      />

      <CustomRow
        title="Platform fee:"
        description={formatCurrency(props?.platformCharge)}
      />
    </Col>
  );
}
