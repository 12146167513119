/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import List from "../../../../Components/UsersList";
import Paginate from "../../../../Components/Paginate";

import { users, usersCleanup } from "../../../../Store/actions/users";
import { toast } from "react-toast";
import AxiosCall from "../../../../Utils/axios";
import { ModalWrapper, Modal, SearchWrapper } from "./styles";
import Loader from "../../../../Components/Loader";
import { Avatar, message, Select } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

const BussinessList = () => {
  const { Option } = Select;
  const key = "updatable";
  const userListState = useSelector((s) => s.users);
  const [userList, setUserList] = useState(null);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(0);
  const [userProfileModal, setUserProfileModal] = useState(false);
  const userProfileRef = useRef(null);
  const [visibility, setVisibility] = useState(null);
  const [navigationData, setNavigationData] = useState(null);

  const searchQuery = useRef({ name: "", email: "" });
  const [isSearching, setIsSearching] = useState(false);

  const [userData, setUserData] = useState(null);
  const [sortValue, setSortValue] = useState("notdeleted");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(users({ role: "business", status: sortValue }));
  }, [sortValue]);

  useEffect(() => {
    if (userListState.isSuccessful) {
      setUserList(userListState.data.users);
      setNavigationData(userListState.data);
      dispatch(usersCleanup());
    } else if (userListState.error) {
      setError(userListState.error);
      dispatch(usersCleanup());
    }
  }, [userListState]);

  const closeModal = (e) => {
    if (e.target == userProfileRef.current) {
      setUserData(null);
      setUserProfileModal(false);
    }
  };

  const actions = {
    showUserDetails: async function (_id) {
      try {
        setIsSending(_id);
        setUserProfileModal(true);
        const result = await AxiosCall({
          method: "GET",
          path: "admin/users/" + _id,
        });

        setUserData(result.data);

        setIsSending(0);
        toast("successful");
        setVisibility(null);
      } catch (error) {
        toast("something went wrong");
      }
    },
    moreOptions: [
      {
        title: "View",
        onClick: function (_id) {
          this.showUserDetails(_id);
        },
      },
      {
        title: "Verify",
        onClick: async function (_id) {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "GET",
              path: "admin/verify/" + _id,
            });
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              const rawList = userList;
              const selectedUser = userList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].verifiedBusiness = "yes";
              setUserList({ ...userList, rawList });
              message.success({ content: "User Verified", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },

      {
        title: "Suspend 2 weeks",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
              data: {
                month: 0,
                week: 2,
              },
            });
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              const rawList = userList;
              const selectedUser = userList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = true;
              setUserList({ ...userList, rawList });
              message.success({ content: "User suspended", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Suspend 1 month",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
              data: {
                month: 1,
                week: 0,
              },
            });
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              const rawList = userList;
              const selectedUser = userList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = true;
              setUserList({ ...userList, rawList });
              message.success({ content: "User suspended", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            message.error({
              content: error.request.responsee,
              key,
              duration: 2,
            });
            setVisibility(null);
          }
        },
      },
      {
        title: "Lift suspention",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
            });
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              const rawList = userList;
              const selectedUser = userList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = false;
              setUserList({ ...userList, rawList });
              message.success({
                content: "Suspension lifted",
                key,
                duration: 2,
              });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Delete",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            // const result = await AxiosCall({
            //   method: "DELETE",
            //   path: "users/" + _id,
            // });
            // dispatch(users({ role: 'business' }))
            setUserProfileModal(false);
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);
          } catch (error) {
            message.error({
              content: error.response.data.message,
              key,
              duration: 2,
            });
          }
        },
      },
      {
        title: "Restore",
        onClick: async (_id) => {
          try {
            // const result = await AxiosCall({
            //   method: "POST",
            //   path: "users/" + _id + '/restore',
            // });
            // dispatch(users({ role: 'business' }))
            setUserProfileModal(false);
            dispatch(users({ role: "business", status: sortValue }));
            setIsSending(0);
            setVisibility(null);
          } catch (error) {
            message.error({
              content: error.response.data.message,
              key,
              duration: 2,
            });
          }
        },
      },
    ],
  };

  const navigate = (data) => {
    dispatch(
      users({
        ...data,
        role: "business",
      })
    );
  };

  const searchUser = async () => {
    setIsSearching(true);
    try {
      const result = await AxiosCall({
        method: "GET",
        path: `users/search?email=${searchQuery.current.name}`,
      });

      setUserList(result.data.users);

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);

      message.error({ content: error.response.data.message, key, duration: 2 });
    }
  };

  return (
    <div className="Bussiness-list container">
      <h3>Business Accounts</h3>

      <SearchWrapper>
        <div>
          <input
            type="text"
            onChange={(e) => {
              searchQuery.current = {
                ...searchQuery.current,
                name: e.target.value,
              };
            }}
          />
          <button onClick={searchUser}>
            {isSearching ? <Loader size={"20px"} /> : "Search"}
          </button>
        </div>
        <div>
          <Select
            onChange={(value) => setSortValue(value)}
            style={{
              width: "100%",
              minHeight: "100%",
              display: "flex",
              alignItems: "center",
            }}
            value={sortValue}
            showArrow={true}
            bordered={true}
          >
            <Option value="notdeleted">Default</Option>
            <Option value="all">All businesses</Option>
            <Option value="active">Verified businesses</Option>
            <Option value="inactive">Unverified businesses</Option>
            <Option value="suspended">Suspended businesses</Option>
            <Option value="deleted">Deleted businesses</Option>
          </Select>
        </div>
      </SearchWrapper>
      {userListState.isLoading ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <List
          data={userList}
          error={error}
          actions={actions}
          isLoading={isSending}
          setVisibility={setVisibility}
          visibility={visibility}
        />
      )}
      {/* {userList && <Paginate data={navigationData} navigate={navigate} />} */}
      {userProfileModal && (
        <ModalWrapper ref={userProfileRef} onClick={closeModal}>
          <Modal>
            {!userData ? (
              <Loader />
            ) : (
              <>
                <div className="profile-overview">
                  <div className="img-wrapper">
                    <Avatar
                      src={userData.avatar.secure_url}
                      size={100}
                      icon={<UserOutlined />}
                    />
                  </div>
                  <div className="user-info">
                    <span>{userData?.name}</span>
                  </div>
                </div>

                <ul>
                  <li>
                    <span>Email: </span> <span>{userData?.email}</span>
                  </li>
                  <li>
                    <span>Business: </span>{" "}
                    <span>{userData?.businessType}</span>
                  </li>
                  <li>
                    <span>Phone: </span> <span>{userData?.phoneNo}</span>
                  </li>
                  <li>
                    <span>City: </span> <span>{userData?.city}</span>
                  </li>
                  <li>
                    <span>State: </span> <span>{userData?.state}</span>
                  </li>
                </ul>
              </>
            )}
          </Modal>
        </ModalWrapper>
      )}
    </div>
  );
};

export default BussinessList;
