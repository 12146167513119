import { ToastContainer } from "react-toast";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import Routes from "./Routes";
import store, { persistor } from "./Store";
import "./App.css";
import "./Utils/utils.css";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
      <ToastContainer position="top-right" />
    </Provider>
  );
};

export default App;
