import { Modal } from "antd";

type CartCheckoutConsentModalPropsType = {
  open: boolean;
  onClose: () => void;
  onProceed: (e: any) => void;
};

export function CartCheckoutConsentModal(
  props: CartCheckoutConsentModalPropsType
) {
  return (
    <Modal
      title="Terms of use"
      open={props.open}
      onCancel={props?.onClose}
      closable
      footer={null}
    >
      <p>
        Terms and Conditions General Site Usage Last Revised: December 16, 2013
        Welcome to www.lorem-ipsum.info. This site is provided as a service to
        our visitors and may be used for informational purposes only. Because
        the Terms and Conditions contain legal obligations, please read them
        carefully. 1. YOUR AGREEMENT By using this Site, you agree to be bound
        by, and to comply with, these Terms and Conditions. If you do not agree
        to these Terms and Conditions, please do not use this site. PLEASE NOTE:
        We reserve the right, at our sole discretion, to change, modify or
        otherwise alter these Terms and Conditions at any time.
      </p>
      <form onSubmit={props?.onProceed}>
        <div className="mt-3 d-flex align-items-center gap-2">
          <input type="checkbox" required />
          <label>I have read and agreed to terms of use</label>
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-success mt-4">
            Proceed
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default CartCheckoutConsentModal;
