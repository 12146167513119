import React from "react";
import { ModalWrapper, Modal } from "./styles";
import Loader from "../../../Components/Loader";

export default function UserProfileModal({ userData, onClose, userRef }) {
  return (
    <ModalWrapper ref={userRef} onClick={onClose}>
      <Modal>
        {!userData ? (
          <Loader />
        ) : (
          <>
            <div className="profile-overview">
              <div className="img-wrapper">
                <img
                  src={userData.avatar.secure_url}
                  alt=""
                  crossOrigin="anonymous"
                />
              </div>
              <div className="user-info">
                <span>{userData.name}</span>
              </div>
            </div>

            <ul>
              <li>
                <span>Email: </span> <span>{userData.email}</span>
              </li>
              {userData?.businessType && (
                <li>
                  <span>Business: </span> <span>{userData.businessType}</span>
                </li>
              )}
              {userData.phoneNo && (
                <li>
                  <span>Phone: </span> <span>{userData.phoneNo}</span>
                </li>
              )}
              {userData.city && (
                <li>
                  <span>City: </span> <span>{userData.city}</span>
                </li>
              )}
              {userData.state && (
                <li>
                  <span>State: </span> <span>{userData.state}</span>
                </li>
              )}
            </ul>
          </>
        )}
      </Modal>
    </ModalWrapper>
  );
}
