import "./index.css";

import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";

import AuthHeader from "../../Components/AuthHeader";
import { login, loginCleanup } from "../../Store/actions/login";
import { clearAuth } from "../../Store/actions/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";

const AdminLogin = () => {
  const [error, setError] = useState(null);
  const [activate, setActivate] = useState(false);
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((s) => s.login);
  const history = useHistory();

  const onFinish = (values) => {
    if (error) setError(null);
    dispatch(login({ ...values, role: "admin" }));
  };

  useEffect(() => {
    if (loginState?.isSuccessful) {
      history.push("/account");
      dispatch(loginCleanup());
    } else if (loginState?.error) {
      if (loginState.error === "please activate your account") {
        setActivate(true);
        setTimeout(() => {
          return history.push("/");
        }, 5000);
      }
      setError(loginState.error);
      dispatch(loginCleanup());
      dispatch(clearAuth());
    }
  }, [loginState?.isSuccessful, loginState?.error, setError, setActivate]);

  return (
    <div className="login">
      <AuthHeader />
      <div className="container">
        <div className="form-div">
          {activate && (
            <>
              <p>Please check your mailbox to activate you Accout</p>
            </>
          )}
          {!activate && (
            <>
              <h3 style={{ textAlign: "center" }}>Admin Login</h3>
              <p style={{ textAlign: "center" }}>
                Manage Save n Win applications
              </p>
              {error ? (
                <Alert message={error} type="error" showIcon closable />
              ) : null}
              <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="login"
                onFinish={onFinish}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "please input an email",
                    },
                    {
                      type: "email",
                      message: "Enter a valid email",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Link
                  style={{ marginLeft: "60%", marginTop: "1rem" }}
                  to="/password-reset"
                  className="forgot"
                >
                  forgot password?
                </Link>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-btn"
                    loading={loginState?.isLoading}
                    style={{ marginTop: "3rem" }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
