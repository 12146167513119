/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./index.css";
import {
  Input,
  Row,
  Col,
  Modal,
  Checkbox,
  message,
  Pagination,
  Popover,
  Tag,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  createProgramCleanup,
  createProgram,
} from "../../../../Store/actions/bussiness/create-program";
import { Link } from "react-router-dom";

import {
  getProductList,
  getProductCleanup,
} from "../../../../Store/actions/bussiness/get-product";
import { Button } from "../../../../Components/ui/Button";
import { formatCurrency } from "../../../../Utils";

const CreateProgram = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const createProgramState = useSelector((s) => s.createProgram);
  const getProductState = useSelector((s) => s.getProduct);

  const [products, setProducts] = useState([]);
  const [programTitle, setProgramTitle] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [programStock, setProgramStock] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [productsArray, setProductsArray] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [allowCreateProgram, setAllowCreateProgram] = useState(true);
  const [completeModal, setCompleteModal] = useState(false);
  const fileInputRef = useRef(null);
  const [programImage, setProgramImage] = useState(null);

  const handleCompleteModalClose = () => {
    setCompleteModal(false);
    window.scrollTo(0, 0);
  };

  const calcNum = (arg) => {
    const isStock = selectedItems.find((item) => item._id === arg._id);

    if (isStock) {
      return isStock.in_stock;
    } else {
      return 0;
    }
  };

  const handleExpiryDate = (e) => {
    setExpiryDate(e.target.value);
  };

  const increaseStock = (arg) => {
    setSelectedItems((prev) => {
      const isItem = prev.find((item) => item._id === arg._id);

      if (isItem) {
        return prev.map((item) =>
          item._id === arg._id
            ? {
                ...item,
                in_stock: item.in_stock + 1,
              }
            : item
        );
      }
      return [...prev, { ...arg, in_stock: 1 }];
    });
  };

  const decreaseStock = (arg) => {
    setSelectedItems((prev) =>
      prev.reduce((ack, item) => {
        if (item._id === arg) {
          if (item.in_stock === 1) return ack;
          return [...ack, { ...item, in_stock: item.in_stock - 1 }];
        } else {
          return [...ack, item];
        }
      }, [])
    );
  };

  const sumPrice = () => {
    setTotalPrice(
      selectedItems.reduce(
        (ack, item) => ack + item.in_stock * item.sellingPrice,
        0
      )
    );

    setTotalDiscount(
      selectedItems.reduce(
        (ack, item) => ack + item.in_stock * item.discount,
        0
      )
    );
  };

  // put id and in stock in an array and prevent from being added twice

  const changePage = (page) => {
    dispatch(getProductList({ page: page }));
  };

  const onSearch = (value) => dispatch(getProductList({ title: value }));

  const onSubmit = () => {
    const date = new Date(expiryDate);
    const formData = new FormData();

    formData.append("image", programImage);
    formData.append("products", JSON.stringify(productsArray));
    formData.append("stock", parseInt(programStock));
    formData.append("totalPrice", totalPrice);
    formData.append("totalDiscount", totalDiscount);
    formData.append("title", programTitle);
    formData.append("expiresAt", date.toISOString());

    dispatch(createProgram(formData));
    // remove attached program image
    setProgramImage(null);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setProgramImage(file);
  };

  const handleButtonClick = () => {
    // Trigger the file input when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();

      // TODO: image loader button is seizing
    }
  };

  const handleResetProgramImage = () => {
    setProgramImage(null);
  };

  // // run this when a product is added
  useEffect(() => {
    sumPrice();
    const newProdArray = selectedItems.map((s) => ({
      item: s._id,
      stock: s.in_stock,
    }));
    setProductsArray(newProdArray);
  }, [selectedItems]);

  useEffect(() => {
    dispatch(getProductList({ limit: 20 }));
  }, []);

  // GET PRODUCTS FROM BACKEND TO DISPLAY
  useEffect(() => {
    if (getProductState.isSuccessful) {
      setProducts(getProductState.data.products);
      dispatch(getProductCleanup());
    } else if (getProductState.error) {
      setProducts(null);
      dispatch(getProductCleanup());
    }
  }, [getProductState]);

  useEffect(() => {
    if (createProgramState.isSuccessful) {
      message.success("Program created successfully");
      handleCancel();
      // setProductsArray([]);
      setSelectedItems([]);
      setProgramTitle("");
      setProgramStock("");
      setCompleteModal(true);
      dispatch(createProgramCleanup());
    } else if (createProgramState.error) {
      message.error(createProgramState.error);
      dispatch(createProgramCleanup());
    }
  }, [createProgramState]);

  const onChange = () => {
    setAllowCreateProgram(!allowCreateProgram);
  };

  const showModal = () => {
    if (!expiryDate) {
      message.error("Expiry date is required");
    } else if (!programImage) {
      message.error("Attach an image to the program");
    } else if (!programTitle) {
      message.error("Program must have a title");
    } else if (totalDiscount < 350) {
      message.error("Total discount should be upto $350");
    }

    if (expiryDate && programImage && programTitle && totalDiscount >= 350) {
      setIsModalVisible(true);
    }
  };

  const showTermsModal = () => {
    setIsTermsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTermsOk = () => {
    setIsTermsModalVisible(false);
  };

  const handleTermsCancel = () => {
    setIsTermsModalVisible(false);
  };

  const content = (
    <div className="help-content">
      <p>
        <b>Step 1:</b> Name your new VIP Program{" "}
      </p>
      <p>
        <b>Step 2:</b> Input the number of customers you want to attract with
        your new VIP Program
      </p>
      <p>
        <b>Step 3:</b> Select Units (from your Items List) to add to your new
        VIP Program to reach the minimum $350 discount threshold to publish your
        new VIP Program.
      </p>
      <p>
        <b>
          Please note: you can add multiple units of the same item as part of
          your new VIP Program offering
        </b>
      </p>
    </div>
  );

  return (
    <div className="create-program-container">
      <div className="container-inner">
        <h6 style={{ textAlign: "center" }}>Create VIP Program </h6>
        <hr />

        <div className="info-section d-flex justify-content-between align-items-center">
          <div className="search-container">
            <Search
              className="search-input"
              placeholder="Search by title"
              onSearch={onSearch}
              enterButton
            />
          </div>
          <div className="help-container">
            <Popover
              placement="bottomRight"
              title={
                <div className="py-3">
                  Take these steps to complete this form
                </div>
              }
              className="help-popover"
              content={content}
              trigger="click"
            >
              Help <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
            </Popover>
          </div>
        </div>
        <Row gutter={30} className="mb-3">
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <span className="help-item d-flex">
              <InfoCircleOutlined className="mt-1" />
              <span className="ms-1">
                <b>Step 1:</b> Name your new VIP Program{" "}
              </span>
            </span>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <span className="help-item d-flex">
              <InfoCircleOutlined className="mt-1" />
              <span className="ms-1">
                <b>Step 2:</b> Program image
              </span>
            </span>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <span className="help-item d-flex">
              <InfoCircleOutlined className="mt-1" />
              <span className="ms-1">
                <b>Step 3:</b> Input the number of customers you want to attract
                with your new VIP Program
              </span>
            </span>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <span className="help-item d-flex">
              <InfoCircleOutlined className="mt-1" />
              <span className="ms-1">
                <b>Step 4:</b> Select Units (from your Items List) to add to
                your new VIP Program to reach the minimum $350 discount
                threshold to publish your new VIP Program.
              </span>
            </span>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <input
              placeholder="Awesome title..."
              className="input_field"
              id="title"
              type="text"
              name="title"
              value={programTitle}
              onChange={(e) => setProgramTitle(e.target.value)}
            />
            <label htmlFor="title" className="prog_input_label">
              Add a Descriptive title for your program
            </label>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <input
              type="file"
              accept="image/*" // Specify that it accepts image files
              style={{ display: "none" }} // Hide the input element
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />

            {programImage ? (
              <Tag
                closable
                onClose={handleResetProgramImage}
                style={{
                  marginBlock: "5px",
                  padding: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {programImage?.name}
              </Tag>
            ) : (
              <Button
                onClick={handleButtonClick}
                className="btn btn-primary input_field"
              >
                Attach Image
              </Button>
            )}

            <label htmlFor="stock" className="prog_input_label">
              Attach the program image.
            </label>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <input
              placeholder="100"
              id="stock"
              className="input_field stock"
              type="number"
              name="inStock"
              value={programStock}
              onChange={(e) => setProgramStock(e.target.value)}
            />
            <label htmlFor="stock" className="prog_input_label">
              How Many Customers Do You Want To Attract With This VIP Program?
            </label>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
            <input
              className="input_field"
              type="date"
              name="expiresAt"
              id="expiryDate"
              min={new Date().toISOString().split("T")[0]}
              value={expiryDate}
              onChange={handleExpiryDate}
            />
            <label htmlFor="expiryDate" className="prog_input_label">
              Expiry date
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 24]} className="mt-4">
          <Col xs={{ span: 12 }} md={{ span: 6 }}>
            <div className="create_program_card selling_price">
              <p>Total Selling Price</p>

              <h3>${totalPrice}.00</h3>
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }}>
            <div className="create_program_card discount_price">
              <p>Total Discount</p>

              <h3>${totalDiscount}.00</h3>
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }}>
            <div className="create_program_card item_number">
              <p>No. of items</p>

              <h3>{selectedItems.length}</h3>
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }}>
            <div className="create_program_card create_btn">
              <button
                // disabled={totalDiscount < 350}

                onClick={showModal}
              >
                Create Program
              </button>
            </div>
          </Col>
        </Row>

        <div className="table-responsive mt-5 mb-5">
          <div className="mb-1">
            <span className="help-item d-flex">
              <InfoCircleOutlined className="mt-1" />
              <span className="ms-1">
                <b>
                  Please note: you can add multiple units of the same item as
                  part of your new VIP Program offering
                </b>
              </span>
            </span>
          </div>
          <table className="table borderless">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Item Title</th>
                <th scope="col">Category</th>
                <th scope="col">Original Price</th>
                <th scope="col">VIP Discount</th>
                <th scope="col">VIP Price</th>
                <th scope="col" className="text-center">
                  Add/Remove Items
                </th>
              </tr>
            </thead>

            <tbody>
              {products &&
                products.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.category}</td>
                    <td>{formatCurrency(item.price)}</td>
                    <td>{formatCurrency(item.discount)}</td>
                    <td>{formatCurrency(item.sellingPrice)}</td>

                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="calc_button"
                        onClick={() => decreaseStock(item._id)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      {calcNum(item)}
                      <button
                        className="calc_button"
                        onClick={() => increaseStock(item)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {products && (
          <div className="d-flex justify-content-end pb-5 pt-4">
            <Pagination
              onChange={changePage}
              total={products.totalProducts}
              hideOnSinglePage={true}
            />
          </div>
        )}
      </div>

      <Modal
        title="Create New Program"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="text-center">
          <Checkbox onChange={onChange}></Checkbox>{" "}
          <span>
            Agree to{" "}
            <span className="terms-btn" onClick={showTermsModal}>
              terms and conditions
            </span>
          </span>
          <br />
          <div className="d-flex justify-content-evenly mt-5">
            <button
              onClick={handleCancel}
              className="btn-danger btn-sm px-4 py-2"
            >
              CANCEL
            </button>
            <button
              disabled={allowCreateProgram}
              onClick={onSubmit}
              className="btn btn-success btn-sm px-4 py-2"
            >
              {createProgramState.isLoading ? <LoadingOutlined /> : "PROCEED"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Terms of use"
        open={isTermsModalVisible}
        onOk={handleTermsOk}
        onCancel={handleTermsCancel}
        footer={null}
      >
        <p>
          Terms and Conditions General Site Usage Last Revised: December 16,
          2013 Welcome to www.lorem-ipsum.info. This site is provided as a
          service to our visitors and may be used for informational purposes
          only. Because the Terms and Conditions contain legal obligations,
          please read them carefully. 1. YOUR AGREEMENT By using this Site, you
          agree to be bound by, and to comply with, these Terms and Conditions.
          If you do not agree to these Terms and Conditions, please do not use
          this site. PLEASE NOTE: We reserve the right, at our sole discretion,
          to change, modify or otherwise alter these Terms and Conditions at any
          time.
        </p>
      </Modal>
      <Modal
        open={completeModal}
        footer={
          <div>
            <div className="pb-4 text-start">
              Congratulation on creating your VIP program!
            </div>
            <div className="d-flex complete-modal-content">
              <button
                className="btn btn-primary"
                onClick={handleCompleteModalClose}
              >
                Create another VIP program
              </button>
              <Link className="btn btn-secondary" to="/account/programs">
                View your VIP programs list
              </Link>
            </div>
          </div>
        }
        onCancel={handleCompleteModalClose}
      ></Modal>
    </div>
  );
};

export default CreateProgram;
