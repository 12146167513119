import { useEffect } from "react";
import { getCart, selectGetCart } from "../Store/actions/getCart";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useIsLoggedIn } from "./auth";

/**
 * Get all the programs in the cart
 */
export function useGetCartItems() {
  const dispatch = useAppDispatch();
  const { isCustomer } = useIsLoggedIn();
  const { data, isLoading, error } = useAppSelector(selectGetCart);

  useEffect(() => {
    if (isCustomer){
      dispatch(getCart());
    } 
  }, [isCustomer]);

  return [data, { isLoading, error }];
}
