/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../Components/customButton/button";
import { Row, Col, message, Tooltip, Modal } from "antd";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "./index.css";
import {
  createProductCleanup,
  createProduct,
} from "../../../../Store/actions/bussiness/create-product";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { Link } from "react-router-dom";

// Form Error Label
const ErrorLabel = ({ message }: { message: any }) =>
  message?.trim() ? (
    <small className="mt-1 create-item-error">{message}</small>
  ) : null;

const RequiredIcon = () => <span className="text-red">*</span>;

function CreateBusinessProduct() {
  const dispatch = useAppDispatch();
  const createProductState = useAppSelector((s) => s.createProduct);
  const [completeModal, setCompleteModal] = useState(false);

  const handleCompleteModalClose = () => {
    setCompleteModal(false);
    window.scrollTo(0, 0);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const validatePriceField = (e:ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    if((e.target.value.match(/\./g) || []).length > 1) {
      // If more than one decimal point, remove extra decimal points
      e.target.value = e.target.value.replace(/\.+/g, '');
    }
  }
  const tagRegex = new RegExp(/^[a-zA-Z,]+$/);

  const itemImage = watch("image");

  const onSubmit = (data) => {
    data.sellingPrice = Number(data.sellingPrice);
    data.discount = Number(data.discount);
    data.inStock = data.inStock || 0;
    data.price = data.sellingPrice;

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    dispatch(createProduct(formData));
  };

  const handleOnChangeTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    const isValidTag = tagRegex.test(
      text.replaceAll("#", "").replaceAll(" ", "")
    );

    if (!isValidTag) {
      setError("tags", {
        type: "pattern",
        message: "Invalid tag is entered",
      });
      const lastValidCharacter = e.target.value.length - 1;
      e.target.value = e.target.value.substring(0, lastValidCharacter);
    } else if (!text.trim()) {
      setError("tags", {
        type: "required",
        message: "Tags are required",
      });
    } else {
      setError("tags", {});
    }

    if (text.length > 0) {
      const tags = text.split(",");
      const hashedTags = tags.map((t) => {
        if (!t.trim() || t.trim()[0] == "#") return t.trim();
        return "#" + t.trim().replaceAll("#", "");
      });
      e.target.value = hashedTags.join(", ").trim();
    }
  };

  useEffect(() => {
    if (createProductState.isSuccessful) {
      message.success("item created successfully");
      setCompleteModal(true);
      reset();
      dispatch(createProductCleanup());
    } else if (createProductState.error) {
      message.error(createProductState.error);
      console.log(createProductState);
      dispatch(createProductCleanup());
    }
  }, [createProductState]);

  return (
    <div className="create-product-container px-2">
      <div>
        <div className="container-inner">
          <h6 style={{ textAlign: "center" }}>Create Item for VIP program</h6>

          <form
            className="col col-md-8  mx-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="productImageDiv">
              <label htmlFor="productImage">
                <Controller
                  name="image"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Image is required",
                    },
                  }}
                  render={({ field: { onChange } }) => (
                    <input
                      type="file"
                      id="productImage"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        e.currentTarget && e.currentTarget.files
                          ? onChange(e.currentTarget.files[0])
                          : null
                      }
                    />
                  )}
                />

                <span>SELECT IMAGE </span>
                <span style={{ color: "#000" }}>
                  Choose a quality image to impress
                  <br />
                  your customers.
                </span>

                <span style={{ color: "#000" }}>
                  {itemImage && itemImage.name}
                </span>
                <Button
                  color="#FF4C4F"
                  brColor="#FF4C4F"
                  br={9}
                  mt={20}
                  mb={0}
                  bg={""}
                  width={100}
                  height={35}
                  title="choose file"
                />
              </label>
            </div>
            <ErrorLabel message={errors?.image?.message} />

            <hr />

            <div>
              <h6 style={{ textAlign: "center" }}>Item Information</h6>

              <div className="create-item-input-container">
                <label>
                  Name <RequiredIcon />
                </label>
                <input
                  className="create-item-input"
                  {...register("title", {
                    required: {
                      value: true,
                      message: "Item name is required",
                    },
                  })}
                />
                <ErrorLabel message={errors?.title?.message} />
              </div>

              <Row gutter={15}>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label style={{ textOverflow: "ellipsis" }}>
                      Regular Selling Price ($) <RequiredIcon />
                    </label>
                    <input
                      placeholder="$"
                      className="create-item-input"
                      {...register("sellingPrice", {
                        required: {
                          value: true,
                          message: "Selling price is required",
                        },
                        onChange: validatePriceField,
                      })}
                    />
                    <ErrorLabel message={errors?.price?.message} />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label>
                      Discount Percentage ($) <RequiredIcon />
                    </label>
                    <input
                      placeholder="$"
                      className="create-item-input"
                      {...register("discount", {
                        required: {
                          value: true,
                          message: "Discount price is required",
                        },
                        onChange:validatePriceField
                      })}
                    />
                    <ErrorLabel message={errors?.discount?.message} />
                  </div>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label>
                      Product Type <RequiredIcon />
                    </label>
                    <select
                      className="create-item-input"
                      {...register("productType", {
                        required: {
                          value: true,
                          message: "Please select a product type",
                        },
                      })}
                    >
                      <option></option>
                      <option value="service">Service</option>
                      <option value="product">Products</option>
                    </select>
                    <ErrorLabel message={errors?.productType?.message} />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label>
                      Product Category <RequiredIcon />
                    </label>
                    <select
                      className="create-item-input"
                      {...register("category", {
                        required: {
                          value: true,
                          message: "Please select a category",
                        },
                      })}
                    >
                      <option></option>
                      <option value="used">Used Item</option>
                      <option value="new">New Item</option>
                      <option value="refurbished">Refurbished Item</option>
                      <option value="Direct Services">Direct Service</option>
                      <option value="Indirect Service">Indirect Service</option>
                    </select>
                    <ErrorLabel message={errors?.category?.message} />
                  </div>
                </Col>
              </Row>

              <div className="create-item-input-container">
                <label>
                  Address <RequiredIcon />
                </label>
                <input
                  className="create-item-input"
                  placeholder="Enter your business address"
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Please enter your business address",
                    },
                  })}
                />
                <ErrorLabel message={errors?.address?.message} />
              </div>
              <Row gutter={15}>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label className="d-flex justify-content-start align-items-center">
                      Tags <RequiredIcon />
                      <Tooltip
                        placement="right"
                        title={
                          "Tags are used to define your product & services. For ex. #Tires"
                        }
                        trigger={["hover", "click"]}
                      >
                        <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </label>
                    <input
                      placeholder="Separate tags with commas e.g '#tag, #tag'"
                      className="create-item-input"
                      {...register("tags", {
                        required: {
                          value: true,
                          message: "Tags are required",
                        },
                      })}
                      onChange={handleOnChangeTags}
                    />
                    <ErrorLabel message={errors?.tags?.message} />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="create-item-input-container">
                    <label className="d-flex justify-content-start align-items-center">
                      Brand <RequiredIcon />
                      <Tooltip
                        placement="right"
                        title={
                          "Type or Brand....Example, If tires, (Firestone, Goodyear, Cooper, All Season, Radial etc.)"
                        }
                        trigger={["hover", "click"]}
                      >
                        <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      placeholder="Cooper"
                      className="create-item-input"
                      {...register("brand", {
                        required: {
                          value: true,
                          message: "Brand is required",
                        },
                      })}
                    />
                    <ErrorLabel message={errors?.brand?.message} />
                  </div>
                </Col>
              </Row>

              <div className="create-item-input-container">
                <label>
                  Description <RequiredIcon />
                </label>

                <textarea
                  rows={5}
                  className="text-area"
                  {...register("description", {
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                  })}
                ></textarea>
                <ErrorLabel message={errors?.description?.message} />
              </div>
              <div className="create-item-input-container">
                <label>
                  Terms of Service <RequiredIcon />
                </label>

                <textarea
                  rows={5}
                  className="text-area"
                  {...register("terms", {
                    required: {
                      value: true,
                      message: "Terms of Service are required",
                    },
                  })}
                ></textarea>
                <ErrorLabel message={errors?.terms?.message} />
              </div>
            </div>

            <div className="btnwrap">
              <button type="submit" className="create-item-submit-btn">
                {createProductState.isLoading ? (
                  <LoadingOutlined style={{ fontSize: 23 }} />
                ) : (
                  "submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        open={completeModal}
        title="Item created successfully"
        footer={
          <div className="d-flex complete-modal-content">
            <button
              className="btn btn-primary"
              onClick={handleCompleteModalClose}
            >
              Add another item
            </button>
            <Link className="btn btn-secondary" to="/account/items">
              View your item list
            </Link>
          </div>
        }
        onCancel={handleCompleteModalClose}
      >
        You have created a new item successfully. <br />
        Name: {createProductState.data?.title}
        <br />
        price: ${createProductState.data?.price?.toFixed(2)}
        <br />
        Discount: {createProductState.data?.discount?.toFixed(1)}% off
        <br />
        Brand:{" "}
        <span className="text-primary text-capitalize">
          {createProductState.data?.brand}
        </span>
        <br />
        Address:{" "}
        <span className="text-italic">{createProductState.data?.address}</span>
      </Modal>
    </div>
  );
}

export default CreateBusinessProduct;
