/**
 * Application environment configuration settings
 */

export const config = {
  REACT_APP_WEB_URL:
    process.env?.REACT_APP_WEB_URL ?? "https://vip.savenwin.com",
  REACT_APP_SERVER_HOST:
    process.env?.REACT_APP_SERVER_HOST ??
    "https://backend-server-dot-vip-savenwin.oa.r.appspot.com",
  REACT_APP_PAYPAL_CLIENT_ID:
    process.env?.REACT_APP_PAYPAL_CLIENT_ID ??
    // remove this line and replace with your own client ID when deploying to the server
    "ASmVJkNKTkJbEwKVz9gDZ-l1YmsIWR7DDAArLiLSv9SSW5MwZtE536l6EINehElQj0puMyhh3sC8KZWr",
};
