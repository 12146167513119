//styles
import "./index.css";

//modules and packages
import React from "react";
import { Link } from "react-router-dom";

//media
import logo from "../../Assets/images/logo.png";
import { HomeOutlined } from "@ant-design/icons";

const AuthHeader = () => (
  <div className="auth-header">
    <div className="container">
      <div className="header">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div
        style={{ fontSize: "1.75rem", alignSelf: "flex-start" }}
        role="button"
      >
        <Link to="/" className="return">
          <HomeOutlined />
        </Link>
      </div>
    </div>
  </div>
);

export default AuthHeader;
