/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

//modules & packages
import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Route, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//media and pictures
import logo from "../../Assets/images/logo.png";

//Routes
import privateRoutes from "../../Routes/Private";

//side menus
import SideMenu from "../../Components/SideMenu";

//components
import PrivateTop from "../../Components/PrivateTop";

//Actions
import { getMe, getMeCleanup } from "../../Store/actions/getMe";

const ProtectedLayout = (props) => {
  const { Content, Sider } = Layout;
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((s) => s.auth);
  const getMeState = useSelector((s) => s.getMe);

  // get loggedin user information
  useEffect(() => {
    if (authState?.isLoggedIn) {
      dispatch(getMe());
    } else {
      history.push("/login");
    }
  }, [authState?.isLoggedIn]);

  // set user information if token is valid
  useEffect(() => {
    if (getMeState.isSuccessful) {
      dispatch(getMeCleanup());
    } else if (getMeState.error) {
      history.push("/login");
      dispatch(getMeCleanup());
    }
  }, [getMeState?.isSuccessful, getMeState.error]);

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      const path = props.match.url + route?.path;
      const isPrivate = route.layout === "/private";

      const isAuthorized =
        route.role === getMeState.data?.user?.role ||
        route.role === "all" ||
        (route.role === "notadmin" && getMeState.data?.user.role !== "admin");

      if (route.collapse) return getRoutes(route.views);

      if (isPrivate && isAuthorized)
        return (
          <Route
            exact={!!route.exact || route.path === "/"}
            path={path}
            key={key}
            component={route.component}
          />
        );
    });
  };

  return authState.isLoggedIn ? (
    <Layout className="protected-layout">
      <Sider>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <SideMenu role={getMeState.data?.user?.role} />
      </Sider>

      <Layout>
        <Content className="protected-content">
          <PrivateTop />
          {!!getMeState.data?.user?.role && getRoutes(privateRoutes)}
        </Content>
      </Layout>
    </Layout>
  ) : null;
};

export default ProtectedLayout;
