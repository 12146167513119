import { Link } from "react-router-dom";
import { EmptyCart } from ".";
import "./index.css";
import moment from "moment";
import { useAppSelector } from "../../../../hooks";
import { selectGetCart } from "../../../../Store/actions/getCart";
import { formatCurrency } from "../../../../Utils";
import constants from "../../../../config/constants";


export const CartItems = () => {
  const cart = useAppSelector(selectGetCart)?.data ?? [];

  return (
    <div className="cart-management-ui">
      <div className="cart-management-ui-header">
        <h3 className="text-center">Shopping Cart</h3>

        {cart?.length == 0 ? (
          <div className="d-flex align-items-center justify-content-center my-5">
            <EmptyCart />
          </div>
        ) : (
          <>
            <div className="cart__list mt-3">
              {cart?.map((item: any) => (
                <div
                  key={item?._id}
                  className="cart__list-item d-flex align-items-center gap-3 my-3"
                >
                  <div>
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={item?.avatar?.secure_url}
                      alt="product-image"
                      className="cart__list-item-image p-1"
                    />
                  </div>

                  <div className="cart__list-item-details">
                    <h5 className="fw-normal  mb-3">{item?.title}</h5>
                    <div className="my-2 d-flex align-items-center gap-2">
                      <span
                        className={`${
                          item?.stock > 0
                            ? "text-success border-success"
                            : "text-dark border-secondary"
                        } text-bold border border-1 p-1 rounded-pill`}
                      >
                        {item?.stock > 0 ? "In stock" : "Out of stock"}
                      </span>

                      <span className="text-white bg-warning text-center p-1 rounded-pill">
                        {(
                          ((item?.totalDiscount ?? 0) /
                            (item?.totalPrice ?? 1)) *
                          100
                        )?.toFixed(0)}
                        % OFF
                      </span>
                    </div>

                    <div className="my-2 d-flex flex-column align-items-start justify-content-between">
                      <span className="fw-bold">Avialable before:</span>{" "}
                      <span className="text-danger fw-bold fst-italic">
                        {moment(item?.expiresAt).format("DD MMM, YYYY hh:mm A")}
                        <br />
                        (UTC {moment(item?.expiresAt).format("Z")})
                      </span>
                    </div>

                    <br />
                    <div className="price mt-3 my-2 d-flex align-items-center justify-content-between">
                      <s className="original-price">
                        {formatCurrency(item?.totalPrice)}
                      </s>
                      <span className="selling-price">
                        {formatCurrency(item?.totalSellingPrice)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Link to="/account/cart">
              <button className="btn btn-outline-danger mb-3">View Cart</button>
            </Link>

            <div className="d-flex align-item-center justify-content-between px-2 mt-3">
              <span className="fw-bold">Total:</span>
              <b className="text-bold">
                {formatCurrency(
                  cart
                    ?.map((p) => p?.totalSellingPrice)
                    ?.reduce((acc, _) => acc + constants.PLATFORM_CHARGES, 0) ?? 0
                )}
              </b>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
