import AxiosCall from "../../../Utils/axios";
import { paramsFormat } from "../../../Utils/axios/paramsFormat";
import ErrorHandler from "../../../Utils/error-handler";

export const productListStart = () => ({
  type: "PRODUCT_LIST_START",
});

export const productListSuccess = (payload) => ({
  type: "PRODUCT_LIST_SUCCESS",
  payload,
});

export const productListFail = (payload) => ({
  type: "PRODUCT_LIST_FAIL",
  payload,
});

export const productListCleanup = () => ({
  type: "PRODUCT_LIST_CLEANUP",
});
export const selectProductList = (s) => s.productList;
export const productList = (payload) => async (dispatch) => {
  const page = payload?.page ?? 1;
  const limit = payload?.limit ?? 30;
  const city = payload?.city ?? "";
  const state = payload?.state ?? "";
  const title = payload?.title ?? "";
  const productType = payload?.productType ?? "";
  
  const query = {
    page,
    limit,
    city,
    state,
    title,
    productType,
  };

  const params = paramsFormat(query);
  try {
    dispatch(productListStart());
    const requestObj = {
      path: `products/search?${params}`,

      method: "GET",
    };

    const { data } = await AxiosCall(requestObj);
    dispatch(productListSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(productListFail(error));
  }
};
