import React, { useEffect } from "react";
import {
  CardBody,
  CardTitle,
  Container,
  Card,
  Col,
  Row,
  CardSubtitle,
  CardFooter,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import SearchForm from "../Home/Hero/SearchForm";
import { Link } from "react-router-dom";
import { getWishlist } from "../../Store/actions/wishlist";
import { useLocation } from "react-router-dom";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { message, Pagination } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { selectLoggedInUserRole } from "../../Store/actions/getMe";

const WishlistListing: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentRole = useAppSelector(selectLoggedInUserRole);
  const wishlist = useAppSelector((state) => state.wishlistReducer);

  const { isLoading, data, error } = wishlist;

  useEffect(() => {
    if (currentRole) {
      const query = new URLSearchParams(location.search).toString() || "";
      dispatch(getWishlist({ query }));
    }
  }, [dispatch, currentRole, location]);

  const changePage = (value: number) => {
    let search = new URLSearchParams(location.search);
    search.set("page", String(value));
    const query = search.toString();
    dispatch(getWishlist({ query }));
  };

  if (!currentRole) {
    return (
      <div className="product-listing">
        <Container>
          <section className="d-flex flex-column mb-5">
            <h4>LOOKS LIKE YOU NEED TO SIGNUP TO VIEW THIS PAGE</h4>
            <Link to={"/signup"}>Signup</Link>
          </section>
          <section>
            <h5>Already have an account?</h5>
            <Link to={"/login"}>Login here</Link>
          </section>
        </Container>
      </div>
    );
  }

  return (
    <div className="program-list">
      <Container>
        <div className="text-start">
          <SearchForm />
        </div>
      </Container>

      {!isLoading && error && (
        <Container className="mt-4 d-flex flex-column align-items-center">
          <h4>An error occured, Please try again.</h4>
        </Container>
      )}

      {isLoading && (
        <Container
          className="justify-content-center w-100 program-detail-top mt-1"
          style={{ minHeight: "40vh" }}
        >
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </Container>
      )}

      {data && !data.data.length && !isLoading && (
        <Container className="mt-4 d-flex flex-column align-items-center">
          <p className="fw-bold fs-6">No result found for your search!</p>
          <p>
            {" "}
            You can{" "}
            <Link to="/signup" className="text-secondary">
              {" "}
              sign up{" "}
            </Link>{" "}
            to become a business owner{" "}
          </p>
        </Container>
      )}

      {data && data.data.length && !isLoading && !error && (
        <Container>
          <div className="topper mt-4 mb-4">
            <h4>Wishlist Items</h4>
            <h3>Showing {data.total} Wishlist Items</h3>
            {currentRole === "business" && (
              <h6 className="w-75 mx-auto text-center">
                You can create a product with either the title or keywords in
                the wishlist item and publish it in a program to notify the
                customer that you have a product they want.{" "}
                <Link to={`/account/create-program`} className="text-primary">
                  Click here to create product
                </Link>
              </h6>
            )}
          </div>

          <Row xs="2" md="3" lg="4">
            {data.data.map((product) => (
              <Col className="col" key={product._id}>
                <Card>
                  <CardBody>
                    <CardTitle
                      tag="h5"
                      className="product-title d-flex align-items-center"
                    >
                      Title: {product.title}
                      <CopyToClipboard
                        text={product.title}
                        onCopy={() =>
                          message.success({
                            duration: 2,
                            content: "Copied!",
                          })
                        }
                        className="ms-2"
                      >
                        <CopyOutlined />
                      </CopyToClipboard>
                    </CardTitle>
                    <CardSubtitle>
                      Description: {product.description}
                    </CardSubtitle>
                    <CardSubtitle>City: {product.city}</CardSubtitle>
                    <CardSubtitle>State: {product.state}</CardSubtitle>
                    <CardSubtitle>Type: {product.type}</CardSubtitle>
                    {!!product.keywords.length ? (
                      <CardFooter className="d-flex align-tems-center">
                        Keywords: {product.keywords.join(", ")}
                        <CopyToClipboard
                          text={product.keywords.join(", ")}
                          onCopy={() =>
                            message.success({
                              duration: 2,
                              content: "Copied!",
                            })
                          }
                          className="ms-2 d-flex justify-content-center align-items-center"
                        >
                          <CopyOutlined />
                        </CopyToClipboard>
                      </CardFooter>
                    ) : (
                      <CardFooter>No keywords, use title instead</CardFooter>
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            onChange={changePage}
            total={data.total || 0}
            hideOnSinglePage={true}
            current={data.currentPage}
            pageSize={10}
            className="d-flex justify-content-end pb-5 pt-4"
          />
        </Container>
      )}
    </div>
  );
};

export default WishlistListing;
