/* eslint-disable array-callback-return */
//styles
import "./index.css";

//modules & packages
import { Layout } from "antd";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

//components
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import PublicRoutes from "../../Routes/Public";

const PublicLayout = () => {
  const hideSidebars = [
    "/login",
    "/signup",
    // "/products",
    "/password-reset",
    "/auth/admin-login",
  ];
  const { pathname: currentRoute } = useLocation();
  const { Content } = Layout;

  const showSidebar = () =>
    hideSidebars.find((route) => route === currentRoute);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        return (
          <Route exact={prop.path === "/"} path={prop.path} key={key}>
            <prop.component />
          </Route>
        );
      } else if (prop.path !== "/public") {
        return <Redirect to="/" />;
      }
    });
  };
  return (
    <Layout className={`public-layout ${showSidebar() ? "no-nav" : ""}`}>
      {!showSidebar() && <NavBar />}
      <Content>
        <Switch>{getRoutes(PublicRoutes)}</Switch>
      </Content>
      {!showSidebar() && <Footer />}
    </Layout>
  );
};

export default PublicLayout;
