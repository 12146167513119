import { Button, Typography } from "antd";
import { Link } from "react-router-dom";

//
import confetti from "../../Assets/images/confetti.png";

export const ProfileCreated = () => (
  <div>
    <img
      style={{
        display: "block",
        margin: "40px auto 10px",
      }}
      src={confetti}
      alt="well done"
    />
    <Typography is="p" style={{ textAlign: "center", paddingBlock: "20px" }}>
      Kindly check your mail to activate your account. Email might take up to 5
      minutes to deliver.
      <br />
      kindly check your mail to activate your account.
    </Typography>
    <Link to="/">
      <Button type="link" block style={{ marginBlock: "10px" }}>
        Go back home
      </Button>
    </Link>
  </div>
);
