import  secureLocalStorage  from  "react-secure-storage";

const token = secureLocalStorage.getItem("tokenUser");
const user = secureLocalStorage.getItem("authUser");

export const login = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const users = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const createProduct = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const createProgram = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const editProgram = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const programs = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const signup = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const subscription = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const productList = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const businessProductList = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const businessProgramList = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const product = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const activate = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const passReset = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};
export const createItem = {
  isCreating: false,
  isSuccessful: false,
  data: null,
  error: null,
};
export const forgotPass = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const signupProgress = {
  role: "customer",
  email: null,
  password: null,
  phoneNo: null,
  address: null,
  city: null,
  state: null,
  country: "United States",
  postalCode: null,
  stage: "account",
};

export const auth = {
  token: token || null,
  user: user || null,
  isLoggedIn: token ? true : false,
};

export const programDetails = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const userPayment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const scanCode = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const businessTransaction = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const redeemCode = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateProfile = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const changeEmail = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getMe = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const validateMail = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const confirmEmail = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const unredeemedCodes = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addToCart = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
  message: null
};

export const redeemedCodes = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addManyToCart = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const changePassword = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getCart = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getProductProgram = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteAccount = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addFlag = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteFlag = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const allNotifications = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const unreadNotifications = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const singleNotifications = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const clickedReferral = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export interface WishlistItemType {
  city: string;
  country: string;
  createdAt: string;
  deleted: boolean;
  description: string;
  fulfilled: boolean;
  keywords: string[];
  owner: string;
  state: string;
  title: string;
  type: string;
  updatedAt: string;
  _id: string;
}
export interface WishlistReducerType {
  isLoading: boolean;
  isSuccessful: boolean;
  data: {
    currentPage: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    next: null | number;
    prev: null | number;
    pageCount: number;
    pagingCounter: number;
    total: number;
    data: WishlistItemType[];
  } | null;
  error: string | null;
}

export const wishlist: WishlistReducerType = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};