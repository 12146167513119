/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-line react-hooks/exhaustive-deps
//styles
import "./index.css";

//packages and modules
import { useEffect, useState } from "react";
import { getMeCleanup } from "../../Store/actions/getMe";
import {
  LogoutOutlined,
  UserOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { LeftRow, NotifyWrapper } from "./styles";
import {
  unreadNotifications,
  unreadNotificationsCleanup,
} from "../../Store/actions/notifications/unreadNotifications";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { UserType } from "../../types";
import { Alert, Image, Popover, Select } from "antd";
import { Link, useHistory } from "react-router-dom";
import { clearAuth } from "../../Store/actions/auth";
import { fetchUserRoles, switchRoleApi } from "../../api/users/roles";
import ErrorHandler from "../../Utils/error-handler";
import { switchUserRoleSuccess } from "../../Store/actions/login";
import secureLocalStorage from "react-secure-storage";

const PrivateTop = () => {
  //
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);
  const [alertMsg, setAlertMsg] = useState<{
    text: string;
    type: "success" | "error" | "info";
  } | null>();

  const dispatch = useAppDispatch();
  const getMeState = useAppSelector((state) => state.getMe);
  const history = useHistory();
  const [showNotificationModal, setNotificationModal] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState([]);
  const [currentRole, setCurrentRole] = useState("");
  const [dropdownRoles, setDropdownRoles] = useState<
    { value: string; label: string }[]
  >([]);
  const [user, setUser] = useState<UserType>();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const unreadNotificationsState = useAppSelector(
    (state) => state.UnreadNotifications
  );

  const updateRole = (role: string) => {
    // update user role
    setIsUpdatingRole(true);
    switchRoleApi(role)
      .then((res) => {
        if (res.status === 200) {
          const roles = dropdownRoles.map((role: any) => role?.value);

          setAlertMsg({
            text: res?.message || "Role switched successfully",
            type: "success",
          });
          setCurrentRole(role);
          dispatch(switchUserRoleSuccess({ ...res.data, roles }));
          history.push("/");
        }
      })
      .catch((error) => {
        const err = ErrorHandler(error);
        setAlertMsg({
          text: err ?? "Failed to update user's role",
          type: "error",
        });
      })
      .finally(() => {
        setIsUpdatingRole(false);
        setTimeout(() => {
          setAlertMsg(null);
        }, 5000);
      });
  };

  const logout = () => {
    secureLocalStorage.clear();
    dispatch(clearAuth());
    dispatch(getMeCleanup());
  };

  useEffect(() => {
    if (getMeState.isSuccessful) {
      setUser(getMeState.data.user);
      dispatch(getMeCleanup());
    }
  }, [getMeState]);

  useEffect(() => {
    if (typeof window != "undefined") {
      const sitWrapper = document.querySelector(".protected-layout");
      const toggleMenu = document.querySelector(".private-top .toggle-menu");
      const slideMenu = document.querySelector(
        ".protected-layout .ant-layout-sider"
      );

      if (!document.querySelector(".menu-overlay")) {
        const overLay = document.createElement("span");
        sitWrapper?.appendChild(overLay);
        overLay.classList.add("menu-overlay");
        overLay.style.display = "none";
        overLay.style.backgroundColor = "#0006";
        overLay.style.width = "100%";
        overLay.style.height = "100vh";
        overLay.style.zIndex = "10";
        overLay.style.cursor = "pointer";
        overLay.style.position = "fixed";
        overLay.style.top = "0px";
        overLay.style.left = "0px";
        overLay.addEventListener("click", (e) => {
          (slideMenu as HTMLElement).style.display = "none";
          overLay.style.display = "none";
        });
      }

      const overLay = document.querySelector(".protected-layout .menu-overlay");

      const onToggleClick = (e) => {
        (overLay as HTMLElement).style.display = "block";
        (slideMenu as HTMLElement).style.display = "block";
      };

      toggleMenu?.addEventListener("click", onToggleClick);

      window.addEventListener("resize", function () {
        if (document.body.clientWidth > 768) {
          (slideMenu as HTMLElement).style.display = "block";
          (overLay as HTMLElement).style.display = "none";
        } else {
          (slideMenu as HTMLElement).style.display = "none";
        }
      });

      return () => {
        toggleMenu?.removeEventListener("click", onToggleClick);
      };
    }
  }, []);

  useEffect(() => {
    if (unreadNotificationsState.isSuccessful) {
      // console.log(unreadNotificationsState)
      setUnreadNotification(unreadNotificationsState.data.notifications);
      setUnreadNotificationCount(unreadNotificationsState.data.totalHits);

      dispatch(unreadNotificationsCleanup());
    } else if (unreadNotificationsState.error) {
      dispatch(unreadNotificationsCleanup());
    }
  }, [unreadNotificationsState]);

  useEffect(() => {
    dispatch(unreadNotifications());
  }, []);

  useEffect(() => {
    fetchUserRoles()
      .then((res) => {
        if (res.status === 200) {
          setCurrentRole(res?.data?.currentRole);
          const _roles = res?.data?.roles?.map((role: string) => ({
            value: role,
            label: role.substring(0, 1).toUpperCase() + role.substring(1),
          }));
          setDropdownRoles(_roles || []);
        }
      })
      .catch((error) => {
        const err = ErrorHandler(error);
      });
  }, [fetchUserRoles]);

  const AvatarContent = (
    <>
      <Select
        defaultValue={currentRole}
        loading={isUpdatingRole}
        style={{ width: 200, marginBottom: "20px" }}
        onChange={(role) => updateRole(role)}
        options={dropdownRoles}
      />

      {!dropdownRoles?.find((role) => role.value === "business") ? (
        <Link href="nav-links" to="/account/roles">
          <div
            className="d-flex align-items-center my-3"
            style={{ color: "#2F855A", fontWeight: 600, gap: "10px" }}
          >
            <BranchesOutlined />
            Create business role
          </div>
        </Link>
      ) : null}

      <Link href="nav-links" to="/" id="logout-btn" onClick={logout}>
        <div
          className="d-flex align-items-center"
          style={{ fontWeight: 600, gap: "10px" }}
        >
          <LogoutOutlined />
          Logout
        </div>
      </Link>
    </>
  );

  return (
    <div className="private-top">
      {!!alertMsg ? (
        <div
          style={{
            maxWidth: 500,
            textAlign: "center",
            marginInline: "auto",
            marginBlock: "10px",
          }}
        >
          <Alert
            banner
            closable
            message={alertMsg?.text}
            type={alertMsg?.type}
          />
        </div>
      ) : null}
      <div className="toggle-menu">
        <span></span>
      </div>

      <LeftRow>
        <NotifyWrapper>
          <FontAwesomeIcon
            style={{
              fontSize: "25px",
            }}
            cursor="pointer"
            icon={faBell as IconProp}
            color="#111111"
            onClick={() => setNotificationModal(!showNotificationModal)}
          />
          {unreadNotification.length > 0 && (
            <span>{unreadNotificationCount}</span>
          )}
          {showNotificationModal && (
            <ul className="motes">
              {unreadNotification.map((data: any, key) => (
                <li key={key} onClick={() => setNotificationModal(false)}>
                  {data.title}
                </li>
              ))}
            </ul>
          )}
        </NotifyWrapper>
        <Popover
          placement="bottomRight"
          title={"Account"}
          content={AvatarContent}
          trigger="click"
        >
          <div className="user-avatar">
            {user && user.avatar && user.avatar.secure_url ? (
              <Image
                crossOrigin="anonymous"
                src={user.avatar ? user.avatar.secure_url : ""}
                fallback="/avatar.png"
                preview={false}
              />
            ) : (
              <UserOutlined style={{ fontSize: "22px" }} />
            )}
          </div>
        </Popover>
      </LeftRow>
    </div>
  );
};

export default PrivateTop;
