import { QRCode } from "antd";
import { Button } from "../../../../Components/ui/Button";
import { convertComponentToImage } from "../../../../Utils/exportAsImage";
import { ProgramQRCode } from "../ProgramQRCode";
import constants from "../../../../config/constants";
import { config } from "../../../../config";

function ProgramQRCodeItem(props: any) {
  return (
    <div
      className="bg-white"
      style={{
        borderColor: "whitesmoke",
        borderRadius: "20px",
        border: "1px solid whitesmoke",
        padding: "10px",
      }}
    >
      <div id={props?.qrcode._id}>
        <ProgramQRCode
          qrCode={
            <QRCode
              bordered={false}
              style={{ marginBottom: 16 }}
              value={`${config.REACT_APP_WEB_URL}/programs/${props?.qrcode._id}`}
            />
          }
          platformCharge={constants.PLATFORM_CHARGES}
          itemId={props?.qrcode.productID._id}
          title={props?.qrcode.productID.title}
          discountPrice={props?.qrcode.productID?.discount}
          description={props?.qrcode?.productID?.description?.substring(0, 30)}
          sellingPrice={props?.qrcode.productID?.sellingPrice}
          totalPrice={props?.qrcode.productID?.price}
        />
      </div>
      <Button
        className="btn btn-outline-secondary w-100 bg-danger border-0 text-white"
        onClick={() =>
          convertComponentToImage(
            document.getElementById(props?.qrcode._id)!,
            `${props?.qrcode.productID.title}_${props?.qrcode.productID._id}`
          )
        }
      >
        Download
      </Button>
    </div>
  );
}

export default ProgramQRCodeItem;
