import "./index.css";

import React, { useRef, Fragment } from "react";
import { Dropdown, Popover } from "antd";
import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import Loader from "../Loader";

const dropdownMenu = (loading, moreOptions, actions, user) => {
  if (loading) {
    return [
      {
        key: "1",
        label: <Loader />,
      },
    ];
  }

  if (user?.suspended?.value) {
    return moreOptions.map((option, index) =>
      option.title === "lift suspention"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : null
    );
  }

  if (user?.deleted?.value) {
    return moreOptions.map((option, index) =>
      option.title === "restore"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : undefined
    );
  }

  return moreOptions.map((option, index) => {
    return {
      key: index,
      label: (
        <Fragment key={index}>
          {option.title !== "lift suspention" ? (
            option.title === "restore" ? null : (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
                key={index}
              >
                {option.title}
              </li>
            )
          ) : undefined}
        </Fragment>
      ),
    };
  });
};

const List = ({
  data,
  error,
  actions,
  isLoading,
  visibility,
  setVisibility,
}) => {
  const { showUserDetails, moreOptions } = actions;

  const itemRefs = useRef([]);
  itemRefs.current = [];
  const viewProfile = async (e, index, userId) => {
    console.log("selected element: ", e.target.parentNode);
    console.log("index element: ", itemRefs.current[index].childNodes[0]);
    if (visibility != null) return;
    if (e.target.parentNode !== itemRefs.current[index].childNodes[0]) {
      showUserDetails(userId);
    }
  };

  const addItiemRefs = (e) => {
    if (e && !itemRefs.current.includes(e)) {
      itemRefs.current.push(e);
    }
  };

  return (
    <div className="table-wrapper">
      {!data ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <div className="table">
          <li className="table-head" id="top-level">
            <span className="user-id">User ID</span>
            <span className="user-email">User Email</span>
            <span className="user-type">User type</span>
            <span className="verification">Suspended</span>
            <span className="verification">User Verified</span>
            <span className="actions">Actions</span>
          </li>
          {error && <li>{error}</li>}
          <ul className="table-body">
            {data &&
              data.users.map((user, key) => (
                <li
                  className="all-users"
                  key={key}
                  onClick={(e) => viewProfile(e, key, user._id)}
                >
                  <span className="items user-id">{user._id}</span>
                  <span
                    className="items user-email"
                    onClick={() => showUserDetails(user._id)}
                  >
                    {user?.email?.slice(0, 20) +
                      `${user.email.length > 20 ? "..." : ""}`}
                  </span>
                  <span className="items user-type">{user.role}</span>
                  <span className="items verification">
                    <b
                      style={{
                        backgroundColor: user.suspended.value
                          ? "#8CF6CA"
                          : "#F7B28C",
                      }}
                    >
                      {user.suspended.value ? "yes" : "no"}
                    </b>
                  </span>
                  <span className="items verification">
                    <b
                      style={{
                        backgroundColor:
                          user.verifiedBusiness || user.verifiedAffiliate
                            ? "#8CF6CA"
                            : "#F7B28C",
                      }}
                    >
                      {user.verifiedBusiness || user.verifiedAffiliate
                        ? "yes"
                        : "no"}
                    </b>
                  </span>
                  <span className="items actions" ref={addItiemRefs}>
                    <Dropdown
                      menu={{
                        items: dropdownMenu(
                          isLoading === user._id,
                          moreOptions,
                          user
                        ),
                      }}
                      trigger={["click"]}
                      open={visibility === user._id ? true : false}
                      onOpenChange={() => {
                        if (visibility === null) {
                          setVisibility(user._id);
                        } else {
                          setVisibility(null);
                        }
                      }}
                    >
                      <MoreOutlined />
                    </Dropdown>
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default List;
