import React, { Fragment, forwardRef } from "react";
import { Button, Dropdown, Table, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { MoreOutlined } from "@ant-design/icons";


interface DataType {
  key: React.Key;
  id: number;
  email: string;
  type: string;
  suspended: boolean;
  confirmed: boolean;
}

const suspendTagColor = (isSuspended: boolean) =>
  isSuspended ? "#8CF6CA" : "#F7B28C";
const suspendTagText = (isSuspended: boolean) => (isSuspended ? "YES" : "NO");
const verifiedTagColor = (isSuspended: boolean) =>
  isSuspended ? "#8CF6CA" : "#F7B28C";
const verifiedTagText = (isSuspended: boolean) => (isSuspended ? "YES" : "NO");

const dropdownMenu = (
  moreOptions,
  id,
  isSuspended,
  isDeleted,
  isVerrifiedBusiness
) => {
  if (isSuspended) {
    return moreOptions.map((option, index) =>
      option.title === "lift suspention"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : null
    );
  }
  if (isDeleted) {
    return moreOptions.map((option, index) =>
      option.title === "restore"
        ? {
            label: (
              <li
                className="action-items"
                onClick={() => option.onClick(id)}
                key={index}
              >
                {option.title}
              </li>
            ),
          }
        : null
    );
  }

  return moreOptions.map((option, index) => {
    return {
      key: index,
      label: (
        <Fragment key={index}>
          {option.title !== "Verify" ? (
            <li className="action-items" onClick={() => option.onClick(id)}>
              {option.title}
            </li>
          ) : (
            !isVerrifiedBusiness && (
              <li className="action-items" onClick={() => option.onClick(id)}>
                {option.title}
              </li>
            )
          )}
        </Fragment>
      ),
    };
  });
};

const ActionCell = forwardRef((props: any, ref: any) => {
  const {
    id,
    isDeleted,
    isSuspended,
    isVerrifiedBusiness,
    key,
    moreOptions,
    visibility,
    setVisibility,
  } = props;

  return (
    <span className="items actions" ref={ref} key={key}>
      <Dropdown
        menu={{
          items: dropdownMenu(
            moreOptions,
            id,
            isSuspended,
            isDeleted,
            isVerrifiedBusiness
          ),
        }}
        trigger={["click"]}
        open={visibility === id ? true : false}
        onOpenChange={() => {
          if (visibility === null) {
            setVisibility(id);
          } else {
            setVisibility(null);
          }
        }}
      >
        <MoreOutlined />
      </Dropdown>
    </span>
  );
});

const columns: ColumnsType<DataType> = [
  {
    title: "User ID",
    // dataIndex: "id",
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value: any, record: Record<string, any>) =>
      record.name.startsWith(value),
    width: "10%",
    render: ({ id, viewProfile, key }) => (
      <Button
        type="link"
        style={{ textDecoration: "underline" }}
        onClick={(e) => viewProfile(e, key, id)}
      >
        {id?.slice(0, 7) + "..."}
      </Button>
    ),
  },
  {
    title: "User Email",
    dataIndex: "email",
    filterSearch: true,
    filtered: true,
    onFilter: (value: any, record: Record<string, any>) =>
      record.name.startsWith(value),
    width: "20%",
  },
  {
    title: "User type",
    align: "center",
    dataIndex: "type",
    onFilter: (value: any, record: Record<string, any>) =>
      record.name?.toLowerCase() === value?.toLowerCase(),
    width: "20%",
    render: (type) => type?.toUpperCase(),
  },
  {
    title: "Bussiness Name",
    align: "center",
    dataIndex: "businessName",
    onFilter: (value: any, record: Record<string, any>) =>
      record.name?.toLowerCase() === value?.toLowerCase(),
    width: "20%",
    render: (businessName) => businessName ?? "None",
  },
  {
    title: "Suspended",
    dataIndex: "suspended",
    render: (isSuspended) => (
      <Tag
        className="pad-2"
        color={suspendTagColor(isSuspended)}
        style={{ marginInline: "auto" }}
      >
        {suspendTagText(isSuspended)}
      </Tag>
    ),
    align: "center",
    filters: [
      {
        text: "Is Suspended",
        value: true,
      },
      {
        text: "Not Suspended",
        value: false,
      },
    ],
    onFilter: (value: any, record: Record<string, any>) =>
      record.suspended === value,
    width: "10%",
  },
  {
    title: "User Verified",
    dataIndex: "confirmed",
    align: "center",
    filters: [
      {
        text: "Is Verified",
        value: true,
      },
      {
        text: "Unverified",
        value: false,
      },
    ],
    render: (isVerified) => (
      <Tag className="pad-2" color={verifiedTagColor(isVerified)}>
        {verifiedTagText(isVerified)}
      </Tag>
    ),
    onFilter: (value: any, record: Record<string, any>) =>
      record.confirmed === value,
    width: "40%",
  },
  {
    title: "Actions",
    align: "center",
    width: "10%",
    render: (payload) => <ActionCell {...payload} />,
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

export const UserTable = ({ data }) => (
  <Table
    rowClassName="table-row"
    rootClassName="table"
    columns={columns}
    dataSource={data}
    onChange={onChange}
    pagination={{
      defaultPageSize: 30,
      onChange: (page) => {
        // TODO: pagination
      },
    }}
  />
);
