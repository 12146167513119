/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Paginate from "../../../Components/Paginate";
import { useDispatch, useSelector } from "react-redux";
import { users, usersCleanup } from "../../../Store/actions/users";
import AxiosCall from "../../../Utils/axios";
import { toast } from "react-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faShoppingCart,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../Components/Loader";
import { message, Dropdown as ADropdown } from "antd";
import { MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  getAccountConfirmationBgColor,
  getAccountSuspendBgColor,
} from "./utils";
import UserProfileModal from "./UserProfileModal";

const Dashboard = () => {
  const key = "updatable";
  const usersListState = useSelector((s) => s.users);
  const siteStats = useSelector((state) => state.getMe);
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState(null);
  const [navigationData, setNavigationData] = useState(null);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(0);
  const [userProfileModal, setUserProfileModal] = useState(false);
  const userProfileRef = useRef(null);
  const [visibility, setVisibility] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const actions = {
    moreOptions: [
      {
        title: "View",
        onClick: async (_id) => {
          showUserDetails(_id);
        },
      },

      {
        title: "Verify",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "GET",
              path: "admin/verify/" + _id,
            });
            setVisibility(null);

            if (result.status == 200) {
              dispatch(users({}));
              const rawList = usersList;
              const selectedUser = usersList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].verifiedBusiness = "yes";
              setUsersList({ ...usersList, rawList });
              message.success({ content: "User Verified", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }

            setIsSending(0);
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Suspend 2 weeks",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
              data: {
                month: 0,
                week: 2,
              },
            });
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              dispatch(users({}));
              const rawList = usersList;
              const selectedUser = usersList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = true;
              setUsersList({ ...usersList, rawList });
              message.success({ content: "User suspended", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Suspend 1 month",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
              data: {
                month: 1,
                week: 0,
              },
            });
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              dispatch(users({}));
              const rawList = usersList;
              dispatch(users({}));
              const selectedUser = usersList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = true;
              setUsersList({ ...usersList, rawList });
              message.success({ content: "User suspended", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Lift suspension",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "POST",
              path: "admin/suspend/" + _id,
            });
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              dispatch(users({}));
              const rawList = usersList;
              const selectedUser = usersList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users[selectedUser].suspended.value = false;
              setUsersList({ ...usersList, rawList });
              message.success({
                content: "Suspension lifted",
                key,
                duration: 2,
              });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            toast("something went wrong");
          }
        },
      },
      {
        title: "Delete",
        onClick: async (_id) => {
          try {
            setIsSending(_id);
            const result = await AxiosCall({
              method: "DELETE",
              path: "users/" + _id,
            });
            setIsSending(0);
            setVisibility(null);

            if (result.status == 200) {
              dispatch(users({}));
              const rawList = usersList;
              const selectedUser = usersList.users.findIndex(
                (item) => item._id == _id
              );
              rawList.users.splice(selectedUser, 1);
              setUsersList({ ...usersList, rawList });
              setUserProfileModal(false);
              message.success({ content: "User Deleted", key, duration: 2 });
            } else {
              message.error({ content: "An error occurred", key, duration: 2 });
            }
          } catch (error) {
            setIsSending(0);
            console.log(error);
            message.error({
              content: error.response
                ? error.response.data.message
                : "An error occurred",
              key,
              duration: 2,
            });
          }
        },
      },
    ],
  };

  const dropdownMenu = (loading, user) => {
    if (loading) {
      return [
        {
          key: "1",
          label: <Loader />,
        },
      ];
    }

    if (user.suspended.value) {
      return actions.moreOptions.map((option, index) =>
        option.title === "lift suspention"
          ? {
              label: (
                <li
                  className="action-items"
                  onClick={() => option.onClick(user._id)}
                  key={index}
                >
                  {option.title}
                </li>
              ),
            }
          : null
      );
    }

    return actions.moreOptions.map((option, index) => {
      return {
        key: index,
        label: (
          <Fragment key={index}>
            {option.title !== "Verify" ? (
              <li
                className="action-items"
                onClick={() => option.onClick(user._id)}
              >
                {option.title}
              </li>
            ) : (
              !user.verifiedBusiness && (
                <li
                  className="action-items"
                  onClick={() => option.onClick(user._id)}
                >
                  {option.title}
                </li>
              )
            )}
          </Fragment>
        ),
      };
    });
  };

  const showUserDetails = async (_id) => {
    try {
      setIsSending(_id);
      setUserProfileModal(true);
      const result = await AxiosCall({
        method: "GET",
        path: "admin/users/" + _id,
      });

      setUserData(result.data);

      setIsSending(0);
      toast("successful");
      setVisibility(null);
    } catch (error) {
      toast("something went wrong");
    }
  };

  const itemRefs = useRef([]);
  itemRefs.current = [];

  const viewProfile = async (e, index, userId) => {
    if (visibility != null) return;
    if (e.target.parentNode != itemRefs.current[index].childNodes[0]) {
      showUserDetails(userId);
    }
  };

  const addItemRefs = (e) => {
    if (e && !itemRefs.current.includes(e)) {
      itemRefs.current.push(e);
    }
  };

  useEffect(() => {
    dispatch(users({}));
  }, []);

  useEffect(() => {
    if (usersListState.isSuccessful) {
      setUsersList(usersListState.data.users);
      setNavigationData(usersListState.data);
      dispatch(usersCleanup());
    } else if (usersListState.error) {
      setUsersList(null);
      setError(usersListState.error);
      dispatch(usersCleanup());
    }
  }, [usersListState]);

  const navigate = (data) => {
    dispatch(users({ ...data, limit: 10 }));
  };

  const closeModal = (e) => {
    if (e.target == userProfileRef.current) {
      setUserData(null);
      setUserProfileModal(false);
    }
  };

  const sortVerify = (arg) => {
    const result = navigationData.users.filter(
      (user) => user.verifiedBusiness === arg
    );
    setUsersList(result);
  };
  const sortSuspend = (arg) => {
    const result = navigationData.users.filter(
      (user) => user.suspended.value === arg
    );
    setUsersList(result);
  };

  return (
    <div className="admin-dashboard lofty-admin-dashboard">
      <Container>
        <h3>Dashboard</h3>
        <Row className="space-y-4">
          <Col md="3" sm="6">
            <Card className="admin-cards earning">
              <FontAwesomeIcon
                style={{
                  fontSize: "44px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                cursor="pointer"
                icon={faMoneyBill}
                color="#42B145"
              />
              <div className="card-content">
                <span>Earnings</span>
                <h4 className="value">${siteStats.data.adminData.earnings}</h4>
              </div>
            </Card>
          </Col>
          <Col md="3" sm="6">
            <Card className="admin-cards products">
              <FontAwesomeIcon
                style={{
                  fontSize: "44px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                cursor="pointer"
                icon={faShoppingCart}
                color="#0097F0"
              />
              <div className="card-content">
                <span>Total Products</span>
                <h4 className="value">
                  {siteStats.data.adminData.totalProducts}
                </h4>
              </div>
            </Card>
          </Col>
          <Col md="3" sm="6">
            <Card className="admin-cards programs">
              <FontAwesomeIcon
                style={{
                  fontSize: "44px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                cursor="pointer"
                icon={faTasks}
                color="#F73464"
              />
              <div className="card-content">
                <span>Total Programs</span>
                <h4 className="value">
                  {siteStats.data.adminData.totalPrograms}
                </h4>
              </div>
            </Card>
          </Col>
          <Col md="3" sm="6">
            <Card className="admin-cards users">
              <FontAwesomeIcon
                style={{
                  fontSize: "44px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
                cursor="pointer"
                icon={faUsers}
                color="#FDBF00"
              />
              <div className="card-content">
                <span>Total users</span>
                <h4 className="value">{siteStats.data.adminData.totalUsers}</h4>
              </div>
            </Card>
          </Col>
        </Row>
        <br />
        <br />

        <div className="d-flex justify-content-between mb-3">
          <h3
            style={{
              color: "#242B27",
              fontSize: "24px",
              fontWeight: "700",
              fontFamily: "Maven Pro, sans-serif",
            }}
          >
            All users
          </h3>

          <div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
              <DropdownToggle className="dropdown-btn" caret>
                Sort by
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => sortVerify(true)}
                >
                  Verified
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => sortVerify(false)}
                >
                  Unverified
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => sortSuspend(true)}
                >
                  Suspended
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => sortSuspend(false)}
                >
                  Unsuspended
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="table-responsive">
          {usersListState.isLoading ? (
            <div className="text-center py-5">
              <LoadingOutlined style={{ fontSize: 30 }} spin />
            </div>
          ) : (
            <table className="table borderless">
              <thead>
                <tr>
                  <th scope="col" className="col-3">
                    User ID
                  </th>
                  <th scope="col" className="col-3">
                    User Email
                  </th>
                  <th scope="col" className="col-2">
                    User Type
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Suspended
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Verified
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              {error && <p>{error}</p>}

              {usersList && (
                <tbody>
                  {usersList.map((user, key) => (
                    <tr
                      className="text-base text-black-200"
                      key={user._id}
                      onClick={(e) => viewProfile(e, key, user._id)}
                    >
                      <td>{user._id}</td>
                      <td className="lowercase"> {user.email}</td>
                      <td>{user.role}</td>
                      <td className="text-center">
                        <span
                          className="pad-2"
                          style={{
                            backgroundColor: getAccountSuspendBgColor(user),
                            borderRadius: "8px",
                          }}
                        >
                          {user.suspended.value ? "yes" : "no"}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className="pad-2"
                          style={{
                            backgroundColor:
                              getAccountConfirmationBgColor(user),
                            borderRadius: "8px",
                          }}
                        >
                          {user.confirmed ? "yes" : "no"}
                        </span>
                      </td>
                      <td className="delete-action" ref={addItemRefs}>
                        <ADropdown
                          open={visibility === user._id ? true : false}
                          menu={{
                            items: dropdownMenu(isSending === user?._id, user),
                          }}
                          trigger="click"
                          onOpenChange={() =>
                            visibility === null
                              ? setVisibility(user._id)
                              : setVisibility(null)
                          }
                        >
                          <MoreOutlined />
                        </ADropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>

        {usersList && <Paginate data={navigationData} navigate={navigate} />}
      </Container>

      {userProfileModal && (
        <UserProfileModal
          onClose={closeModal}
          userRef={userProfileRef}
          userData={userData}
        />
      )}
    </div>
  );
};

export default Dashboard;
