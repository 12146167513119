/* eslint-disable react-hooks/exhaustive-deps */
import "./profile.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePass,
  changePassCleanup,
} from "../../../Store/actions/changePassword";
import { Button } from "../../../Components/ui/Button";
import InputField from "../../../Components/ui/form/Input";

function EditPassword() {
  const dispatch = useDispatch();
  const changePassState = useSelector((state) => state.changePass);

  // form state
  const [form, setForm] = useState({
    oldPass: "",
    newPass: "",
    repeatNewPass: "",
  });

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // submit form

  const submitPass = (e) => {
    e.preventDefault();

    const data = {
      password: form?.oldPass,
      newPassword: form?.newPass,
    };

    if (form?.newPass !== form?.repeatNewPass) {
      setErrorMsg("Passwords do not match");
    } else {
      dispatch(changePass(data));

      setErrorMsg("");
      setForm({
        oldPass: "",
        newPass: "",
        repeatNewPass: "",
      });
    }
  };

  useEffect(() => {
    if (changePassState.isSuccessful) {
      setMsg("Password changed successfully");
      dispatch(changePassCleanup());
    } else if (changePassState.error) {
      setMsg("An error occured. Try again");
      dispatch(changePassCleanup());
    }
  }, [changePassState]);

  return (
    <div className="editProfileWrapper">
      <form
        onSubmit={submitPass}
        className="editProfileInner"
        style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
      >
        <h4>Change Password</h4>

        <InputField
          label="Old Password"
          type="password"
          name="oldPass"
          placeholder="******"
          value={form?.oldPass}
          onChange={onChange}
          isRequired
        />

        <InputField
          label="New Password"
          type="password"
          name="newPass"
          placeholder="******"
          value={form?.newPass}
          onChange={onChange}
          isRequired
        />
        <InputField
          label="Repeat New Password"
          type="password"
          name="repeatNewPass"
          placeholder="******"
          value={form?.repeatNewPass}
          onChange={onChange}
          isRequired
        />

        <div>
          <p className="text-success">{msg}</p>
          <p className="text-danger">{errorMsg}</p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            loading={changePassState?.isLoading}
            className="btn--edit-profile"
            type="submit"
          >
            Change Password
          </Button>
        </div>
      </form>
    </div>
  );
}

const inputStyles = {
  backgroundColor: "#f3f3f3",
  width: "100%",
  borderRadius: "5px",
  height: "40px",
  border: "1px solid rgba(0,0,0,0.1)",
  padding: "0px 5px",
};

const btnStyle = {
  width: "130px",
  height: "35px",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#ff4c4f",
  borderRadius: "8px",
  color: "#ffffff",
  border: "none",
};

const labelStyle = {
  fontWeight: "bolder",
  fontSize: "14px",
  color: "rgba(0,0,0,0.5)",
  marginBottom: "15px",
};

export default EditPassword;
