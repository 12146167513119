import { message } from "antd";
import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";

export const wishlistActionStart = (
  type:
    | "CREATE_WISHLIST_ITEM"
    | "UPDATE_WISHLIST_ITEM"
    | "GET_WISHLIST"
    | "DELETE_WISHLIST_ITEM"
    | "GET_WISHLIST_LISTING"
) => ({
  type,
});

export const wishlistActionError = (
  type:
    | "GET_WISHLIST_ERROR"
    | "CREATE_WISHLIST_ERROR"
    | "UPDATE_WISHLIST_ERROR"
    | "GET_WISHLIST_LISTING_ERROR",
  payload: any
) => ({
  type,
  payload,
});

export const getWishlistSuccess = (
  type: "GET_WISHLIST_SUCCESS" | "GET_WISHLIST_LISTING_SUCESS",
  payload
) => ({
  type,
  payload,
});

export const createWishlistSuccess = (
  type: "CREATE_WISHLIST_SUCCESS",
  payload
) => ({
  type,
  payload,
});

export const getWishlistCustomer =
  ({ limit, page }: { limit?: number; page: number }) =>
  async (dispatch) => {
    dispatch(wishlistActionStart("GET_WISHLIST"));

    try {
      const reqObj = {
        path: `wishlist/me?limit=${limit || 10}&page=${page}`,
        method: "GET",
      };
      const { data } = await AxiosCall(reqObj);
      dispatch(getWishlistSuccess("GET_WISHLIST_SUCCESS", data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(wishlistActionError("GET_WISHLIST_ERROR", error));
      return;
    }
  };

export const createWishlistItem =
  (wishlistItem: {
    title: string;
    description: string;
    state: string;
    city: string;
    type: "product" | "service";
    country: string;
    keywords?: string[];
  }) =>
  async (dispatch) => {
    dispatch(wishlistActionStart("CREATE_WISHLIST_ITEM"));

    try {
      const reqObj = {
        path: "wishlist/",
        method: "POST",
        data: wishlistItem,
      };

      await AxiosCall(reqObj);
      message.success({
        content: "Wishlist item created successfully",
        duration: 2,
      });
      dispatch(getWishlistCustomer({ page: 1 }));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(wishlistActionError("GET_WISHLIST_ERROR", error));
      return;
    }
  };

export const updateWishlistItem =
  (wishlistItem: {
    title: string;
    description: string;
    state: string;
    city: string;
    type: "product" | "service";
    country: string;
    keywords: string[];
    _id: string;
    fulfilled: boolean;
  }) =>
  async (dispatch) => {
    dispatch(wishlistActionStart("UPDATE_WISHLIST_ITEM"));
    try {
      const reqObj = {
        path: `wishlist/${wishlistItem._id}`,
        method: "PATCH",
        data: wishlistItem,
      };
      await AxiosCall(reqObj);
      message.success({
        content: "Wishlist item updated successfully",
        duration: 2,
      });
      dispatch(getWishlistCustomer({ page: 1 }));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(wishlistActionError("GET_WISHLIST_ERROR", error));
      return;
    }
  };

export const deleteWishlistItem = (wishlist_id: string) => async (dispatch) => {
  dispatch(wishlistActionStart("DELETE_WISHLIST_ITEM"));
  try {
    const reqObj = {
      path: `wishlist/${wishlist_id}`,
      method: "DELETE",
    };
    await AxiosCall(reqObj);
    message.success({
      duration: 2,
      content: "Deleted successfully",
    });
    dispatch(getWishlistCustomer({ page: 1 }));
  } catch (err) {
    const error = ErrorHandler(err);
    message.error({
      duration: 2,
      content: "Something went wrong",
    });
    dispatch(wishlistActionError("GET_WISHLIST_ERROR", error));
    return;
  }
};
export const getWishlist =
  ({ limit, query }: { limit?: number; query: string }) =>
  async (dispatch) => {
    dispatch(wishlistActionStart("GET_WISHLIST_LISTING"));
    try {
      const reqObj = {
        path: `wishlist?limit=${limit || 10}&${query}`,
        method: "GET",
      };
      const { data } = await AxiosCall(reqObj);
      dispatch(getWishlistSuccess("GET_WISHLIST_LISTING_SUCESS", data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(wishlistActionError("GET_WISHLIST_LISTING_ERROR", error));
      return;
    }
  };
