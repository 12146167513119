import InputField from "../../../../Components/ui/form/Input";
import Select from "../../../../Components/ui/form/Select";
import { getCitiesByState, getStates } from "./search.utils";

type ProductSearchType = {
  form: Record<string, string>;
  onChange(e: any): void;
  resetFields(fields: string[]): void;
};

export const ProductSearch = (props: ProductSearchType) => {
  const { onChange, form, resetFields } = props;

  const onChangeState = (e: any) => {
    // reset the city field on the form
    resetFields(["city"]);
    onChange(e);
  };

  return (
    <>
      <InputField
        label=""
        name="title"
        placeholder="Search..."
        onChange={onChange}
      />

      <Select
        name="type"
        placeholder="Category"
        options={[
          { label: "New product", value: "new" },
          { label: "Used product", value: "used" },
          { label: "Refurbished product", value: "refurbished" },
          { label: "Direct Services", value: "directService" },
          { label: "Indirect Services", value: "indirectService" },
        ]}
        // defaultValue={form?.view}
        onChange={onChange}
      />

      <Select
        name="state"
        placeholder="State"
        options={getStates()}
        // defaultValue={form?.view}
        onChange={onChangeState}
      />

      <Select
        name="city"
        placeholder="Arizhona"
        options={getCitiesByState(form.state)}
        // defaultValue={form?.view}
        onChange={onChange}
      />
    </>
  );
};
