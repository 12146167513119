import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { useLocation } from "react-router";

import { setPassword, setStage } from "../../Store/actions/signupProgress";
import { signup } from "../../Store/actions/signup";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Terms from "./Terms";
import Privacy from "./Privacy";

const CreatePassword = () => {
  const dispatch = useAppDispatch();
  const progress = useAppSelector((s) => s.signupProgress as any);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referralCode = searchParams.get("referral-code");

  const [modalValue, setModalValue] = useState<string | null>(null);

  const showModal = (value: string) => {
    setModalValue(value);
  };

  const handleOk = () => {
    setModalValue(null);
  };

  const handleCancel = () => {
    setModalValue(null);
  };

  const onFinish = (values) => {
    dispatch(
      setPassword({
        ...values,
      })
    );
    dispatch(
      signup({
        ...progress,
        ...values,
        referralCode,
      })
    );
  };

  // useEffect(() => {
  //     dispatch(signup(progress))
  // }, [progress])

  const onClick = () => {
    dispatch(
      setStage({
        stage: "profile",
      })
    );
  };

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="login"
        onFinish={onFinish}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "please input you password",
            },
            {
              min: 8,
              message: "password should be minimum of 8 characters",
            },
          ]}
          labelAlign="left"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <div className="mt-3 d-flex align-items-start gap-2">
          <input type="checkbox" required style={{ marginTop: "3px" }} />
          <label>
            I have read and agreed to{" "}
            <span className="terms-btn" onClick={() => showModal("terms")}>
              terms of use
            </span>{" "}
            and{" "}
            <span className="terms-btn" onClick={() => showModal("privacy")}>
              privacy policy
            </span>
          </label>
        </div>

        <Form.Item labelCol={{ sm: { span: 12 }, md: { span: 10 } }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
              marginTop: "50px",
            }}
          >
            <Button onClick={onClick} className="back">
              Back
            </Button>
            <Button htmlType="submit" className="signup-btn">
              Sign up
            </Button>
          </div>
        </Form.Item>
      </Form>

      <Modal
        title={
          modalValue === "terms"
            ? "Terms of use"
            : modalValue === "privacy"
            ? "Privacy Policy"
            : null
        }
        open={!!modalValue}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ height: "80vh", overflow: "hidden" }}
        bodyStyle={{ height: "90%", overflow: "scroll" }}
      >
        {modalValue === "terms" ? (
          <Terms switchModal={showModal} />
        ) : modalValue === "privacy" ? (
          <Privacy />
        ) : null}
      </Modal>
    </>
  );
};

export default CreatePassword;
