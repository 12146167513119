import { Col, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createWishlistItem } from "../../../Store/actions/wishlist";
import america from "../../../Utils/location/america";
import styles from "./index.module.css";

const CreateWishlistModal: React.FC<{
  open: boolean;
  handleModals: (type: "HIDE_CREATE_MODAL") => void;
}> = ({ open, handleModals }) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState<{
    title: string;
    description: string;
    state: string;
    city: string;
    type: "product" | "service" | "";
    country: string;
    keywords: string;
  }>({
    title: "",
    description: "",
    state: "",
    city: "",
    type: "",
    country: "",
    keywords: "",
  });
  const [cities, setCities] = useState<string[]>([]);

  const states = Object.keys(america);

  useEffect(() => {
    if (formState.state) {
      return setCities(america[formState.state]);
    }
    setCities([]);
  }, [formState.state]);

  const handleFormState = (
    e: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState((old) => ({ ...old, [name]: value }));
  };

  const handleFormSubmit = () => {
    const { title, description, state, city, type, country } = formState;
    if (!title.length) {
      message.error({
        duration: 2,
        content: "Please enter a title",
      });
      return;
    }
    if (!description.length) {
      message.error({
        duration: 2,
        content: "Please enter a description",
      });
      return;
    }
    if (!state.length) {
      message.error({
        duration: 2,
        content: "Please enter a state",
      });
      return;
    }
    if (!city.length) {
      message.error({
        duration: 2,
        content: "Please enter a city",
      });
      return;
    }
    if (!type.length || (type !== "product" && type !== "service")) {
      message.error({
        duration: 2,
        content: "Please choose a valid type",
      });
      return;
    }
    if (!country.length) {
      message.error({
        duration: 2,
        content: "Please enter a country",
      });
      return;
    }

    const wishObj: {
      title: string;
      description: string;
      state: string;
      city: string;
      type: "product" | "service";
      country: string;
      keywords?: string[];
    } = {
      title,
      description,
      state,
      city,
      type,
      country,
    };

    if (formState.keywords.length) {
      wishObj.keywords = formState.keywords
        .split(",")
        .map((string) => string.trim());
    }

    dispatch(createWishlistItem(wishObj) as any);
    handleModals("HIDE_CREATE_MODAL");
  };

  return (
    <Modal
      title="Add to Wishlist"
      open={open}
      onCancel={() => handleModals("HIDE_CREATE_MODAL")}
      onOk={() => handleFormSubmit()}
      width="50%"
      okText="Add to Wishlist"
      cancelText="Close"
    >
      <form name="createWishlist" onSubmit={() => handleFormSubmit()}>
        <Row className="mb-3">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <label
              htmlFor="title"
              className={`form-label ${styles.label_form}`}
            >
              Title
            </label>
            <input
              placeholder="Add a title"
              className={`${styles.input_field} d-block`}
              type="text"
              name="title"
              id="title"
              value={formState.title}
              onChange={handleFormState}
              required={true}
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <label
              htmlFor="description"
              className={`form-label ${styles.label_form}`}
            >
              Description
            </label>
            <textarea
              placeholder="Add a description"
              className={`${styles.input_field} d-block`}
              name="description"
              id="description"
              value={formState.description}
              onChange={handleFormState}
              rows={1}
              required={true}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <label
              htmlFor="state"
              className={`form-label ${styles.label_form}`}
            >
              State
            </label>
            <select
              name="state"
              id="state"
              className={`${styles.input_field} d-block`}
              onChange={handleFormState}
              value={formState.state}
            >
              {states.map((state) => {
                return (
                  <option value={state} key={state}>
                    {state}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <label htmlFor="city" className={`form-label ${styles.label_form}`}>
              City
            </label>
            <select
              name="city"
              id="city"
              className={`${styles.input_field} d-block`}
              onChange={handleFormState}
              value={formState.city}
            >
              {cities.map((city) => {
                return (
                  <option value={city} key={city}>
                    {city}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <label
              htmlFor="country"
              className={`form-label ${styles.label_form}`}
            >
              Country
            </label>
            <input
              placeholder="Add a country"
              className={`${styles.input_field} d-block`}
              type="text"
              name="country"
              id="country"
              value={formState.country}
              onChange={handleFormState}
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor="type" className={`form-label ${styles.label_form}`}>
              Type
            </label>
            <select
              name="type"
              id="type"
              className={`${styles.input_field} d-block`}
              onChange={handleFormState}
            >
              <option value="none" defaultValue={""} disabled hidden>
                Select a Type
              </option>
              <option value="product">Product</option>
              <option value="service">Service</option>
            </select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <label
              htmlFor="keywords"
              className={`form-label ${styles.label_form}`}
            >
              Keywords
            </label>
            <input
              placeholder="Split keywords with a comma"
              className={`${styles.input_field} d-block`}
              type="text"
              name="keywords"
              id="keywords"
              value={formState.keywords}
              onChange={handleFormState}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default CreateWishlistModal;
