import secureLocalStorage from "react-secure-storage";
import { selectLoggedInUser } from "../Store/actions/getMe";
import { useAppSelector } from "../hooks";

export const useIsLoggedIn = () => {
  const loggedInUser = useAppSelector(selectLoggedInUser);

  return {
    isCustomer: loggedInUser?.data?.user?.role === "customer",
    isBusiness: loggedInUser?.data?.user?.role === "business",
    isLoggedIn: !!loggedInUser?.data?.user && !!secureLocalStorage.getItem("authToken")
  }

};
