import { Space } from "antd";
import { useEffect } from "react";
import { message as antdMessage } from "antd";
//
import { useAppDispatch, useAppSelector } from "../../hooks";
import ProgramList from "./ProgramList";
import "./index.css";
import {
  programsList as vipProgramsList,
  selectPrograms,
  programsCleanup,
} from "../../Store/actions/programs";
import Loader from "../../Components/ui/Loader";
import { selectAddToCart } from "../../Store/actions/addToCart";
import { useGetCartItems } from "../../hooks/cart";

const Programs = () => {
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const dispatch = useAppDispatch();
  const [cart] = useGetCartItems();
  const { isLoading, error } = useAppSelector(selectPrograms);

  const { message } = useAppSelector(selectAddToCart);

  useEffect(() => {
    // fetch all data by page
    dispatch(programsCleanup());
    dispatch(vipProgramsList());
  }, []);

  // show notification for when user adds or removes item from the cart
  useEffect(() => {
    if (message) {
      messageApi.open({
        type: "info",
        content: message,
        duration: 5,
      });
    }
  }, [message]);

  return (
    <div className="programs_wrapper">
      {/* show information for when message item is added or removed */}
      {contextHolder}
      {/* render error */}
      {error && (
        <p className="error__card">
          {typeof error === "string" ? error : "An unknown error occured"}
        </p>
      )}
      {/* Page title */}
      <h4 className="programs__title">All VIP Programs</h4>

      {/* Program list */}
      <div className="programs__content">
        {isLoading ? (
          <Space
            style={{ display: "grid", placeContent: "center", height: "600px" }}
          >
            <Loader fontSize={50} size="large" />
          </Space>
        ) : (
          <ProgramList />
        )}
      </div>
    </div>
  );
};

export default Programs;
