import { useState } from "react";
import { Button, Form, Radio, Space } from "antd";

//actions
import { setRole } from "../../Store/actions/signupProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";

const AccountType = () => {
  const {role } = useAppSelector(s=>s.signupProgress)
  const [accountType, setAccountType] = useState(role);
  const dispatch = useAppDispatch();

  const next = () => {
    if (accountType)
      dispatch(
        setRole({
          role: accountType,
        })
      );
  };

  return (
    <Form>
      <Radio.Group
        onChange={(e) => setAccountType(e.target.value)}
        defaultValue={accountType}
      >
        <Space direction="vertical">
          <Radio value="affiliate">Affiliate</Radio>
          <Radio value="business">Business</Radio>
          <Radio value="customer">Customer</Radio>
        </Space>
      </Radio.Group>
      <Form.Item labelCol={{ sm: { span: 12 } }}>
        <Space
          align="end"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={next}
            className="btn btn--signup"
            size="large"
            type="primary"
            danger
          >
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AccountType;
