import  secureLocalStorage  from  "react-secure-storage";

export const setAuth = (token = "") => {
  // save the token
  secureLocalStorage.setItem("authToken", token);

  return {
    type: "SET_AUTH",
    payload: {
      token,
      isLoggedIn: true,
    },
  };
};

export const clearAuth = () => ({
  type: "CLEAR_AUTH",
});
