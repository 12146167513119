import { Avatar, Popover, Tag } from "antd";
import "./index.css";
import { formatCurrency } from "../../../../Utils";

export const CartItemDetail = (props: any) => {
  const percentageOfProduct =
    (props?.item?.inStock / (props?.stock === 0 ? 1 : props?.stock)) * 100;

  const percentageOfDiscount =
    (props?.item?.discount /
      (props?.item?.price === 0 ? 1 : props?.item?.price)) *
    100;

  const totalPrice =
    (props?.item?.inStock ?? 0) * (props?.item?.sellingPrice ?? 0);

  return (
    <Popover
      content={
        <div className="cart cart_item_detail position-relative">
          <span className="position-absolute dicount__tag bg-warning text-white text-italic">
            {percentageOfDiscount.toFixed(0)}% OFF
          </span>
          <div className="cart_item_detail_image">
            <div className="product-item-image--container">
              <img
                className="product-item-image"
                src={props?.item?.avatar?.secure_url}
                alt="product-image"
              />
            </div>

            <h5 className="fw-bolder">{props?.item?.title}</h5>
            <p className="fs-6">{props?.item?.description}</p>

            {/* show all the associated tags */}
            {!!props?.item?.tags?.length ? (
              <div className="state__list d-flex flex-wrap align-items-center gap-1/2">
                {props?.item?.tags[0].split(",")?.map((tag, key) => (
                  <Tag key={key} style={{ padding: "3px" }}>
                    {tag}
                  </Tag>
                ))}
              </div>
            ) : null}

            <div className="d-flex gap-2 align-items-center my-3">
              {props?.item?.inStock > 0 ? (
                <span className="p-1 text-success border border-1 rounded-pill border-success text-success">
                  In stock
                </span>
              ) : (
                <span className="p-1 border border-1 rounded-pill border-danger text-danger">
                  Out of stock
                </span>
              )}

              {props?.item?.brand ? (
                <span className="p-1 rounded-pill bg-secondary text-white">
                  {props?.item?.brand}
                </span>
              ) : null}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold">Amount Per unit:</span>
              <span className="fw-normal">
                ${props?.item?.sellingPrice?.toFixed(2)}
              </span>
            </div>

            {/* show the available stock for this product */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold">Units (In Stock):</span>
              <span className="fw-normal">{props?.item?.inStock}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold">% of product in program:</span>
              <span className="fw-normal">
                {percentageOfProduct.toFixed(2)}%
              </span>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold">Total:</span>
              <span className="fw-normal">${formatCurrency(totalPrice)}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center gap-3">
              <span className="fw-bold">Address:</span>
              <span className="fw-normal">{props?.item?.address}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center gap-3">
              <span className="fw-bold">State:</span>
              <span className="fw-normal">{props?.item?.state}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center gap-3">
              <span className="fw-bold">Product Type:</span>
              <span className="fw-normal">{props?.item?.productType}</span>
            </div>
          </div>
        </div>
      }
      trigger="click"
    >
      <Avatar
        style={{ cursor: "pointer" }} // click to see the product detail
        src={props?.item?.avatar?.secure_url}
        size={"large"}
      />
    </Popover>
  );
};
