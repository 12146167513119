import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  FileOutlined,
  HomeOutlined,
  TransactionOutlined,
  NotificationOutlined,
  ProjectOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "../../hooks";
import secureLocalStorage from "react-secure-storage";
import { clearAuth } from "../../Store/actions/auth";
import { Menu } from "antd";

const getAdminMenuList = (onClick: Function, logout: Function) => [
  {
    key: "account",
    label: "Dashboard",
    icon: <HomeOutlined />,
    onClick: onClick("/account"),
  },
  {
    key: "users",
    label: "Users",
    icon: <UserOutlined />,
    children: [
      {
        key: "affiliate_accounts",
        label: "Affiliate Accounts",
        onClick: onClick("/account/affiliate"),
      },
      {
        key: "business_accounts",
        label: "Business Accounts",
        onClick: onClick("/account/business"),
      },
      {
        key: "customer",
        label: "Customer Accounts",
        onClick: onClick("/account/customer"),
      },
    ],
  },
  {
    key: "vip_program_access",
    label: "VIP Program Access",
    icon: <ProjectOutlined />,
    children: [
      {
        key: "all_products",
        label: "All Products",
        onClick: onClick("/account/products"),
      },
      {
        key: "sold_products",
        label: "Sold Products",
        onClick: onClick("/account/sold"),
      },
      {
        key: "products_instock",
        label: "In Stock",
        onClick: onClick("/account/in-stock"),
      },
    ],
  },
  {
    key: "programs",
    label: "Programs",
    icon: <ProjectOutlined />,
    children: [
      {
        key: "all_programs",
        label: "All Programs",
        onClick: onClick("/account/programs"),
      },
      {
        key: "active_programs",
        label: "Active Programs",
        onClick: onClick("/account/active"),
      },
      {
        key: "pending_items",
        label: "Pending",
        onClick: onClick("/account/qrcodes/open"),
      },
      {
        key: "redeemed_items",
        label: "Redeemed",
        onClick: onClick("/account/qrcodes/redeemed"),
      },
    ],
  },
  {
    key: "transactions",
    label: "Transaction History",
    icon: <TransactionOutlined />,
    onClick: onClick("/account/transactions"),
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <NotificationOutlined />,
    onClick: onClick("/account/notifications"),
  },
  {
    key: "user_logout",
    label: "Logout",
    icon: <UserOutlined />,
    onClick: logout("/account/logout"),
  },
];

const getCustomerMenuList = (onClick: Function, logout: Function) => [
  {
    key: "account",
    label: "Dashboard",
    icon: <HomeOutlined />,
    onClick: onClick("/account"),
  },
  {
    key: "cart",
    label: "My Cart",
    icon: <ShoppingCartOutlined />,
    onClick: onClick("/account/cart"),
  },
  {
    key: "programs",
    label: "Programs",
    icon: <ProjectOutlined />,
    children: [
      {
        key: "pending_items",
        label: "Pending Items",
        icon: <QrcodeOutlined />,
        onClick: onClick("/account/qrcodes/open"),
      },
      {
        key: "redeemed_items",
        label: "Redeemed Items",
        icon: <QrcodeOutlined />,
        onClick: onClick("/account/qrcodes/redeemed"),
      },
    ],
  },
  {
    key: "transactions",
    label: "Transaction History",
    icon: <TransactionOutlined />,
    onClick: onClick("/account/transactions"),
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <NotificationOutlined />,
    onClick: onClick("/account/notifications"),
  },
  {
    key: "faqs",
    label: "FAQs",
    icon: <QuestionCircleOutlined />,
    onClick: onClick("/faq/customer"),
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    onClick: onClick("/account/settings"),
  },
  {
    key: "user_logout",
    label: "Logout",
    icon: <UserOutlined />,
    onClick: logout("/account/logout"),
  },
];

const getAffiliateMenuList = (onClick: Function, logout: Function) => [
  {
    key: "account",
    label: "Dashboard",
    icon: <HomeOutlined />,
    onClick: onClick("/account"),
  },

  {
    key: "referrals",
    label: "Referrals",
    icon: <TransactionOutlined />,
    onClick: onClick("/account/referrals"),
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <NotificationOutlined />,
    onClick: onClick("/account/notifications"),
  },
  {
    key: "faqs",
    label: "FAQs",
    icon: <QuestionCircleOutlined />,
    onClick: onClick("/faq/customer"),
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    onClick: onClick("/account/settings"),
  },
  {
    key: "user_logout",
    label: "Logout",
    icon: <UserOutlined />,
    onClick: logout("/account/logout"),
  },
];

const getBusinessMenuList = (onClick: Function, logout: Function) => [
  {
    key: "account",
    label: "Dashboard",
    icon: <HomeOutlined />,
    onClick: onClick("/account"),
  },
  {
    key: "vip_program_access",
    label: "VIP Program Access",
    icon: <FileOutlined />,
    children: [
      {
        key: "create-program",
        label: "Create Item",
        // icon: <FileOutlined />,
        onClick: onClick("/account/create-program"),
      },
      {
        key: "items_list",
        label: "Items List",
        // icon: <FileOutlined />,
        onClick: onClick("/account/items"),
      },
      {
        key: "create-vip-program",
        label: "Create VIP Program",
        // icon: <FileOutlined />,
        onClick: onClick("/account/create-vip-program"),
      },
      {
        key: "programs",
        label: "VIP Program List",
        // icon: <FileOutlined />,
        onClick: onClick("/account/programs"),
      },
    ],
  },
  {
    key: "redeemed",
    label: "Redeemed Codes",
    icon: <QrcodeOutlined />,
    onClick: onClick("/account/qrcodes/redeemed"),
  },
  {
    key: "open",
    label: "Unredeemed Codes",
    icon: <QrcodeOutlined />,
    onClick: onClick("/account/qrcodes/open"),
  },
  {
    key: "transactions",
    label: "Transaction History",
    icon: <TransactionOutlined />,
    onClick: onClick("/account/transactions"),
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <NotificationOutlined />,
    onClick: onClick("/account/notifications"),
  },
  {
    key: "faqs",
    label: "FAQs",
    icon: <QuestionCircleOutlined />,
    onClick: onClick("/faq/customer"),
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    onClick: onClick("/account/settings"),
  },
  {
    key: "user_logout",
    label: "Logout",
    icon: <UserOutlined />,
    onClick: logout(),
  },
];

const getMenuList = (role: string, onClick: Function, logout: Function) => {
  switch (role) {
    case "admin":
      return getAdminMenuList(onClick, logout);
    case "affiliate":
      return getAffiliateMenuList(onClick, logout);
    case "business":
      return getBusinessMenuList(onClick, logout);
    case "customer":
      return getCustomerMenuList(onClick, logout);
    default:
      return [];
  }
};

const routes = [
  "business",
  "affiliate",
  "customer",
  "products",
  "sold",
  "in-stock",
  "programs",
  "active",
  "pending",
  "redeemed",
  "transactions",
  "settings",
  "account",
  "cart",
];

type SidebarProps = {
  role: string;
};

export default function SideBar(props: SidebarProps) {
  const [keys, setKeys] = useState<string[]>([]);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // style the active route by default

  useEffect(() => {
    const pathname = location.pathname;
    const currentRoute = pathname?.split("/")?.at(-1);

    if (routes.includes(currentRoute)) {
      setKeys([currentRoute]);
    }
  }, []);

  const handleClickMenuItem = (route: string) => (info: { key: string }) => {
    setKeys(() => [info.key]);
    history.push(route);
  };

  // TODO: replace this with hook implementation of logout
  const logout = () => () => {
    secureLocalStorage.removeItem("authToken");
    dispatch(clearAuth());
  };

  return (
    <Menu
      mode="inline"
      selectedKeys={keys}
      theme="dark"
      items={getMenuList(props.role, handleClickMenuItem, logout)}
    />
  );
}
