import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./index.css";
import { CartButtonProps } from "./types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToCart, addToCartCleanup } from "../../Store/actions/addToCart";
import { selectLoggedInUserRole } from "../../Store/actions/getMe";
import { Button } from "../ui/Button";
import { useIsLoggedIn } from "../../hooks/auth";
import { selectGetCart } from "../../Store/actions/getCart";

const CartButton: React.FC<CartButtonProps> = ({ program_id, variant = 1 }) => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const currentRole = useAppSelector(selectLoggedInUserRole);
  const { isLoggedIn } = useIsLoggedIn();
  const cartState = useAppSelector(selectGetCart);
  const isInCart = cartState?.data?.find((item) => item._id === program_id);
  const [isFetching, setIsFetching] = useState(false);

  const handleIsFetching = (isFetching: boolean) => setIsFetching(isFetching);

  // track the addition and removal of items from cart
  useEffect(() => {
    if (cartState.isSuccessful || !!cartState.error) {
      handleIsFetching(false);
    }

    return () => {
      dispatch(addToCartCleanup());
      console.log("cleanup...");
    };
  }, [cartState.isSuccessful]);

  const handleCart = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    program_id: string,
    type: "remove" | "add"
  ) => {
    e.preventDefault();
    if (isLoggedIn) {
      setIsFetching(true);
      dispatch(addToCart({ id: program_id, type }));
    } else {
      push("/login");
    }
  };

  if (!currentRole) {
    return (
      <Link to="/signup">Please sign up as a customer to add to cart</Link>
    );
  } else if (currentRole !== "customer") {
    return <Link to="/signup">Only customer accounts can buy programs</Link>;
  }


  return (
    <>
      {isInCart ? (
        <div className="btn-cart-container">
          <Link className="btn btn__cart--view" to="/account/cart">
            View Cart
          </Link>
          {variant === 2 && (
            <Link className="btn btn__cart--continue" to="products">
              Continue Shopping
            </Link>
          )}
          {variant === 1 && (
            <Button
              className="btn btn__cart--remove"
              loading={isFetching && !!isInCart}
              onClick={(e: any) => handleCart(e, program_id, "remove")}
            >
              Remove from Cart
            </Button>
          )}
        </div>
      ) : (
        <Button
          className="btn btn__cart--add"
          loading={isFetching && !isInCart}
          onClick={(e: any) => handleCart(e, program_id, "add")}
        >
          Add to Cart
        </Button>
      )}
    </>
  );
};

export default CartButton;
