import * as htmlToImage from "html-to-image";

//
export async function convertComponentToImage(
  node: HTMLElement,
  filename: string,
  options = {}
) {
  htmlToImage
    .toPng(node)
    .then((url) => {
      const f = filename.replace(/\s/g, "-").toLowerCase().split(".")[0];

      downloadQRCode(url, `${f}.png`);
    })
    .catch(console.error);
  console.log("🚀 ~ filename:", filename);
}

function downloadQRCode(url, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
