/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//MODULES AND PACKAGES
import { useState, useEffect, Fragment } from "react";

import { useHistory } from "react-router-dom";
import { Alert, message } from "antd";

// STYLES
import { Row, Col } from "reactstrap";
import "./index.css";

//ACTIONS
import { StopOutlined } from "@ant-design/icons";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { PaymentResponseType } from "./payment.type";
import {
  createUserPayment,
  createUserPaymentCleanup,
  selectUserPayment,
} from "../../../../Store/actions/userPayment";
import ProgramQRCodeItem from "../../common/cart/ProgramQRCodeItem";
import ShoppingList from "../../common/cart/ShoppingList";
import { CartSummary } from "../../common/cart/CartSummary";
import {
  addToCart,
  addToCartCleanup,
} from "../../../../Store/actions/addToCart";
import CartCheckoutConsentModal from "./CartCheckoutConsentModal";
import { EmptyCart } from "../../common/cart";
import { config } from "../../../../config";
import constants from "../../../../config/constants";

const PayPal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const paymentState = useAppSelector(selectUserPayment);
  const getCartState = useAppSelector((state) => state.getCart);
  const authState = useAppSelector((state) => state.auth);

  // constants
  const WEB_URL = config.REACT_APP_WEB_URL;

  const [paidFor, setPaidFor] = useState(false);
  const [onOpenTerms, setOnOpenTerms] = useState(false);
  const [proceedToCheckout, setProceedToCheckout] = useState(false);
  const [qrCode, setQrCode] = useState<PaymentResponseType[]>([]);

  const totalPrice = (
    constants.PLATFORM_CHARGES * getCartState.data?.length
  ).toFixed(2); // for each program package, savenwin charges $29.99

  const programIds = getCartState.data?.map((item) => item?._id);

  const paypalOptions: any = {
    "client-id": config.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
  };

  const isAvailable = ({
    stock,
    owner,
    disabled,
  }: {
    stock: number;
    owner: { suspended: { value: boolean }; deleted: { value: boolean } };
    disabled: boolean;
  }): boolean => {
    if (
      stock > 0 &&
      !owner.suspended?.value &&
      !owner.deleted?.value &&
      !disabled
    ) {
      return true;
    }
    return false;
  };

  const handleCloseCheckout = () => setProceedToCheckout(false);

  const handleProceedToCheckout = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cart: {
      stock: number;
      owner: { suspended: { value: boolean }; deleted: { value: boolean } };
      disabled: boolean;
    }[]
  ) => {
    e.preventDefault();
    const isValid: boolean = cart.some((item) => isAvailable(item));

    if (!isValid) {
      message.error({
        content: "Some items in your cart are not available",
        duration: 2,
      });
      return;
    }
    setProceedToCheckout(true);
    return;
  };

  const handleSignConsent = (e: any) => {
    handleProceedToCheckout(e, getCartState.data);
    setOnOpenTerms(false);
  };

  /**
   * Create Paypal Order
   * @param data
   * @param actions
   * @returns
   */
  const handleCreateOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          items: getCartState.data?.map((item) => ({
            name: item?.title,
            description: `sold by ${item?.owner?.email}`,
            quantity: 1,
            unit_amount: {
              currency_code: "USD",
              value: constants.PLATFORM_CHARGES,
            },
          })),
          amount: {
            currency_code: "USD",
            value: constants.PLATFORM_CHARGES,
            breakdown: {
              item_total: {
                currency_code: "USD",
                value: constants.PLATFORM_CHARGES,
              },
            },
          },
        },
      ],
      application_context: {
        return_url: `${WEB_URL}/account/cart`,
        cancel_url: `${WEB_URL}/account/cart`,
      },
    });
  };

  const handleOnApprove = (data, actions) => {
    return actions.order
      .capture()
      .then((details: { status: string }) => {
        if (details.status === "COMPLETED" && programIds.length > 0) {
          dispatch(
            createUserPayment({
              orderID: data.orderID,
              cart: getCartState.data,
              paymentStatus: details.status,
              paymentID: data.paymentID,
              payerID: data.payerID,
              programID: programIds[0],
              facilitatorAccessToken: data.facilitatorAccessToken,
            })
          );
        }
        history.push("/account/cart");
      })
      .then(() => {
        dispatch(addToCartCleanup());
      })
      .catch((err) => {
        console.log("🚀 ~ handleOnApprove ~ err:", err);
      });
  };

  // list of all actions on the Paypal Smart Button
  const paypalActions: any = {
    createOrder: handleCreateOrder,
    onApprove: handleOnApprove,
  };

  useEffect(() => {
    if (paymentState.isSuccessful) {
      setQrCode(paymentState.data);
      console.info("The payment state is not updated");
      setPaidFor(true);

      programIds.forEach((singleId) => {
        dispatch(addToCart({ id: singleId, type: "remove" }));
      });
    }
  }, [paymentState.isSuccessful]);

  //CHECK IF PAYMENT IS SUCCESSFULL
  if (paidFor) {
    return (
      <div className="qrCode__page">
        <h4 className="fw-bold mt-3 mb-5 text-center">Generated QRCodes</h4>

        {/* GENERATE QRCODE  */}
        <div className="qrcode__list">
          {qrCode.map((qrcode) => (
            <ProgramQRCodeItem key={qrcode._id} qrcode={qrcode} />
          ))}
        </div>
      </div>
    );
  }

  //CHECK IS USER IS LOGGED IN
  if (authState.isLoggedIn === false) history.push("/login");

  if (authState.isLoggedIn) {
    return (
      // implement a two column layout where the left is 8 time bigger than the right column
      <div className="cart_page ml-4">
        {paymentState?.error && (
          <div className="pt-1">
            <Alert
              message="Payment Failed"
              description={paymentState?.error}
              type="error"
              closable
              onClose={() => dispatch(createUserPaymentCleanup())}
              className="m-3"
            />
          </div>
        )}

        {getCartState.data?.length > 0 ? (
          <Fragment>
            <Row
              gutter={[
                { xs: 16, sm: 24, md: 32 },
                { xs: 8, sm: 16, md: 24, lg: 32 },
              ]}
            >
              <Col className="px-0">
                <div className="bg-white mx-4 my-3">
                  <h3 className="fw-bolder text-start px-3 py-2">
                    Cart ({getCartState.data?.length ?? 0})
                  </h3>
                  <hr className="text-secondary border-2" />
                </div>

                <ShoppingList />
              </Col>

              <Col style={{ flex: "0 0 30%" }} className="px-0">
                <CartSummary
                  subtotal={+totalPrice}
                  handleProceed={() => setOnOpenTerms(true)}
                />

                {proceedToCheckout ? (
                  <div
                    className="paypal__smart__buttons bg-white p-3"
                    onMouseOver={() => {
                      // the smart paypal button saves paypal session in local storage.
                      // remove the session when you want to start another session.
                      localStorage.removeItem("__paypal_storage__");
                    }}
                  >
                    <PayPalScriptProvider options={paypalOptions}>
                      <PayPalButtons {...paypalActions} />
                    </PayPalScriptProvider>

                    <div
                      className={`${
                        proceedToCheckout ? "d-none" : "smart__hidden d-flex"
                      } justify-content-center align-items-center`}
                    >
                      <StopOutlined className="text-danger" />
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>

            {/* show terms and condition before allowing them to proceed to make payment */}
            <CartCheckoutConsentModal
              open={onOpenTerms }
              onClose={handleCloseCheckout}
              onProceed={handleSignConsent}
            />
          </Fragment>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "89vh", margin: 0 }}
          >
            <EmptyCart />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default PayPal;
