import { Col, Row } from "antd";
import { formatCurrency } from "../../../../Utils";
import { Button } from "../../../../Components/ui/Button";
import "./index.css"

type CartSummaryPropTypes = {
  subtotal: number;
  handleProceed(): void;
};
export function CartSummary(props: CartSummaryPropTypes) {
  return (
    <div className="cart__summary p-3 m-3 border-rounded">
      <h3 className="cart__title">CART SUMMARY</h3>

      <Row align={"middle"} justify={"space-between"}>
        <Col className="fw-normal fs-5">Subtotal:</Col>
        <Col className="fw-bolder fs-5">{formatCurrency(props.subtotal)}</Col>
      </Row>

      <Row align={"middle"} justify={"space-between"}>
        <Button
          onClick={props.handleProceed}
          className="w-100 btn btn-danger mt-2"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Payment is for service charges for all the items in the cart"
        >
          Pay Charges
        </Button>
      </Row>
    </div>
  );
}
