import { LoadingOutlined } from "@ant-design/icons";
import { ButtonProps, Spin } from "antd";

export function Button(props: ButtonProps) {
  return (
    <button
      className={`btn ${props.className}`}
      onClick={props.onClick}
      style={props?.style}
    >
      {props.loading ? (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
          }
        />
      ) : (
        props.children
      )}
    </button>
  );
}
