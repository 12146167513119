import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank, faShoppingCart, faSmile } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PromotionCard from "../../../Components/PromotionCard";
import "./index.css";

const Promotion = () => (
  <div className="promotion">
    <Container>
      <div className="text-center py-5">
        <div className="fs-5">Why Savenwin</div>
        <div className="fs-1 fw-bolder">Shop smart, win big and earn rewards</div>
        <p className="fs-5 mx-auto">
          Step into the world of smart shopping with SaveNwin. Our platform combines the best of shopping, savings, and
          winning for an unforgettable experience.
        </p>
      </div>

      <Row className="py-3">
        <Col sm={12} md={6} lg={4}>
          <PromotionCard
            title="Shop"
            bgColor="#d6cfff"
            icon={<FontAwesomeIcon icon={faShoppingCart as IconProp} color="#614ecf" fontSize={25} />}
          >
            Shop for all your favorite products and enjoy unbeatable deals. With a wide range of categories, you'll find
            everything you need, from fashion to electronics, all at a great price."
          </PromotionCard>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <PromotionCard
            title="Save"
            bgColor="#bad7be"
            icon={<FontAwesomeIcon icon={faPiggyBank as IconProp} color="#4ea159" fontSize={25} />}
          >
            Maximize your savings with our exclusive coupons and promo codes. Whether you're shopping for essentials or
            treating yourself to something special, you'll save big every time you shop with us.
          </PromotionCard>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <PromotionCard
            title="Support your community"
            bgColor="#b8edfd"
            icon={<FontAwesomeIcon icon={faSmile as IconProp} color="#00c4ff" fontSize={25} />}
          >
            &#8246;At SaveNwin, we believe in giving back to the community. A portion of our profits goes towards supporting
            local organizations and causes that make a difference in people's lives.
          </PromotionCard>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Promotion;
