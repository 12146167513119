function PromotionCard({ icon, bgColor, title, children }) {
  return (
    <div className="border position-relative p-2  rounded card-promo">
      <div
        className="p-3  position-absolute rounded"
        style={{ backgroundColor: bgColor, height: 60, width: 60, top: -20, left: 20 }}
      >
        {icon}
      </div>
      <div className="my-5 mb-2 fw-bold fs-4">{title}</div>
      <div className="">{children}</div>
    </div>
  );
}

export default PromotionCard;
