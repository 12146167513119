/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

import {
  CloseOutlined,
  FacebookFilled,
  InfoCircleOutlined,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeFilled
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

//media
import logo from "../../../Assets/images/logo.png";
import heroImg from "../../../Assets/images/heroimg.jpg";
import confetti from "../../../Assets/images/confetti.png";

//actions
import { subCleanup } from "../../../Store/actions/subscription";
import { Alert } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";

const SubscriptionModal = (props) => {
  const { toggle, modal, subscribe } = props;
  const dispatch = useAppDispatch();
  const subState = useAppSelector((state) => state.sub);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (subState.isSuccessful) {
      setSuccess(subState.isSuccessful);
      setTimeout(() => {
        toggle();
      }, 5000);
      dispatch(subCleanup);
    } else if (subState.error) {
      setError(subState.error);
      dispatch(subCleanup);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [subState]);

  return (
    <Modal isOpen={modal} toggle={toggle} className="email-popup">
      <CloseOutlined onClick={toggle} className="close-btn" />
      {!success && (
        <div className="d-flex email-popup-content">
          <div className="left">
            <div className="left-top">
              <div className="img-container">
                {/* <img src={special} alt="special" className="special" /> */}
                <img src={logo} alt="logo" />
              </div>
            </div>
            <div className="left-bottom d-flex align-items-center">
              <div className="left-bottom-left pt-3">
                <h3 className="text-uppercase">Get exclusive VIP offers!</h3>
              </div>
              <div className="left-bottom-right">
                <img src={heroImg} alt="hero" width={'100%'} />
              </div>
            </div>
          </div>
          <div className="right">
            <h2 className="text-uppercase">New VIP offers Early Bird notification!</h2>
            <p style={{fontSize: '13px'}}>
              Subscribe to the SaveNWin VIP mailing list to be notified of new VIP
              offers before they sell out!
            </p>
            {error ? (
              <Alert message={error} type="error" showIcon closable />
            ) : null}
            <form
              className="modal-sub-form"
              onSubmit={(e) => {
                e.preventDefault();
                subscribe({ email });
              }}
            >
              <input
                value={email}
                className="input_field"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email address"
              />
              <button type="submit">Subscribe</button>
            </form>
            <p className="share-container">
              <span className="share-btn">
                <a href="#">
                  <FacebookFilled width={'20px'} />
                </a>

                <a href="#">
                  <TwitterOutlined width={'20px'} />
                </a>

                <a href="#">
                  <LinkedinFilled width={'20px'} />
                </a>

                <a href="#">
                  <InstagramOutlined width={'20px'} />
                </a>

                <a href="#">
                  <YoutubeFilled width={'20px'} />
                </a>

                <a href="#">
                  <InfoCircleOutlined width={'20px'} />
                </a>
              </span>
            </p>
          </div>
        </div>
      )}
      {success && (
        <>
          <img src={confetti} alt="well done!" />
          <h1>Well Done!</h1>
          <p>You have successfully subscribed to our newslette</p>
        </>
      )}
    </Modal>
  );
};

export default SubscriptionModal;
