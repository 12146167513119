import React from "react";
import { ProgramType } from "../../types";

function ProgramDeleteTemplate({ data }) {
  let program :ProgramType | null = data.resource.program;
  return (
    <div>
      {data.resource && (
        <>
          <p className="text-center fw-bold fs-6">Summary of your program</p>
          <h6>
            Program name:{" "}
            <span className="fw-normal">{program? program.title: <b style={{fontSize: '12px'}}>Unknown</b>}</span>
          </h6>
          <h6>
            Total Selling Price:{" "}
            <span className="fw-normal">
              {program? '$' + program.totalPrice: <b style={{fontSize: '12px'}}>Unknown</b>}
            </span>
          </h6>
          <h6>
            Total discount:{" "}
            <span className="fw-normal">
              {program? '$' + program.totalDiscount: <b style={{fontSize: '12px'}}>Unknown</b>}
            </span>
          </h6>
          <h6>
            Final price:{" "}
            <span className="fw-normal">
              {program? '$' + program.totalSellingPrice: <b style={{fontSize: '12px'}}>Unknown</b>}
            </span>
          </h6>
          <h6>
            Address:{" "}
            <span className="fw-normal">{program? program.address: <b style={{fontSize: '12px'}}>Unknown</b>}</span>
          </h6>
          <h6>
            City:{" "}
            <span className="fw-normal">{program? program.city: <b style={{fontSize: '12px'}}>Unknown</b>}</span>
          </h6>
          <h6>
            State:{" "}
            <span className="fw-normal">{program? program.state: <b style={{fontSize: '12px'}}>Unknown</b>}</span>
          </h6>
          <p>
            Date:
            {new Date(data.createdAt).toLocaleDateString()}
          </p>
          {
            program && program.owner && typeof program.owner === 'object'? (
              <>
                <hr />
                <p className="text-center fw-bold fs-6">Business details</p>
                <h6>
                  Name:{" "}
                  <span className="fw-normal">
                    {program.owner.businessName? program.owner.businessName: <b style={{fontSize: '12px'}}>Unknown</b>}
                  </span>
                </h6>
                <h6>
                  Phone:{" "}
                  <span className="fw-normal">
                    {program.owner.phoneNo? program.owner.phoneNo: <b style={{fontSize: '12px'}}>Unknown</b>}
                  </span>
                </h6>
                <h6>
                  Email:{" "}
                  <span className="fw-normal">
                    {program.owner.email? program.owner.email: <b style={{fontSize: '12px'}}>Unknown</b>}
                  </span>
                </h6>
              </>
            ): null
          }          
        </>
      )}
    </div>
  );
}

export default ProgramDeleteTemplate;
