/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

//modules and packages
import React, { useEffect, useState } from "react";
import { Space, Spin, Steps, Typography } from "antd";
import { useLocation } from "react-router-dom";

//media

//components
import PersonalDetails from "./PersonalDetails";
import CreatePassword from "./CreatePassword";
import { ProfileCreated } from "./ProfileCreated";
import AccountType from "./AccountType";
import { RedoOutlined } from "@ant-design/icons";

import AuthHeader from "../../Components/AuthHeader";
import { signupCleanup } from "../../Store/actions/signup";
import {
  cleanup,
  setReferralCode,
} from "../../Store/actions/signupProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";

const Description = (props: any) => (
  <Typography style={{ fontSize: "0.7rem", color: "black" }}>
    {props.title}
  </Typography>
);

const enum STAGES {
  ACCOUNT = "account",
  PROFILE = "profile",
  PASSWORD = "password",
  CREATED = "created",
  CREATING = "creating",
}

const Signup = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [percentage, setPercentage] = useState(10);
  const [currentStage, setCurrentStage] = useState(0);

  const { stage } = useAppSelector((s) => s.signupProgress);
  const signupState = useAppSelector((s) => s.signup);

  useEffect(() => {
    if (stage === STAGES.ACCOUNT) {
      setPercentage(75);
    } else {
      setPercentage(10);
    }

    const getStageNumber = (stage: STAGES) => {
      switch (stage) {
        case STAGES.PROFILE:
          setCurrentStage(1);
          return;

        case STAGES.PASSWORD:
          setCurrentStage(2);
          return;

        case STAGES.CREATED:
          setCurrentStage(3);
          return;
        case STAGES.CREATING:
          setCurrentStage(2);
          return;
        default:
          setCurrentStage(0);
      }
    };

    getStageNumber(stage);
  }, [stage]);

  // parse the search query and save referral-code if found
  useEffect(() => {
    const parseSearchQuery = () => {
      try {
        const searches = new URLSearchParams(location?.search);

        return searches.get("referral-code");
      } catch (error) {
        // TODO: handle error properly
        console.log("Error occured while parsing search query");
      }
    };

    const referralCode = parseSearchQuery();

    // save the refferal code if any
    if (referralCode) {
      dispatch(
        setReferralCode({
          referralCode,
          role: "customer",
          stage: STAGES.PROFILE,
        })
      );
    }
  }, [location?.search]);

  useEffect(() => {
    if (signupState.isSuccessful) {
      dispatch(signupCleanup());
    } else if (signupState.error) {
      dispatch(signupCleanup());
      dispatch(cleanup());
    }
  }, [signupState]);


  useEffect(()=>{

    return ()=>{
      dispatch(cleanup())
    }
  },[])
  return (
    <div className="signup__wrapper">
      <AuthHeader />

      <div className="">
        <Space align="center">
          <Steps
            responsive={true}
            status={stage === "created" ? "finish" : "process"}
            current={currentStage}
            percent={percentage}
            style={{ color: "black" }}
            className="signup_steps"
            items={[
              {
                title: "Select Account Type",
                description: (
                  <Description title="Business, Affiliate or Customer account" />
                ),
                style: { fontSize: "0.5em" },
              },
              {
                title: "Personal Details",
                description: (
                  <Description title="Account holder profile detail" />
                ),
              },
              {
                title: "Create Password",
                description: <Description title="Authenticate your account" />,
              },
            ]}
          />
        </Space>

        {/* content area */}
      </div>
      <div className="signup_content">
        {stage === "account" && <AccountType />}
        {stage === "profile" && <PersonalDetails />}
        {stage === "password" && <CreatePassword />}
        {stage === "created" && <ProfileCreated />}

        {/* loader */}
        {signupState?.isLoading && (
          <Space
            style={{
              display: "flex",
              height: "200px",
              placeContent: "center",
            }}
          >
            <Spin
              size="large"
              indicator={
                <RedoOutlined
                  style={{
                    fontSize: 30,
                  }}
                  spin
                />
              }
            />
          </Space>
        )}
      </div>
    </div>
  );
};

export default Signup;
