/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//MODULES AND PACKAGES
import { useState, useEffect } from "react";
import { message, Pagination, Modal, Button } from "antd";
import { QrReader } from "react-qr-reader";
import {QRCode} from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

//ACTIONS
import { scanCode, scanCodeCleanup } from "../../../Store/actions/scanCode";
import {
  redeemCode,
  redeemCodeCleanup,
} from "../../../Store/actions/redeemCode";
import {
  unredeemedCodes,
  unredeemedCodesCleanup,
} from "../../../Store/actions/unredeemedCodes";

//STYLES
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectLoggedInUserRole } from "../../../Store/actions/getMe";
import { formatCurrency } from "../../../Utils";


const downloadQRCode = () => {
  const canvas = document.getElementById('generator-container')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement('a');
    a.download = 'QRCode.png';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const UnredeemedCodes = () => {
  const dispatch = useAppDispatch();
  const scanCodeState = useAppSelector((state) => state.scanCode);
  const redeemCodeState = useAppSelector((state) => state.redeemCode);
  const unredeemedCodesState = useAppSelector((state) => state.unredeemedCodes);
  const currentRole = useAppSelector(selectLoggedInUserRole);

  const [result, setResult] = useState("");
  const [item, setItem] = useState<typeof scanCodeState.data.productID>(null);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [page] = useState(1);

  const [unredeemedList, setUnredeemedList] = useState<
    typeof unredeemedCodesState.data
  >({});

  const [currentItem, setCurrentItem] = useState<typeof unredeemedList.QRCodes>(
    []
  );

  // FOR SCANNING MODAL
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // FOR CODE GENERATION MODAL
  const [isGenModalVisible, setIsGenModalVisible] = useState(false);

  const showGenModal = () => {
    setIsGenModalVisible(true);
  };

  const genHandleOk = () => {
    setIsGenModalVisible(false);
  };

  const genHandleCancel = () => {
    setIsGenModalVisible(false);
  };

  const showCustomerModal = (data) => {
    showGenModal();
    setCurrentItem(data);
  };

  const handleScan = (data) => {
    if (!data) return;
    setResult(data);
  };

  const changePage = (page) => {
    dispatch(unredeemedCodes({ page: page, limit: 20 }));
  };

  const handleError = (error) => {
    message.error(error);
  };

  const handleQrReaderScanning = (result: any, error: any) => {
    if (result) handleScan(result);
    if (error) handleError(error);
  };

  const redeemScannedCode = () => {
    dispatch(redeemCode(result));
  };

  const reset = () => {
    setMsg("");
    setResult("");
    setItem(null);
  };

  useEffect(() => {
    dispatch(unredeemedCodes({ page: page, limit: 20 }));
  }, []);

  useEffect(() => {
    if (unredeemedCodesState.isSuccessful) {
      setUnredeemedList(unredeemedCodesState.data);
      dispatch(unredeemedCodesCleanup());
    } else if (unredeemedCodesState.error) {
      setUnredeemedList({});
      dispatch(unredeemedCodesCleanup());
    }
  }, [unredeemedCodesState]);

  useEffect(() => {
    if (result) {
      dispatch(scanCode(result));
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scanCodeState.isSuccessful) {
      console.log(scanCodeState.data);
      setItem(scanCodeState.data.productID);
      dispatch(scanCodeCleanup());
    } else if (scanCodeState.error) {
      message.error(scanCodeState.error);
      dispatch(scanCodeCleanup());
    }
  }, [scanCodeState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (redeemCodeState.isSuccessful) {
      setMsg(redeemCodeState.data);
      setMsgType("success");
      dispatch(unredeemedCodes({ page: page, limit: 20 }));
      dispatch(redeemCodeCleanup());
    } else if (redeemCodeState.error) {
      setMsg(redeemCodeState.error);
      setMsgType("warning");
      dispatch(redeemCodeCleanup());
    }
  }, [redeemCodeState]);

  return (
    <section className="unredeemed-code-container">
      <h1>Unredeemed codes</h1>

      <hr />

      <div>
        <p className="mt-0 pt-0 font-weight-bold">
          Total unredeemed items : {unredeemedList.totalQRCodes}
        </p>
      </div>

      {unredeemedList.QRCodes &&
      !!unredeemedList.QRCodes.length &&
      currentRole === "business" ? (
        <div>
          <p>Scan customer's Qr Codes</p>

          <div>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => {
                showModal();
              }}
            >
              Scan Code
            </button>
          </div>
        </div>
      ) : null}

      {/* QR READER MODAL  */}
      {isModalVisible && (
        <Modal
          className="qr-modal"
          title="Scan Item"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="scanner-body">
            {!result ? (
              <>
                <QrReader
                  scanDelay={300}
                  onResult={handleQrReaderScanning}
                  constraints={{ facingMode: "user" }}
                  className="qr-reader"
                />

                <p className="mt-3">
                  Kindly place the QR Code inside the area to claim your coupon.
                  Scanning will start automatically.
                </p>
              </>
            ) : null}
          </div>

          <div>
            {!item && scanCodeState.isLoading && (
              <div className="text-center py-5">
                <LoadingOutlined style={{ fontSize: 30 }} spin />
              </div>
            )}
            {item && (
              <div className="scanned-result">
                <div className="result-img-container">
                  <img
                    className="product-img"
                    src={item.avatar ? item.avatar.secure_url : ""}
                    alt={item.title}
                  />
                </div>

                <div className="result-details">
                  <h4>{item.title}</h4>

                  <p>
                    Brand: <span>{item.brand}</span>
                  </p>
                  <p>
                    Type: <span>{item.productType}</span>
                  </p>
                  <p>
                    Category: <span>{item.category}</span>
                  </p>

                  <p>
                    Selling price: <span>${item.sellingPrice}</span>
                  </p>
                  <p>
                    Discount: <span> ${item.discount} </span>
                  </p>

                  <h5>Description</h5>
                  <p id="description">{item.description}</p>
                </div>
              </div>
            )}
            <div
              className="buttons"
              style={{ display: result ? "flex" : "none" }}
            >
              <button
                className="btn btn-danger mt-5 mb-2"
                onClick={redeemScannedCode}
                disabled={!result}
              >
                Redeem item
              </button>
              <button
                className="btn btn-secondary mt-5 mb-2 d-flex align-items-center"
                onClick={reset}
                disabled={!result}
                style={{ gap: 3 }}
              >
                <DeleteOutlined /> Reset
              </button>
            </div>
            {msg && (
              <div
                className={"alert alert-" + msgType}
                style={{ padding: ".5rem 1rem" }}
              >
                {msg}
              </div>
            )}
          </div>
        </Modal>
      )}

      {/* QRCODE GENERATOR MODAL FOR CUSTOMER */}
      <Modal
        title="Item Code"
        open={isGenModalVisible}
        onOk={genHandleOk}
        onCancel={genHandleCancel}
        footer={null}
      >
        <div id="generator-container flex col"> 
          <QRCode value={currentItem._id} />
          <Button className="mt-4" type="primary" onClick={downloadQRCode}>
      Download
    </Button>
        </div>

        <div className="mt-4">
          <h5>Seller's Information</h5>
          <hr />

          {currentItem.seller && (
            <>
              <h6 className="mb-0">Business Name:</h6>
              <p>{currentItem.seller.businessName}</p>

              <h6 className="mb-0">Address:</h6>
              <p>{currentItem.seller.address}</p>

              <h6 className="mb-0">State:</h6>
              <p>{currentItem.seller.state}</p>

              <h6 className="mb-0">City:</h6>
              <p>{currentItem.seller.city}</p>

              <h6 className="mb-0">Phone number:</h6>
              <p>{currentItem.seller.phoneNo}</p>

              <h6 className="mb-0">Email:</h6>
              <p>{currentItem.seller.email}</p>
            </>
          )}
        </div>
      </Modal>

      {/* TABLE  */}
      {unredeemedCodesState.isLoading ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <div className="table-responsive mt-4">
          <table className="table borderless mb-5">
            {unredeemedList.QRCodes &&
            unredeemedList.QRCodes.length === 0 ? null : (
              <thead>
                <tr>
                  <th scope="col" className="col-1">
                    No.
                  </th>

                  <th scope="col" className="col-2">
                    {currentRole === "business"
                      ? "Buyer Email"
                      : "Seller Email"}
                  </th>

                  <th scope="col" className="col-1">
                    Item
                  </th>

                  <th scope="col" className="col-2">
                    Amount to be paid
                  </th>

                  <th scope="col" className="col-3">
                    Address
                  </th>

                  <th scope="col" className="col-1">
                    City
                  </th>

                  <th scope="col" className="col-1">
                    State
                  </th>

                  {currentRole === "business" ? null : (
                    <th scope="col" className="col-2">
                      Action
                    </th>
                  )}
                </tr>
              </thead>
            )}

            {unredeemedList.QRCodes &&
              unredeemedList.QRCodes.map((item, index) => (
                <tbody key={item._id}>
                  <tr>
                    <th scope="row">{index + 1}</th>

                    {currentRole === "customer" ? (
                      <td style={{ fontSize: "13px" }} className="text-break">
                        {item.seller.email}
                      </td>
                    ) : (
                      <td style={{ fontSize: "13px" }} className="text-break lowercase">
                        {item.customerID.email}
                      </td>
                    )}

                    <td style={{ fontSize: "13px" }} className="text-break">
                      {" "}
                      {item.productID?.title || " -"}
                    </td>

                    <td style={{ fontSize: "13px" }} className="text-break">
                      {formatCurrency(item.productID?.sellingPrice) || " -"}
                    </td>

                    <td style={{ fontSize: "13px" }} className="text-break">
                      {item.seller.address}
                    </td>

                    <td style={{ fontSize: "13px" }} className="text-break">
                      {item.seller.city}
                    </td>

                    <td style={{ fontSize: "13px" }} className="text-break">
                      {item.seller.state}
                    </td>

                    {currentRole === "business" ? null : (
                      <td style={{ fontSize: "13px" }} className="text-break">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => showCustomerModal(item)}
                        >
                          Generate
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      )}

      <div className="pb-5 pt-4 w-max">
        <Pagination
          onChange={changePage}
          total={unredeemedList.totalQRCodes || 0}
          hideOnSinglePage={true}
          pageSize={20}
          defaultCurrent={1}
          responsive={true}
          className="d-flex justify-content-end"
        />
      </div>
    </section>
  );
};

export default UnredeemedCodes;
