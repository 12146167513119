import { ChangeEventHandler, useState } from "react";

import "./input.css";

type InputFieldPropTypes = {
  isRequired?: boolean;
  name: string;
  label: string;
  type?: "text" | "password" | "email" | "number";
  dir?: "horizontal" | "vertical";
  placeholder?: string;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
  onChange: ChangeEventHandler;
};

function InputField(props: InputFieldPropTypes) {
  const [showPassword, setShowPassword] = useState<"hide" | "show">("show");

  const toggleShowPassword = () =>
    setShowPassword((prev) => (prev === "hide" ? "show" : "hide"));

  const getDirStyle = (dir: "horizontal" | "vertical") =>
    dir === "horizontal" ? "input_field__wrapper--horizontal" : "";

  const getInputType = (type: any) => {
    if (type === "password" && showPassword === "hide") {
      return "text";
    } else return type;
  };

  return (
    <div
      className={`input_field__wrapper ${getDirStyle(
        props?.dir ?? "vertical"
      )}`}
    >
      {props?.label ? (
        <label className="input_field__label" htmlFor={props.name}>
          {props.label}
          {props?.isRequired ? (
            <>
              &ensp;<b className="is_required">*</b>
            </>
          ) : null}
        </label>
      ) : null}

      <div
        className={`input__wrapper ${
          props.type === "password" ? "password__wrapper" : ""
        }`}
      >
        <input
          className={`input_field ${props?.error ? "input_field--error" : ""}`}
          type={getInputType(props.type) ?? "text"}
          placeholder={props?.placeholder}
          name={props.name}
          defaultValue={props?.defaultValue}
          onChange={props.onChange}
          readOnly={props?.readOnly}
        />

        <button
          type="button"
          className="show__password"
          onClick={toggleShowPassword}
        >
          {showPassword}
        </button>
      </div>
      {props?.error ? <p className="error_message">{props?.error}</p> : null}
    </div>
  );
}

export default InputField;
