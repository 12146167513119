/* eslint-disable import/no-anonymous-default-export */
export default {
  primary: "lightblue",
  main: "rgba(13, 13, 214, 0.863)",
  white: "#fff",
  danger: "#FF4C4F",
  grey: "rgba(0,0,0,0.1)",
  twitter: "#00ACEE",
  facebook: "#1778f2",
};

// more colors to come when the ui is ready
