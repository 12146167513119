import { Empty } from "antd";
import { Link } from "react-router-dom";
//
import { Button } from "../../../../Components/ui/Button";

export const EmptyCart = () => (
  <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{ height: 60 }}
    description={
      <span>
        Your cart is empty. <br />
      </span>
    }
  >
    <Link to="/programs-list">
      <Button type="primary" className="text-primary">
        Go to VIP Programs
      </Button>
    </Link>
  </Empty>
);
