const Privacy: React.FC = () => {
  return (
    <div>
      <h6
        className="s1"
        style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
      >
        Affordable Advertising Solutions, LLC dba SaveNWin.com Privacy Policy
        Notice. Please read below to understand your rights and how we use the
        information we gather. Effective Date: 5/4/2021.
      </h6>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        This Privacy Policy Notice (“Notice”) applies to this website or mobile
        application and the websites or mobile applications of Affordable
        Advertising Solutions, LLC dba SaveNWin.com (SaveNWin.com), and its
        subsidiaries and affiliates (collectively “we”,
      </p>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        “us” or “our”) that display this Notice (each a “Site” and collectively,
        the “Sites”). By visiting the Sites, you are consenting to our
        collection, use, disclosure, retention, and protection of information
        about you and devices you use as described in this Notice.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        This Notice only applies to the Sites and does not apply to information
        collected or received by other means. Our Sites are not directed to
        children under the age of 13. We do not knowingly collect personal
        information from children under age 13. This Notice does not apply to
        anonymized or aggregated data that does not allow us or third parties to
        identify or contact you.
      </p>
      <p
        style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}
      />
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }} />
      <ol id="l1">
        <li data-list-text={1}>
          <p
            className="s1"
            style={{
              paddingTop: "4pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            What Information Does SaveNWin.com Gather?
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Information You Give Us.
            <span className="s1"></span>
            <span className="p">
              We may collect and retain any information from you or your devices
              provided to us when you visit a Site, including when you:
            </span>
          </p>
          <ul id="l2">
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                use our service or mobile application;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                register for an account;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                communicate with us, such as to provide feedback, request
                support, or ask for additional information; and
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                subscribe to content we offer, such as newsletters, alerts, etc.
              </p>
              <p
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                We may collect information such as your first and last name,
                home or other physical address, email address, zip code,
                telephone number, your automobile’s vehicle identification
                number (“VIN”), license plate number (if transaction is related
                to the sale or purchase of any motorized vehicle), or
                information about your interactions with us or others, and other
                information.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Social Media.
                <span className="s1"></span>
                <span className="p">
                  You may use social networks or other online services to access
                  our Sites. When you do so, information from those services may
                  be made available to us. By associating a social network
                  account with our Sites, you agree that we may access and
                  retain that information in accordance with the policies of the
                  social network or other online service and this Notice. For
                  example, we may be able to access account or profile
                  information that you have provided to the social network or
                  information about your interactions with the social network to
                  make information available to us (such as when commenting on a
                  blog post or using a sign-on service, such as Facebook
                  Connect).
                </span>
              </p>
              <p
                className="s2"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Automatically Collected Information.
                <span className="s1"></span>
                <span className="p">
                  We may collect information automatically when you visit our
                  Sites or use our mobile applications, such as:
                </span>
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                your IP address;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                the type of browser, devices and operating systems you use;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                identifiers associated with the device(s) you use to access our
                Sites;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                the pages you visit, vehicles you view or configure, and the
                features you use, including dates and times;
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                if you navigated from or navigate to another website, the
                address of that website; and
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s1"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                information regarding your internet service provider.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }} />
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                In addition, we may collect information about your activities on
                our Sites through the use of cookies, clear GIFs or web beacons,
                local shared objects or Flash cookies, or through other
                identifiers or technologies, including similar technologies as
                they may evolve over time. We refer to these technologies
                collectively as Metrics Tools.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                We may allow third parties to use Metrics Tools on our Sites.
                The information collected by Metrics Tools may be shared with
                and used by us, by others acting on our behalf, or by third
                parties subject to their own privacy policies. Information
                collected by Metrics Tools may be used on this Site or on other
                websites or services, including those that may not be operated
                by us.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Email.
                <span className="s1"></span>
                <span className="p">
                  We may collect information regarding the effectiveness of our
                  email and other communications with you. For example, we may
                  know if you follow a link in an email we send to you.
                </span>
              </p>
              <p
                className="s2"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Mobile.
                <span className="s1"></span>
                <span className="p">
                  We may collect session and geolocation information from your
                  mobile device. Geolocation information includes data such as
                  your device’s physical location and may include GPS-based,
                  WiFi-based or cell-based location information.
                </span>
              </p>
              <p
                className="s2"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Information from Other Sources.
                <span className="s1"></span>
                <span className="p">
                  We may obtain information about you from affiliates, partners,
                  automobile dealers and other third parties. This information
                  may include information about your use of this Site or our
                  services, your use of other websites, your interactions with
                  or purchases from automobile dealers, your interests and
                  preferences and other information about you or your household.
                  We may combine the information we obtain from third parties
                  with information that we or our affiliates have collected
                  about you.
                </span>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text={2}>
          <p
            className="s1"
            style={{
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            How Does SaveNWin.com Use Information About Me?
          </p>
          <p
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            We and others acting on our behalf may use the information that we
            collect or receive to operate our business, including our Sites, as
            described below:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "4pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Operate and Support our Sites and Services.
            <span className="s1"></span>
            <span className="p">
              We use the information that we gather in order to operate our
              Sites and our services. For example, we may use the information
              that we collect or receive to provide support and assistance that
              you request or to diagnose or address technical problems in the
              operation of our Sites or any of our services. If you establish an
              account with us, we may use information about you to manage or
              support your account. We may identify your use of our Sites across
              devices, and we may use information we collect from you and
              receive from others to optimize your use of the Sites and our
              services as you use different devices.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Improving and Evolving our Services.
            <span className="s1"></span>
            <span className="p">
              We constantly evaluate and improve our Sites and services,
              including developing new products or services and use the
              information we gather to do so.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            To Allow You to Connect with Dealers.
            <span className="s1"></span>
            <span className="p">
              When you use our service, and after you provide your contact
              information in order to request offers or information, we may use
              the information you provide us to allow you to connect with a
              limited number of automobile dealers. These dealers may provide
              you with a savings certificate and/or offers for new or used
              vehicles or other products or services. We may also notify a
              dealer when you are at the dealer’s location via geolocation
              information that we collect. When you choose to share your contact
              information with dealers, your direct interactions with those
              dealers
            </span>
          </p>
          <p
            style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            will not be controlled by this Notice. You should review the
            applicable notices of those dealers to understand how they may use
            your information.
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            Advertising and Promotions.
            <span className="s1"></span>
            <span className="p">
              We may use the information we gather to offer, provide, or
              personalize products and services from us and third parties. For
              example, we may customize content, advertising, promotions and
              incentives to reflect your preferences, interests, or prior
              interactions with us and others.
            </span>
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            Other Contacts.
            <span className="s1"></span>
            <span className="p">
              We may contact you through telephone, text, or chat for other
              purposes, as permitted by law.
            </span>
          </p>
          <p
            style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            This Notice only addresses our own information practices. This
            Notice does not apply to information you share with third parties,
            including but not limited to dealers, even if we link to those third
            parties from a Site. These third parties may have their own privacy
            policies governing their use of information that you can access from
            their websites. Our services may be offered through third party
            websites or mobile applications (“Partner Sites”). This Notice does
            not apply to Partner Sites, unless otherwise indicated on the
            Partner Site.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            Please note that other parties may collect personally identifiable
            information about your online activities over time and across
            different websites when you use our Site.
          </p>
          <p
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          />
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }} />
        </li>
        <li data-list-text={3}>
          <p
            className="s1"
            style={{
              paddingTop: "4pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            When Does SaveNWin.com Share Information?
          </p>
          <p
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            We only share information about you as described or permitted by
            this Notice, unless you consent to other sharing. We may share
            information about you in the following ways:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "4pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Corporate Affiliates.
            <span className="s1"></span>
            <span className="p">
              We may share information with our corporate affiliates for their
              everyday business purposes, to provide services or to perform
              marketing. We may also participate in joint ventures with others
              and we may share information as part of that joint venture.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Partner Sites.
            <span className="s1"></span>
            <span className="p">
              We may share the information we gather with Partner Sites (and the
              owners of those sites). For example, we may share information for
              the purpose of determining or verifying our economic rights and
              obligations.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Service Providers.
            <span className="s1"></span>
            <span className="p">
              We may share information with third party service providers who
              help us operate our business or provide services on our behalf.
              These service providers include analytics companies and
              advertising networks that provide Metrics Tools on our Sites.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Promotional.
            <span className="s1"></span>
            <span className="p">
              We may share information with third parties to help us promote our
              products and services, or to help us customize advertisements,
              offers, or other communications to you.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Automobile Dealers and Manufacturers.
            <span className="s1"></span>
            <span className="p">
              We may share information with automobile dealers, manufacturers
              and other providers of automobile related products and services.
              For example, we may share information with our participating
              dealers to allow you to communicate with them, and them to
              communicate with you.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Business Transfer.
            <span className="s1"></span>
            <span className="p">
              We may share information we have collected from you in connection
              with the sale or merger of our business or the transfer of assets.
            </span>
          </p>
          <p
            className="s2"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Protection of Ourselves and Others.
            <span className="s1"></span>
            <span className="p">
              We may use and share the information we gather to enforce our
              rights and protect our property and those of our affiliates,
              customers and members of the public, to comply with applicable
              law, legal process, legal advice and for preventing fraud, theft,
              and injury to you, us or others.
            </span>
          </p>
        </li>
        <li data-list-text={4}>
          <p
            className="s1"
            style={{
              paddingLeft: "25pt",
              textIndent: "-18pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            What Choices Do I Have Regarding My Information?
          </p>
        </li>
      </ol>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        You may limit and control the information provided to us in a number of
        ways. You may not be able to use all features of our Sites if you limit
        the information you share with us.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        For example, you can choose not to access our Sites through your social
        media account. You may also be able to limit the information provided to
        us by third party social media providers by altering your privacy
        settings with those providers. You may unsubscribe from promotional
        emails from us by following the unsubscribe link included in each such
        email.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "1pt", textIndent: "0pt", textAlign: "left" }}>
        Your browser and your device may provide you the option to limit the use
        of cookies or other Metrics Tools. You should consult documentation for
        your browser or device for more information. Your mobile device may have
        settings that allow you to prevent sharing geolocation information with
        us. You should consult your mobile device’s settings or help
        documentation for more information about exercising these options.
      </p>
      <p
        style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}
      />
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l3">
        <li data-list-text={5}>
          <p
            className="s1"
            style={{
              paddingLeft: "19pt",
              textIndent: "-18pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            How Can I Access and Update My Information?
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "19pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            If you are a registered user on SaveNWin.com, you may access, update
            and change certain information we have collected about you by
            accessing the “
            <a
              href="https://savenwin.com/my-account"
              className="s3"
              target="__blank"
            >
              My Account
            </a>
            ” tab after signing into your account on{" "}
            <a href="http://www.SaveNWin.com/" className="s3" target="__blank">
              www.SaveNWin.com.
            </a>
          </p>
        </li>
        <li data-list-text={6}>
          <p
            className="s1"
            style={{
              paddingLeft: "19pt",
              textIndent: "-18pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            How Does SaveNWin.com Address California Privacy Rights?
          </p>
        </li>
      </ol>
      <p
        className="s4"
        style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "left" }}
      >
        California Civil Code Section 1798.83 entitles California users to
        request information concerning the disclosure of certain personal
        information to third parties for the third parties’ direct marketing
        purposes. California users who wish to request further information in
        compliance with this law may contact us as specified in the “How to{" "}
        <a href="https://savenwin.com/contact" className="a" target="__blank">
          Contact Us
        </a>
        <span className="p">” section below.</span>
      </p>
      <p style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "left" }}>
        As of the Effective Date listed above, there is no commonly accepted
        response for Do Not Track signals initiated by browsers. Therefore, we
        do not respond to such signals or to other mechanisms that provide the
        ability to exercise choice regarding the collection of personally
        identifiable information regarding your online activities over time and
        across third party websites or online services.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }} />
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s5"
        style={{
          paddingLeft: "1pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
        }}
      >
        7.
        <span style={{ color: "#000" }}>
          How Does SaveNWin.com Handle Privacy Disputes?
        </span>
      </p>
      <p style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "left" }}>
        By using this Site, you agree that any dispute arising out of or
        relating to the Sites, the Sites’ content or the services or materials
        made available on the Sites, or regarding information collected or
        shared about you, is subject to the Choice of Law, Venue, and Class
        Action Waiver provisions in our Terms of Service.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }} />
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l4">
        <li data-list-text={8}>
          <p
            className="s1"
            style={{
              paddingLeft: "19pt",
              textIndent: "-18pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            How Does SaveNWin.com Protect the Security of the Personal
            Information it Collects?
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "19pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            We use administrative, technical, and physical security designed to
            safeguard personal information in our possession. We cannot
            guarantee the security of the information that we collect and store.
            If you believe that information about you has been subject to
            unauthorized disclosure, please let us know by emailing{" "}
            <a
              href="mailto:support@savenwin.com"
              className="s3"
              target="__blank"
            >
              support@SaveNWin.com
            </a>
            <span className="p">.</span>
          </p>
        </li>
        <li data-list-text={9}>
          <p
            className="s1"
            style={{
              paddingLeft: "19pt",
              textIndent: "-18pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            How Does SaveNWin.com Provide Updates to this Notice?
          </p>
        </li>
      </ol>
      <p style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "left" }}>
        When we make material changes to this Notice, we will update this web
        page and change the Effective Date listed above.
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }} />
      <p
        className="s5"
        style={{
          paddingLeft: "1pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
        }}
      >
        10.
        <span style={{ color: "#000" }}>
          How Can SaveNWin.com be Contacted Regarding Privacy Issues?
        </span>
      </p>
      <p
        className="s4"
        style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "left" }}
      >
        <a href="mailto:support@SaveNWin.com" className="s3" target="__blank">
          You can contact us with questions or comments about our privacy
          practices or this Notice by emailing us at{" "}
        </a>
        support@SaveNWin.com
        <span className="p">or you can contact us at:</span>
      </p>
      <p
        style={{
          paddingLeft: "19pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
        }}
      >
        Affordable Advertising Solutions, LLC dba SaveNWin.com
      </p>
      <p
        style={{
          paddingLeft: "19pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
        }}
      >
        P.O. Box 28437
      </p>
      <p
        style={{
          paddingLeft: "19pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
        }}
      >
        Richmond, VA 23228
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }} />
      <h6 style={{ paddingLeft: "25pt", textIndent: "0pt", textAlign: "left" }}>
        Personal Data collected for the following purposes and using the
        following services:
      </h6>
      <ul id="l5">
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Access to third party services' accounts
          </p>
          <ul id="l6">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Access to the Facebook account
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Permissions: Access Requests, App Notifications, Manage
                Advertisements and Status
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Access to the Twitter account and Access to the Google Drive
                account
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
              <ul id="l7">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Advertising
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Google AdSense
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Personal Data: Cookies and Usage data
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Analytics
          </p>
          <ul id="l8">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "123%",
                  textAlign: "left",
                }}
              >
                Google Analytics, Google Tag Manager, Google Analytics with
                anonymized IP, Wordpress Stats, Facebook Ads conversion tracking
                and Google AdWords conversion tracking
              </h6>
              <p
                className="s7"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: Cookies and Usage data
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                User ID extension for Google Analytics
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: Cookies
              </p>
              <ul id="l9">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Backup saving and management
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Backup on Google Drive
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Personal Data: various types of Data as specified in the privacy
            policy of the service
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Contacting the User
          </p>
          <ul id="l10">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Mailing List or Newsletter
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: city, country, email address, first name, last
                name, state and Usage data
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Contact form
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  lineHeight: "123%",
                  textAlign: "left",
                }}
              >
                Personal Data: country, email address, first name, last name and
                various types of Data as specified in the privacy policy of the
                service
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Phone contact
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: phone number
              </p>
              <ul id="l11">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Content commenting
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Comment system managed directly
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: Cookies, email address, first name, last name,
                Usage data and username
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Facebook Comments
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Personal Data: Cookies and Usage data
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Content performance and features testing (A/B testing)
          </p>
          <ul id="l12">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Google Website Optimizer
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Personal Data: Cookies and Usage data
              </p>
              <ul id="l13">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Displaying content from external platforms
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Google Maps widget, YouTube video widget, Vimeo video and
                Instagram widget
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Personal Data: Cookies and Usage data
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "3pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Handling payments
          </p>
          <ul id="l14">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                PayPal, Stripe and/or Google Wallet
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
              <ul id="l15">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    Hosting and backend infrastructure
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Google Cloud Storage and Amazon Web Services
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Personal Data: various types of Data as specified in the privacy
            policy of the service
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Interaction with external social networks and platforms
          </p>
          <ul id="l16">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "124%",
                  textAlign: "justify",
                }}
              >
                Google+ +1 button and social widgets, Facebook Like button and
                social widgets, Google Friend Connect, Twitter Tweet button and
                social widgets, Tumblr sharing button and social widgets,
                ShareThis, Pinterest “Pin it” button and social widgets,
                LinkedIn button and social widgets, Socialize and AddThis
              </h6>
              <p
                className="s7"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: Cookies and Usage data
              </p>
              <ul id="l17">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    Interaction with live chat platforms
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                LiveChat Widget and My LiveChat Widget
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Personal Data: Cookies and Usage data
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Interaction with online survey platforms
          </p>
          <ul id="l18">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                SurveyMonkey Widget
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: Cookies and Usage data
              </p>
              <ul id="l19">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    Location-based interactions
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Geolocation and Non-continuous geolocation
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Personal Data: geographic position
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Managing contacts and sending messages
          </p>
          <ul id="l20">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                MailChimp
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: email address
              </p>
              <ul id="l21">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    Registration and authentication
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  lineHeight: "124%",
                  textAlign: "justify",
                }}
              >
                Facebook Authentication, Foursquare OAuth, Google OAuth,
                Linkedin OAuth, Meetup.com OAuth, Twitter OAuth, Instagram
                Authentication and Login with Amazon
              </h6>
              <p
                className="s7"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: various types of Data as specified in the privacy
                policy of the service
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Direct registration
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  lineHeight: "124%",
                  textAlign: "justify",
                }}
              >
                Personal Data: country, email address, first name, last name,
                password, username and various types of Data as specified in the
                privacy policy of the service
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  lineHeight: "11pt",
                  textAlign: "justify",
                }}
              >
                Twitter Digits
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Personal Data: phone number
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Remarketing and behavioral targeting
          </p>
          <ul id="l22">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  lineHeight: "123%",
                  textAlign: "justify",
                }}
              >
                AdWords Remarketing, Facebook Remarketing, Twitter Remarketing,
                Remarketing through Google Analytics for Display Advertising and
                DoubleClick for Publishers Audience Extension
              </h6>
              <p
                className="s7"
                style={{
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: Cookies and Usage data
              </p>
              <ul id="l23">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    RSS feed management
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Feedburner
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Personal Data: Cookies and Usage data
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Social features
          </p>
          <ul id="l24">
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Inviting and suggesting friends
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: Various types of Data
              </p>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Public profile
              </h6>
              <p
                className="s7"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "25pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Personal Data: city, country, geographic position and username
              </p>
              <ul id="l25">
                <li data-list-text="o">
                  <p
                    className="s6"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "36pt",
                      textIndent: "-18pt",
                      textAlign: "justify",
                    }}
                  >
                    SPAM protection
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <h6
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "36pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Akismet, WordFence, AntiSpam by CleanTalk and VERSCaptcha
              </h6>
            </li>
          </ul>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Personal Data: various types of Data as specified in the privacy
            policy of the service
          </p>
          <h6
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Further information about Personal Data
          </h6>
          <p
            className="s8"
            style={{
              paddingTop: "3pt",
              paddingLeft: "7pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "3pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            The Service is not directed to children under the age of 13
          </p>
          <p
            className="s7"
            style={{
              paddingTop: "1pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              lineHeight: "124%",
              textAlign: "left",
            }}
          >
            Users declare themselves to be adult according to their applicable
            legislation. Minors may use this Application only with the
            assistance of a parent or guardian. Under no circumstance persons
            under the age of 13 may use this Application.
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingLeft: "25pt",
              textIndent: "-18pt",
              lineHeight: "12pt",
              textAlign: "left",
            }}
          >
            Push notifications
          </p>
          <p
            className="s7"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            This Application may send push notifications to the User.
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "2pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Selling goods and services online
          </p>
          <p
            className="s7"
            style={{
              paddingTop: "1pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              lineHeight: "124%",
              textAlign: "left",
            }}
          >
            The Personal Data collected are used to provide the User with
            services or to sell goods, including payment and possible delivery.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s7"
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              lineHeight: "124%",
              textAlign: "left",
            }}
          >
            The Personal Data collected to complete the payment may include the
            credit card, the bank account used for the transfer, or any other
            means of payment envisaged. The kind of Data collected by this
            Application depends on the payment system used.
          </p>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingLeft: "25pt",
              textIndent: "-18pt",
              lineHeight: "12pt",
              textAlign: "left",
            }}
          >
            Unique device identification
          </p>
          <p
            className="s7"
            style={{
              paddingTop: "1pt",
              paddingLeft: "25pt",
              textIndent: "0pt",
              lineHeight: "123%",
              textAlign: "left",
            }}
          >
            This Application may track Users by storing a unique identifier of
            their device, for analytics purposes or for storing Users'
            preferences.
          </p>
          <h6
            style={{
              paddingLeft: "25pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left",
            }}
          >
            Contact information
          </h6>
        </li>
        <li data-list-text="o">
          <p
            className="s6"
            style={{
              paddingTop: "3pt",
              paddingLeft: "25pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Data owner
          </p>
        </li>
      </ul>
      <p
        className="s7"
        style={{
          paddingTop: "1pt",
          paddingLeft: "25pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        AFFORDABLE ADVERTISING SOLUTIONS, LLC – PO BOX 28437, Richmond, VA
        232228
      </p>
      <p
        style={{
          paddingTop: "2pt",
          paddingLeft: "25pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        Owner contact email:
        <a href="mailto:support@savenwin.com" className="s10" target="__blank">
          {" "}
          support@savenwin.com
        </a>
      </p>
    </div>
  );
};

export default Privacy;
