import InputField from "../../../../Components/ui/form/Input";
import Select from "../../../../Components/ui/form/Select";
import { getCitiesByState, getStates } from "./search.utils";

type ProgramSearchType = {
  form: Record<string, string>;
  onChange(e: any): void;
  resetFields(fields: string[]): void;
};

export const ProgramSearch = (props: ProgramSearchType) => {
  const { onChange, form, resetFields } = props;

  const onChangeState = (e: any) => {
    // reset the city field on the form
    resetFields(["city"]);
    onChange(e);
  };

  return (
    <>
      <Select
        name="state"
        placeholder="State"
        options={getStates()}
        // defaultValue={form?.view}
        onChange={onChangeState}
      />

      <Select
        name="city"
        placeholder="Arizhona"
        options={getCitiesByState(form.state)}
        // defaultValue={form?.view}
        onChange={onChange}
      />

      <InputField
        label=""
        name="businessName"
        placeholder="Business name"
        onChange={onChange}
      />

      <InputField
        label=""
        name="programName"
        placeholder="Program name"
        onChange={onChange}
      />
    </>
  );
};
