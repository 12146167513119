//styles
import "./index.css";
import { useHistory } from "react-router-dom";

import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Switch,
  Typography,
} from "antd";
import { useState } from "react";
import { RoleType } from "../../../../interfaces";
import { useForm } from "antd/lib/form/Form";
import { addBusinessRoleApi } from "../../../../api/users/roles";
import ErrorHandler from "../../../../Utils/error-handler";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 32 },
};

const minorityOptions = [
  // { value: "female", label: "Female" },
  { value: "asian-pacific", label: "Asian-pacific" },
  { value: "asian-indian", label: "Asian-indian" },
  { value: " native american", label: " Native American" },
  { value: "hispanic", label: "Hispanic" },
  { value: "veteran", label: "Veteran owned" },
  { value: "LGBTQ+", label: "LGBTQ+" },
];

const AddBusinessRole = () => {
  const [form] = useForm();
  const history = useHistory();
  const { resetFields } = form;

  const [newRole, setNewRole] = useState<RoleType>("business");
  const [isLoading, setIsLoading] = useState(false);
  const [isMinority, setIsMinority] = useState(false);
  const [alertMsg, setAlertMsg] = useState<{
    text: string;
    type: "success" | "error" | "info";
  } | null>();

  const roles = [
    // { label: "Affiliate", value: "affiliate", disabled: true },
    { label: "Business", value: "business" },
    // { label: "Customer", value: "customer", disabled: true },
  ];

  const handleRoleChange = (e: RadioChangeEvent) => {
    setNewRole(e.target.value);
    // clear form when role changes
    // this ensures that the role you enter data for is the one that get submitted
    resetFields();
  };

  const handleOnSubmit = (payload: any) => {
    if (isLoading) return;
    if (newRole === "affiliate") {
      // handle Affiliate role updates
    }

    if (newRole === "business") {
      const data = {
        ...payload,
        minority: isMinority,
        minority_group: isMinority ? payload.minority_group : [],
      };
      // handle Business role updates
      setIsLoading(true);

      addBusinessRoleApi(data)
        .then((response) => {
          console.log(
            "🚀 ~ file: index.tsx:63 ~ handleOnSubmit ~ response:",
            response
          );

          if (response.status < 300) {
            setAlertMsg({
              text: "Business role was successfully added",
              type: "success",
            });

            setTimeout(() => {
              history.push("/account");
            }, 3000);
          }
        })
        .catch((err) => {
          const error = ErrorHandler(err);

          setAlertMsg({
            type: "error",
            text: error ?? "Failed to add business role",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setTimeout(() => {
            setAlertMsg(null);
          }, 5000);
        });
    }

    if (newRole === "customer") {
      // handle Customer role updates
    }
  };

  return (
    <section
      className="new_role"
      style={{
        padding: "10px",
        maxHeight: "calc(100dvh - 100px)",
        overflowY: "auto",
      }}
    >
      {!!alertMsg ? (
        <div
          style={{
            maxWidth: 500,
            textAlign: "center",
            marginInline: "auto",
            marginBlock: "10px",
          }}
        >
          <Alert
            banner
            closable
            message={alertMsg?.text}
            type={alertMsg?.type}
          />
        </div>
      ) : null}

      <div style={{ marginInline: "auto", width: "fit-content" }}>
        <Typography.Title>Business Role</Typography.Title>
        {/* <Radio.Group
          className="mt-2"
          optionType="button"
          buttonStyle="solid"
          options={roles}
          onChange={handleRoleChange}
          value={newRole}
        /> */}
      </div>

      <Form
        {...layout}
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={handleOnSubmit}
        className="update_role_form"
        style={{
          maxWidth: 500,
          marginInline: "auto",
          marginTop: "50px",
          marginBottom: "70px",
          padding: "30px",
          border: `${!!alertMsg ? "0.25px solid red" : "0.15px solid #fdfeff"}`,
          boxShadow: "5px 8px 24px 5px rgba (208, 216, 243, 0.6)",
          backgroundColor: "#fdfeff",
          borderRadius: "10px",
          overflowY: "auto",
        }}
      >
        {newRole === "business" ? (
          <>
            <Form.Item
              name="businessName"
              label="Business Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Business Name" name="businessName" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Business Address"
              rules={[{ required: true }]}
            >
              <Input placeholder="123, Joyce street" name="address" />
            </Form.Item>

            <Form.Item name="city" label="City" rules={[{ required: true }]}>
              <Input placeholder="New York City" name="city" />
            </Form.Item>

            <Form.Item name="state" label="State" rules={[{ required: true }]}>
              <Input placeholder="New York" name="state" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true }]}
            >
              <Input placeholder="United State" name="country" />
            </Form.Item>
            <Form.Item
              name="phoneNo"
              label="Phone Number"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="+123-456-7899"
                name="phoneNo"
                type="telephone"
              />
            </Form.Item>

            <Form.Item
              name="postalCode"
              label="Postal Code"
              rules={[{ required: true }]}
            >
              <Input placeholder="89877" name="postalCode" />
            </Form.Item>

            <Form.Item
              name="minority"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch
                checkedChildren="Minority"
                unCheckedChildren="Majority"
                onChange={(_isMinority) => setIsMinority(_isMinority)}
              />
            </Form.Item>

            {isMinority ? (
              <Form.Item
                name="minority_group"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox.Group
                  options={minorityOptions}
                  name="minority_group"
                />
              </Form.Item>
            ) : null}
          </>
        ) : null}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            aria-disabled={isLoading}
            style={{
              width: "100%",
              marginInline: "auto",
              fontWeight: "700",
              padding: "5px 15px",
            }}
          >
            Add New Role
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default AddBusinessRole;
