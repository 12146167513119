/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

//modules and packages
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

//actions
import { programsList, programsCleanup } from "../../../Store/actions/programs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ProgramType } from "../../../types";
import ProgramList from "../../../Components/ui/programs/List";
import { useGetCartItems } from "../../../hooks/cart";

const PopularPrograms = () => {
  const dispatch = useAppDispatch();
  const [cart] = useGetCartItems();

  const programState = useAppSelector((s) => s.programs);

  const [programs, setPrograms] = useState<Array<ProgramType>>([]);

  useEffect(() => {
    dispatch(programsList({ limit: 8 }));
  }, []);

  useEffect(() => {
    if (programState.isSuccessful) {
      setPrograms(programState.data.programs);
      dispatch(programsCleanup());
    } else if (programState.error) {
      setPrograms([]);
      dispatch(programsCleanup());
    }
  }, [programState.isSuccessful]);

  return (
    <div className="popular-programs">
      <Container>
        <div className="topper">
          <h3>Popular programs</h3>
          <Link to="/programs-list">View all</Link>
        </div>

        <ProgramList
          data={programs?.slice(0, 5)} // show only 5 programs on the landing page
          isLoading={programState?.isLoading}
        />
      </Container>
    </div>
  );
};

export default PopularPrograms;
