import { signupProgress as initialState } from "../../initialStates";

const signupProgress = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SET_ROLE":
      return {
        ...state,
        role: payload.role,
        stage: "profile",
      };
    case "SET_PROFILE":
      return {
        ...state,
        email: payload.email,
        phoneNo: payload.phoneNo,
        minority: payload.minority,
        // veteran: payload.veteran,
        minority_group: payload.minorityGroup,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        postalCode: payload.postalCode,
        fund_raiser: payload.fund_raiser,
        stage: "password",
      };
    case "SET_STAGE":
      return {
        ...state,
        stage: payload.stage,
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: payload.password,
        stage: payload.stage,
      };

    case "SET_REFFERAL_CODE":
      return {
        ...state,
        role: payload.role,
        stage: payload.stage,
        referralCode: payload.referralCode,
      };


    case "SIGNUP_PROGRESS_CLEANUP":
      return initialState;
    default:
      return state;
  }
};

export default signupProgress;
