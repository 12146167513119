/* eslint-disable react-hooks/exhaustive-deps */
//styles
import "./index.css";

//modules and packages
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MehOutlined, LoadingOutlined } from "@ant-design/icons";
import { Flex } from "antd";
//actions
import { activate, activateCleanup } from "../../Store/actions/activate";

//media
import confetti from "../../Assets/images/confetti.png";
import { setAuth } from "../../Store/actions/auth";
import { Button } from "../../Components/ui/Button";

const Activate = () => {
  const { token } = useParams();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const activateState = useSelector((s) => s.activate);
  const dispatch = useDispatch();

  const handleAccountActivation = () => {
    if (token) {
      dispatch(activate(token));
    }
  };

  useEffect(() => {
    if (error) setError(null);
  }, []);

  useEffect(() => {
    if (activateState.isLoading) {
      setLoading(activateState.isLoading);
      dispatch(activateCleanup());
    } else if (activateState.isSuccessful) {
      setSuccess(activateState.isSuccessful);
      setLoading(false);
      dispatch(setAuth(activateState?.data));
      dispatch(activateCleanup());
      history.push("/account"); // go to dashboard
    } else if (activateState.error) {
      setLoading(false);
      setError(activateState.error);
      dispatch(activateCleanup());
    }
  }, [activateState]);

  return (
    <div className="activate">
      {!loading && !success && !error && (
        <Flex align="center" vertical gap={"2rem"} style={{ width: "350px" }}>
          <h1>Account Activation</h1>
          <Button
            style={{ width: "300px", marginInline: "auto" }}
            block
            className="btn btn-primary"
            onClick={handleAccountActivation}
          >
            Activate
          </Button>
        </Flex>
      )}
      {loading && (
        <>
          <LoadingOutlined spin />
        </>
      )}
      {success && (
        <>
          <img src={confetti} alt="well done!" />
          <p>Yaaay!!!</p>
          <p>your account has successfully being activated</p>
          <p>
            please login <Link to="/login">here</Link>
          </p>
        </>
      )}
      {error && (
        <>
          <MehOutlined />
          <p>{error}</p>
        </>
      )}
    </div>
  );
};

export default Activate;
