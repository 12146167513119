import { FlagFilled, UserOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import { Link } from "react-router-dom";

import "./Card.css";
import { formatCurrency, getProgramTitle } from "../../../Utils";
import fromhome from "../../../Assets/images/fromhome.png";
import CartButton from "../../CartButton";

type ProgramCardType = {
  program: any;
  showControls?: boolean;
  variant?: number;
};

function ProgramCard(props: ProgramCardType) {
  const { program, variant = 2 } = props;
  return (
    <div className="program__card">
      <Link to={`/programs/${program?._id}`} className="program__link">
        {program.flag ? (
          <p className="flag">
            <FlagFilled style={{ color: "#000000", fontSize: "13px" }} />
          </p>
        ) : null}
        <Image
          src={program?.avatar?.secure_url || fromhome}
          alt="happy"
          height={"300px"}
          width={"100%"}
          className="avatar"
          crossOrigin="anonymous"
        />
        <div className="program__body px-1">
          <h4
            className="d-flex align-items-center py-2 px-1"
            style={{ fontSize: "14px", gap: 10 }}
          >
            <Avatar
              src={
                program.owner?.avatar?.secure_url ?? <UserOutlined size={30} />
              }
              size={40}
              style={{ flex: "0 0 auto" }}
              crossOrigin="anonymous"
            />

            {getProgramTitle(program)}
          </h4>
          <p className="total-discount">
            <span style={{ marginRight: "5px", marginLeft: "30px" }}>
              You save &emsp;
            </span>
            {formatCurrency(program.totalDiscount)}
          </p>
        </div>
      </Link>

      {/* show the button based on the card declarations */}

      <CartButton program_id={program?._id} variant={variant} />
    </div>
  );
}

export default ProgramCard;
