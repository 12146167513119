import { useLocation, useHistory } from "react-router-dom";
import "./index.css";
import InputField from "../../Components/ui/form/Input";
import { useEffect, useState } from "react";
import { Button } from "../../Components/ui/Button";
import { Alert } from "antd";
import {
  selectSubscription,
  subCleanup,
  subscribe,
  unsubscribe,
} from "../../Store/actions/subscription";
import { useAppDispatch, useAppSelector } from "../../hooks";

function Subscription() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const subscription = useAppSelector(selectSubscription);

  const [error, setError] = useState({ status: "error", message: "" });
  const [subscriberEmail, setSubscriberEmail] = useState("");

  const getButtonText = () => {
    const pathname = location.pathname.replace("/", "");

    return pathname[0]?.toUpperCase() + pathname?.substring(1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!subscriberEmail) {
      setError({
        status: "error",
        message: "Subscriber email is required",
      });
      return;
    }

    const action = getButtonText();

    if (!(action === "Subscribe" || action === "Unsubscribe")) {
      setError({
        status: "error",
        message: "You can either subscribe or unscubscribe",
      });

      return;
    }

    switch (action) {
      case "Subscribe":
        dispatch(subCleanup());
        dispatch(subscribe(subscriberEmail));
        break;

      case "Unsubscribe":
        dispatch(subCleanup());
        dispatch(unsubscribe(subscriberEmail));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.search?.includes("?email=")) {
      const email = location?.search?.replace("?email=", "");
      setSubscriberEmail(email);
    } else setSubscriberEmail("");
  }, [location?.search]);

  useEffect(() => {
    // reset last error
    setError({ status: "", message: "" });

    if (subscription?.error) {
      setError({ status: "error", message: subscription?.error });
    } else if (subscription?.data) {
      setError({ status: "success", message: subscription?.data?.message });
      history.push("/");
    }
  }, [subscription?.error, subscription?.data]);

  return (
    <div className="subscription__page">
      {error?.message ? (
        <Alert
          showIcon
          closable
          message={error?.message}
          className="subscription__alert"
          type={error?.status === "error" ? "error" : "success"}
        />
      ) : null}
      <form className="subscription__wrapper" onSubmit={handleSubmit}>
        <h4 className="subscription__header">Subscription</h4>
        <InputField
          label=""
          name="email"
          type="email"
          defaultValue={subscriberEmail}
          readOnly
          onChange={(e: any) => {}}
        />

        <Button className="btn--subscription" loading={subscription?.isLoading}>
          {getButtonText()}
        </Button>
      </form>
    </div>
  );
}

export default Subscription;
