import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";
import { RootState } from "../..";
import { getCartSuccess } from "../getCart";

const addToCartStart = () => ({
  type: "ADD_TO_CART_START",
});

const addToCartSuccess = (payload) => ({
  type: "ADD_TO_CART_SUCCESS",
  payload,
});

const addToCartFail = (payload) => ({
  type: "ADD_TO_CART_FAIL",
  payload,
});

export const addToCartCleanup = () => ({
  type: "ADD_TO_CART_CLEANUP",
});

export const selectAddToCart = (state: RootState) => state.addToCart;

export const addToCart =
  (payload: { type: "add" | "remove"; id: string; data?: any[] }) =>
  async (dispatch) => {
    dispatch(addToCartStart());

    if (payload.type === "add") {
      try {
        const requestObj = {
          path: "cart/",
          method: "POST",
          data: { _id: payload.id },
        };
        const { data: items, message } = await AxiosCall(requestObj);
        dispatch(addToCartSuccess({ items, message }));
        dispatch(getCartSuccess(items));
      } catch (err) {
        const error = ErrorHandler(err);
        dispatch(addToCartFail(error));
      }
    } else if (payload.type === "remove")
      try {
        const requestObj = {
          path: `cart/${payload.id}`,
          method: "DELETE",
        };
        const { data: items, message } = await AxiosCall(requestObj);
        dispatch(
          addToCartSuccess({
            items: items,
            message,
          })
        );
        dispatch(getCartSuccess(items));
      } catch (err) {
        const error = ErrorHandler(err);
        dispatch(addToCartFail(error));
      }
  };
