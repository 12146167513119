import { ChangeEventHandler } from "react";
type OptionType = { label: string; value: string };
type PropType = {
  name: string;
  onChange: ChangeEventHandler;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  options: OptionType[];
};

function Select(props: PropType) {
  const formatRole = (role: string) =>
    role[0].toUpperCase() + role.substring(1);

  return (
    <div className="select__wrapper">
      {!props.placeholder ? (
        <label htmlFor={props.name} className="select__label">
          {props.name[0].toUpperCase() + props.name.substring(1)}{" "}
          {props?.isRequired ? (
            <>
              &ensp;<b className="is_required">*</b>
            </>
          ) : null}
        </label>
      ) : null}

      <select
        name={props.name}
        className="select__input"
        placeholder={props.placeholder}
        defaultValue={props?.defaultValue}
        onChange={props.onChange}
      >
        {props.placeholder ? <option>{props.placeholder}</option> : null}
        {props.options.map((role) => (
          <option key={role?.label} value={role.value}>
            {formatRole(role.label)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
