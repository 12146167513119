//styles
import "./index.css";

//modules and packages
import { Container, Row, Col, CardImg } from "reactstrap";
import SearchForm from "./SearchForm";
import heroimg from "../../../Assets/images/heroimg.png";

const Hero = () => {
  return (
    <div className="hero">
      <Container>
        <Row>
          <Col md="6" className="hero-text-container">
            <h1 className="fs-1 fw-bolder">
              Win VIP contests and gift cards on SaveNwin
            </h1>
            <p className="fs-5">
              Get ready for the ultimate win-win situation with SaveNwin! Enter
              our VIP contests and put your luck to the test. Score big and you
              could walk away with gift cards to your favorite brands. With new
              contests added regularly, you'll have endless opportunities to
              win. Don't miss out on this chance to save and win at the same
              time. Sign up now and start winning on SaveNwin!
            </p>
          </Col>
          <Col md="6" className="hero-img">
            <div className="hero-img-container">
              <CardImg src={heroimg} />
            </div>
          </Col>
        </Row>
        <SearchForm />
      </Container>
    </div>
  );
};

export default Hero;
