//styles
import "./index.css";

//modules and packages
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//media
import logo from "../../Assets/images/logo.png";

const Footer = () => (
  <div className="footer bg-white">
    <Row className="justify-content-center align-items-center pb-5">
      <Col xs={12} md={10}>
        <Row>
          <Col sm={12} md={4}>
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </Col>
          <Col sm={12} md={8}>
            {" "}
            <Row className="justify-content-center">
              <Col md="3">
                <p className="title fs-6">Our Office</p>
                <p>
                  11357 Nuckols Rd #1035 <br />
                  Glen Allen, VA 23059
                </p>
              </Col>
              <Col md="3">
                <p className="title fs-6">Resources</p>
                <ul>
                  <li>
                    <Link className="footer-links" to="#">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      My account
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <p className="title fs-6">Services provided</p>
                <ul>
                  <li>
                    <Link className="footer-links" to="#">
                      Web | App Dev
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      SEO | SMM
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      Domains | Hosting
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      Video | Digital Maketing
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <p className="title fs-6">Company</p>
                <ul>
                  <li>
                    <Link className="footer-links" to="#">
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-links" to="#">
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row className="justify-content-center align-items-center text-center py-3 border-top">
      <Col sm={12}>
        <div>Copyright @ 2023. All Right Reserved</div>
      </Col>
    </Row>
  </div>
);

export default Footer;
