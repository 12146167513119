/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";

// ACTIONS
import "./index.css";
import {
  getTransaction,
  getTransactionCleanup,
} from "../../../Store/actions/bussiness/get-transaction";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { selectLoggedInUserRole } from "../../../Store/actions/getMe";

const TransactionHistory = () => {
  const { Search } = Input;
  const dispatch = useAppDispatch();
  const [transactions, setTransaction] = useState([]);
  const getTransactionState = useAppSelector((state) => state.getTransaction);
  const currentRole = useAppSelector(selectLoggedInUserRole);

  useEffect(() => {
    dispatch(getTransaction());
  }, []);

  useEffect(() => {
    if (getTransactionState.isSuccessful) {
      setTransaction(getTransactionState.data);
      dispatch(getTransactionCleanup());
    } else if (getTransactionState.error) {
      setTransaction(null);
      dispatch(getTransactionCleanup());
    }
  }, [getTransactionState]);

  const onSearch = (value) => dispatch(getTransaction(value));

  return (
    <div className="transaction-list-container">
      <h1>Transaction History</h1>

      <hr />
      {transactions && (
        <>
          {transactions.length !== 0 && (
            <div className="search-container">
              <Search
                className="search-input"
                placeholder="Search by transaction ID"
                onSearch={onSearch}
                enterButton
              />
            </div>
          )}
        </>
      )}

      {getTransactionState.isLoading ? (
        <div className="text-center py-5">
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <div className="table-responsive">
          {transactions.length === 0 ? (
            <p style={{ textAlign: "center" }}>No Transactions found</p>
          ) : (
            <table className="table borderless">
              {transactions.length === 0 ? null : (
                <thead>
                  <tr>
                    <th scope="col" className="col-1">
                      No.
                    </th>

                    <th scope="col" className="col-2">
                      {currentRole === "business"
                        ? "Buyer Email"
                        : "Seller Email"}
                    </th>

                    <th scope="col" className="col-2">
                      Title
                    </th>

                    <th scope="col" className="col-2">
                      Transaction ID
                    </th>

                    <th scope="col" className="col-1">
                      Date
                    </th>
                  </tr>
                </thead>
              )}

              {transactions.length !== 0 &&
                transactions.map((item, index) => (
                  <tbody key={index}>
                    <tr className="all-products">
                      <td>{index + 1}.</td>

                      {currentRole === "customer" ? (
                        <td>{item.owner.email}</td>
                      ) : (
                        <td>{item.buyer.email}</td>
                      )}

                      <td>
                        {item.program == null
                          ? "not available"
                          : item.program.title}
                      </td>

                      <td>
                        {item.program == null
                          ? "not available"
                          : item.transactionID}
                      </td>

                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    </tr>
                  </tbody>
                ))}
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
