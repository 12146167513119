import "./index.css"
 
import { useRef } from "react";
import { UserTable } from "./UserTable";

const List = ({
  data,
  error,
  actions,
  isLoading,
  visibility,
  setVisibility,
}) => {
  const itemRefs = useRef([]);
  itemRefs.current = [];

  const { showUserDetails, moreOptions } = actions;

  const addItiemRefs = (e) => {
    if (e && !itemRefs.current.includes(e)) {
      itemRefs.current.push(e);
    }
  };

  const viewProfile = async (e, index, userId) => {
    // console.log("selected element: ", e.target.parentNode);
    // console.log("index element: ", itemRefs.current[index].childNodes[0]);
    if (visibility != null) return;
    if (e.target.parentNode !== itemRefs.current[index].childNodes[0]) {
      showUserDetails(userId);
    }
  };

  const datasource = (data || [])?.map((user, key) => ({
    ref: addItiemRefs,
    viewProfile,
    key,
    showUserDetails,
    moreOptions,
    visibility,
    isLoading,
    setVisibility,
    id: user?._id,
    email: user?.email,
    type: user?.role,
    isDeleted: user?.deleted?.value,
    suspended: user?.suspended?.value,
    confirmed: user?.confirmed,
    businessName: user?.businessName,
  }));
  return <UserTable data={datasource} />;
};

export default List;
