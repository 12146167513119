import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";
import secureLocalStorage from "react-secure-storage";

import { setAuth } from "../auth";
import { getMe } from "../getMe";

export const loginStart = () => ({
  type: "LOGIN_START",
});

export const loginSuccess = (payload) => ({
  type: "LOGIN_SUCCESS",
  payload,
});

export const switchRoleSuccess = (payload) => ({
  type: "SWITCH_ROLE_SUCCESS",
  payload,
});

export const loginFail = (payload) => ({
  type: "LOGIN_FAIL",
  payload,
});

export const loginCleanup = () => ({
  type: "LOGIN_CLEANUP",
});

export const selectLogin = (s) => s.login;

export const login = (payload) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const requestObj = {
      path: "users/login",
      method: "POST",
      data: payload,
    };
    const { data } = await AxiosCall(requestObj);
    dispatch(loginSuccess(data));
    dispatch(setAuth(data.token));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(loginFail(error));
  }
};

export const switchUserRoleSuccess = (payload) => (dispatch) => {
  secureLocalStorage.setItem("authToken", payload.token);
  dispatch(getMe())
  dispatch(switchRoleSuccess(payload));
};
