import AxiosCall from "../../Utils/axios";
import { CreateNewBusinessRoleDtoType } from "../../interfaces";

export const addBusinessRoleApi = async (data: CreateNewBusinessRoleDtoType) =>
  AxiosCall({
    path: `users/me/roles/business`,
    method: "POST",
    data,
  });

export const switchRoleApi = async (role: string) =>
  AxiosCall({
    path: `users/me/roles/${role}`,
    method: "GET",
  });

export const fetchUserRoles = () =>
  AxiosCall({
    path: "users/me/roles",
    method: "GET",
  });
