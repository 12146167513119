import { RootState } from "../..";
import AxiosCall from "../../../Utils/axios";
import ErrorHandler from "../../../Utils/error-handler";

export const getCartStart = () => ({
  type: "GET_CART_START",
});

export const getCartSuccess = (payload) => ({
  type: "GET_CART_SUCCESS",
  payload,
});

export const getCartFail = (payload) => ({
  type: "GET_CART_FAIL",
  payload,
});

export const getCartCleanup = () => ({
  type: "GET_CART_CLEANUP",
});

export const selectGetCart = (state: RootState) => {
  return state.getCart;
};

export const selectCartState = (state: RootState) => {
  return state.getCart;
};

export const getCart =
  (populate = true) =>
  async (dispatch) => {
    let path = "cart";
    if (populate) path.concat("?populate=true");
    try {
      dispatch(getCartStart());
      const requestObj = {
        path: path,
        method: "GET",
      };
      const { data } = await AxiosCall(requestObj);
      dispatch(getCartSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(getCartFail(error));
    }
  };
