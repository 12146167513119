/* eslint-disable react-hooks/exhaustive-deps */
import "./index.css";

import { useEffect, useState } from "react";

import UserProfile from "./userprofile/userProfile";
import EditProfile from "./userprofile/editProfile";
import Button from "../../../Components/customButton/button";
import {
  selectLoggedInUser,
  selectLoggedInUserRole,
} from "../../../Store/actions/getMe";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

const customCss = {
  shadow: {
    md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  },
  fontWeight: {
    title: 600,
  },
};

const BusinessDashboard = () => {
  const [user, setUser] = useState({});

  const userState = useAppSelector(selectLoggedInUser);
  const currentRole = useAppSelector(selectLoggedInUserRole);

  const history = useHistory();

  useEffect(() => {
    if (userState?.data?.user) {
      setUser(userState?.data?.user);
    } else {
      setUser({});
      history.push("/login");
    }
  }, [userState?.data?.user]);

  return (
    <div className="dashboard-container container">
      <div className="dashboard-inner mx-auto">
        <h6
          style={{
            fontWeight: "bolder",
            fontSize: "2rem",
            textAlign: "center",
            marginBlock: "40px",
          }}
        >
          Profile Account
        </h6>
        <div className="dashboard-content row">
          <div className="dashboard-column1 col-sm-12 col-md-11 col-lg-8 mx-auto">
            <UserProfile user={user} />
            <EditProfile />
          </div>

          {currentRole === "business" ? (
            <div className="dasboard-column2 col-sm-12 col-md-11 col-lg-8 mx-auto mb-4">
              <div className="column-wrapper">
                <div className="row">
                  <span style={{ fontWeight: customCss.fontWeight.title }}>
                    Upload a new Item
                  </span>
                  <Link to="/account/create-program" style={{ width: "auto" }}>
                    <Button
                      width={90}
                      br={5}
                      mt={15}
                      color="#FF4C4F"
                      brColor="#FF4C4F"
                      height={35}
                    >
                      Upload
                    </Button>
                  </Link>
                </div>
                <div className="row">
                  <span style={{ fontWeight: customCss.fontWeight.title }}>
                    Create a new VIP program
                  </span>
                  <Link
                    to="/account/create-vip-program"
                    style={{ width: "auto" }}
                  >
                    <Button
                      width={90}
                      br={5}
                      mt={15}
                      color="#FF4C4F"
                      brColor="#FF4C4F"
                      height={35}
                    >
                      Post
                    </Button>
                  </Link>
                </div>
                <div
                  className="row"
                  style={{ marginTop: 20, boxShadow: customCss.shadow.md }}
                >
                  <span style={{ fontWeight: customCss.fontWeight.title }}>
                    Transaction History
                  </span>
                  <Link to="/account/transactions" style={{ width: "auto" }}>
                    <Button
                      width={90}
                      br={5}
                      mt={15}
                      color="#FF4C4F"
                      brColor="#FF4C4F"
                      height={35}
                    >
                      View
                    </Button>
                  </Link>
                </div>
                <div
                  className="row"
                  style={{ marginTop: 20, boxShadow: customCss.shadow.md }}
                >
                  <span style={{ fontWeight: customCss.fontWeight.title }}>
                    Our Items
                  </span>
                  <Link to="/account/items" style={{ width: "auto" }}>
                    <Button
                      width={90}
                      br={5}
                      mt={15}
                      color="#FF4C4F"
                      brColor="#FF4C4F"
                      height={35}
                    >
                      View
                    </Button>
                  </Link>
                </div>

                <div
                  className="row"
                  style={{
                    marginTop: 20,
                    boxShadow: customCss.shadow.md,
                  }}
                >
                  <span style={{ fontWeight: customCss.fontWeight.title }}>
                    Scan Codes
                  </span>
                  <Link to="/account/qrcodes/open" style={{ width: "auto" }}>
                    <Button
                      width={90}
                      br={5}
                      mt={15}
                      // color="#FF4C4F"
                      brColor="#FF4C4F"
                      height={35}
                    >
                      View
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
