import {
  wishlist as initialState,
  WishlistReducerType,
} from "../../initialStates";

const wishlistReducer = (
  state = initialState,
  action: { type: string; payload: any }
): WishlistReducerType => {
  switch (action.type) {
    case "CREATE_WISHLIST_ITEM":
    case "UPDATE_WISHLIST_ITEM":
    case "GET_WISHLIST":
    case "GET_WISHLIST_LISTING":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_WISHLIST_ERROR":
    case "GET_WISHLIST_LISTING_ERROR":
    case "CREATE_WISHLIST_ERROR":
    case "UPDATE_WISHLIST_ERROR":
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: action.payload,
      };

    case "GET_WISHLIST_SUCCESS":
    case "GET_WISHLIST_LISTING_SUCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        isSuccessful: true,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default wishlistReducer;
